import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import "./TermsAndConditions.css";

const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: 500    
  },
}));

function TandCClerk(props) {
    const classes = useStyles();
    
    // use <b /> tag for bolding
    const rawHtml = `<b>TITLEPLUS CLERK TERMS OF USE AGREEMENT<a href="#_ftn1" name="_ftnref1" title="">[1]</a> (the “Agreement”)</b>

<b>TERMS OF USE</b>

<b>1.     INTRODUCTION</b>
     
    a)     Lawyers’ Professional Indemnity Company (LAWPRO®) has developed a form of insurance known as TitlePLUS® title insurance and is licensed to provide same in various Canadian jurisdictions.
        
    b)     LAWPRO maintains websites for informational purposes and for creating TitlePLUS policy applications (subject to availability) by the Subscribing Lawyer and the Clerk, including titleplus.ca and mytitleplus.ca (with any such websites being individually and collectively referred to as the “<b>Website</b>”). The Subscribing Lawyer and the Clerk may access certain electronic information or databases and certain databases provided by third parties. The term “Website” as used in this Agreement is deemed to include any other web‑based TitlePLUS policy application system (in addition to or in replacement of mytitleplus.ca) as may be approved by LAWPRO from time to time, in its sole and absolute discretion.
        
    c)     This Agreement provides the Terms of Use under which the Clerk may use the Website. By accessing the Website, the Clerk agrees to be bound by all of the Terms of Use and agrees that these terms constitute a binding contract between LAWPRO and the Clerk.

<b>2.   USE OF THE WEBSITE</b>
     
Subject to compliance with this Agreement and payment of the TitlePLUS Charge (defined below) by the applicable Subscribing Lawyer, LAWPRO grants to the Clerk a non-exclusive, non-transferable, limited personal licence to access and use the Website to make application for TitlePLUS policies upon such Clerk meeting all due diligence and other requirements in LAWPRO’s sole and absolute discretion.
     
    a)     When the Clerk makes use of the Website, they must do so under the supervision and with the authorization of the Subscribing Lawyer.
        
    b)     The Clerk’s use of the Website shall be at the Subscribing Lawyer’s sole expense and risk. LAWPRO makes no representation or warranty regarding the operation of the Website for any purpose other than making application for TitlePLUS coverage over the Website.
        
    c)     The Clerk will be assigned a unique user identification (the “User ID”) and password from LAWPRO, both of which will be required to complete a policy application.
        
    d)     Only the Clerk may access the Website using that User ID and password. The Clerk shall be responsible for the security of their User ID and password and shall not share them with any other person, including the Subscribing Lawyer. The Clerk agrees to notify LAWPRO immediately of any unauthorized use of their User ID or password, or any other breach of security.
        
    e)     The Clerk acknowledges their duty to keep LAWPRO informed of any changes in their employment. In particular, the Clerk shall promptly notify LAWPRO upon:

           (i)     the conclusion of their employment relationship with one or more Subscribing Lawyer; or

           (ii)    the beginning of an employment relationship with another Subscribing Lawyer.
     
    f)     LAWPRO reserves the right, in its sole and absolute discretion, to terminate, limit or suspend the Clerk's access to the Website without notice or liability, for any reason whatsoever, including the Clerk's breach of any of these Terms of Use.
        
    g)     The Clerk shall use the Website strictly in accordance with the terms and provisions of this Agreement, the lawful requirements of any applicable governmental authorities, and the instructions and requirements issued by LAWPRO from time to time, including without limitation:

           (i)     any LAWPRO-approved training materials;

           (ii)    any instructions communicated through the Website; and

           (iii)   any instructions from or by an authorized LAWPRO representative;

           (collectively referred to as the “<b>Instructions</b>”) on the express understanding that the Instructions may be amended or varied (in whole or in part) on one or more occasions by LAWPRO either in writing or through direct verbal communications with the Clerk or the Subscribing Lawyer.
     
    h)     Certain materials and features may be added to or withdrawn from the Website without prior notice to the Clerk. The Clerk shall take all reasonable steps to ensure that the Website is not tampered with in any fashion, including without limitation, any attempts to translate, reverse engineer, decompile, disassemble, modify, or merge the Website with any other system.
        
    i)     The Clerk acknowledges and agrees that they have no right or entitlement under this Agreement to the use of the business name, logo, and related trademarks of LAWPRO (e.g., TitlePLUS) or any of its licensors or suppliers (collectively referred to as the “Trademarks”). In order to use one or more of the Trademarks, the Clerk must inform LAWPRO in writing of their intent to use it and receive written approval from LAWPRO.
     
<b>3.     CLERK’S ROLE IN TITLEPLUS TITLE INSURANCE</b>
     
    a)     The Clerk specifically acknowledges and agrees that:

           (i)     They will only use the TitlePLUS functionality within the Website in connection with the submission of applications for Clients<a href="#_ftn2" name="_ftnref2" title="">[2]</a> for TitlePLUS policies and under the supervision and with the authorization of the Subscribing Lawyer;

           (ii)    TitlePLUS policies are only issued by LAWPRO if LAWPRO has approved an application submitted through the Website, whether by the Clerk, by the Subscribing Lawyer or by LAWPRO staff on the Clerk’s or Subscribing Lawyer’s behalf;

           (iii)   They will not purport to create, generate or distribute any TitlePLUS policies that are not issued by LAWPRO through the Website,

           (iv)    They will respond to all inquiries generated through the Website and provide all requisite information requested by said system;

           (v)     The provision of full responses and information to the Website, including the completion of all requisite data fields and inquiries, on or before a request for issuance of a policy, will constitute a certificate of title from the Subscribing Lawyer on behalf of the Client to and in favour of LAWPRO in respect of each property for which an application for TitlePLUS title insurance is submitted;

           (vi)    notwithstanding the provision of said certificate of title, the Subscribing Lawyer and the Clerk will be representing the Client to whom the TitlePLUS policy is issued and not representing LAWPRO, and shall be obliged to exercise care and judgment on behalf of their Client in accordance with the standards of a prudent solicitor in the jurisdiction where the Subscribing Lawyer practices.
     
    b)     LAWPRO has designed programs within the Website, including the New Home Program, pursuant to which LAWPRO will make arrangements regarding certain new residential subdivisions and condominiums to streamline the requirements for the Clerk and the Subscribing Lawyer acting on behalf of a purchaser of property in such developments.

           (i)     Within the New Home Program, LAWPRO shall provide to the Clerk and Subscribing Lawyer certain general information and responses necessary to complete a TitlePLUS application (the “<b>Program Responses</b>”), so that they do not have to complete inquiries or investigations except those specifically set out in the New Home Program. Their obligations under paragraphs 3.a)(v) and (vi) of this Agreement are deemed to be fulfilled by the completion of the TitlePLUS application using the Program Responses, but only in respect of the questions and inquiries addressed by the Program Responses.

           (ii)    Notwithstanding the provisions of paragraphs 6.a) and 6.c) of this Agreement, the Clerk and the Subscribing Lawyer will not be obligated to carry out any searches or inquiries that are obviated by the Program Responses, nor maintain records in respect thereof.
     
    c)     The Clerk agrees and acknowledges that:

           (i)     in the event of any dispute arising between any Client and LAWPRO, the Clerk and Subscribing Lawyer will not act for any of the parties in relation to such dispute; and

           (ii)    no information in respect of the same transaction or the property insured under a TitlePLUS policy that is received by the Clerk and Subscribing Lawyer in connection with LAWPRO issuing a TitlePLUS policy can be treated as confidential in so far as any Client or LAWPRO is concerned;

           provided however that the provisions of this paragraph will not, under any circumstances, prevent the Clerk and Subscribing Lawyer from acting for the Client in the completion of such real estate transaction and the Clerk and Subscribing Lawyer are hereby specifically authorized to do so by LAWPRO.
     
    d)     The Clerk shall not, without the prior written approval of LAWPRO:

           (i)     incur accounts or debts chargeable to LAWPRO;

           (ii)    adjust any claims for loss under a TitlePLUS policy;

           (iii)   accept service of an originating document in a legal proceeding against LAWPRO; or

           (iv)    alter forms furnished by LAWPRO.
     
<b>4.   LAWPRO’S ROLE IN TITLEPLUS TITLE INSURANCE</b>

    a)     LAWPRO agrees to provide TitlePLUS coverage to the Clients from time to time upon proper application being made to and accepted by LAWPRO through the Website, whether by the Clerk, by the Subscribing Lawyer or by LAWPRO staff on the Clerk’s and Subscribing Lawyer’s behalf, provided that the Clerk complies with all terms of this Agreement.
        
    b)     In furtherance of the foregoing, LAWPRO shall advise the Clerk with reasonable promptness of its decision regarding acceptance or rejection of an application for TitlePLUS coverage.
        
    c)     <b>LAWPRO SHALL NOT BE LIABLE FOR ANY COSTS, CLAIMS, AND/OR DAMAGES ARISING FROM ITS INABILITY TO PROCESS APPLICATIONS FOR TITLEPLUS POLICIES AND/OR ISSUE TITLEPLUS POLICIES DUE TO ANY CAUSE, EXCEPT THOSE RELATED TO LAWPRO’S GROSS NEGLIGENCE OR WILFUL MISCONDUCT.</b>

<b>5.   ACCESS TO THE WEBSITE</b>

The Clerk shall be solely responsible for arranging on-line remote access at their sole cost and expense and for the acquisition, installation and operation of all necessary hardware, software and communications configuration required for the proper operation of the Website at their location. The use of such equipment shall be at their sole expense and risk and LAWPRO makes no representation or warranty regarding the operation of the Website on, and/or the adequacy or fitness of, said equipment for any of the purposes set out herein.
     
<b>6.   MAINTENANCE OF RECORDS AND AUDIT RIGHTS</b>
     
    a)     The Clerk shall keep safely in the Clerk’s or Subscribing Lawyer’s possession all title searches, acknowledgments, letter search responses and/or other documents which have been relied upon in connection with the issuance of TitlePLUS policies.
        
    b)     The Clerk will keep safely in the Clerk’s or the Subscribing Lawyer’s possession any materials or documents provided by LAWPRO which are clearly identified as being the property of LAWPRO including, but not limited to, all training materials, policies, endorsements, forms and other supplies furnished to the Subscribing Lawyer by LAWPRO.
        
    c)     The Clerk agrees that LAWPRO has the right to examine and copy all documents and records (including financial records) relating to any TitlePLUS coverage issued by LAWPRO or in respect of which an application has been submitted to LAWPRO by the Clerk or by the Subscribing Lawyer. Such right of examination shall not extend to records which do not relate to applications for TitlePLUS coverage or the property affected thereby. Such right to examination will not be limited by the electronic nature of the documents and records or their location, whether in the Subscribing Lawyer’s place of business, in the Clerk’s place of business, or any other location.
     
<b>7.   CLERK’S ROLE RE: CLAIMS</b>
     
The Clerk shall cooperate with and assist the Subscribing Lawyer when the Subscribing Lawyer is required to advise LAWPRO of a claim, possible claim or defect in respect of TitlePLUS Coverage on behalf of a Client. In connection with such matters, the Clerk shall:

    a)     forward to LAWPRO copies of all search notes and other documentation; and

    b)     provide all reasonable assistance and information to assist LAWPRO with its investigation, adjustment, or contestation of any such claim or defect, including without limitation attending at meetings or discoveries, providing affidavits or statutory declarations, or giving oral testimony;

    as may be requested from time to time by LAWPRO.
     
<b>8.   INTELLECTUAL PROPERTY RIGHTS</b>
     
Title to the Website, all associated business processes and all TitlePLUS documentation shall at all times remain with LAWPRO or its licensors and suppliers, and the Clerk shall have no rights therein except as may be expressly granted by this Agreement. The TitlePLUS trademark, the Website and all TitlePLUS documentation are the sole property of LAWPRO or its licensors and suppliers and are protected by copyright and other intellectual property laws. Unauthorized access to, or reproduction, use and/or distribution of, the TitlePLUS trademark, the Website and any TitlePLUS documentation or any portion thereof may result in serious civil and criminal penalties and in prosecution to the full extent of the law. For the purposes of this Agreement, “<b>TitlePLUS documentation</b>” includes the contents of the Website, as amended from time to time.
     
<b>9.   TERMINATION OF THE LICENCE</b>

    a)     Subject to the terms and provisions of paragraph 9.b) below, the Licence granted to the Clerk or the Subscribing Lawyer herein (and the Clerk’s and Subscribing Lawyer’s corresponding entitlement to apply for TitlePLUS policies on behalf of their Client) may be revoked or limited by LAWPRO or released by the Clerk or Subscribing Lawyer at any time by a written notice of intention to revoke or release (as the case may be) served in the manner set out in this Agreement. Any release, limitation or revocation by the Clerk or Subscribing Lawyer or LAWPRO shall take effect immediately upon receipt of the notice delivered in the manner specified in this Agreement.
        
    b)     Upon revocation or release of the Licence in the manner described above, the Clerk shall:

        (i)     no longer have access to the Website to create or complete new policy applications; and

        (ii)    return to LAWPRO all materials related to the Website which are the property of LAWPRO;

        provided however that the revocation or release of the Licence shall not affect the Subscribing Lawyer’s obligations to LAWPRO, or the benefits arising out of paragraphs 3.b)(ii) and 6.b) herein, in respect of any approvals for the issuance of a TitlePLUS policy and/or TitlePLUS policies issued by LAWPRO prior to such revocation or release.
     
<b>10.  NO WARRANTIES OR REPRESENTATIONS BY LAWPRO</b>
     
<b>THE CLERK EXPRESSLY ACKNOWLEDGES AND AGREES THAT THE WEBSITE HAS BEEN DESIGNED AND MADE AVAILABLE IN ORDER TO FACILITATE APPLICATIONS FOR, AND THE ISSUANCE OF, TITLEPLUS POLICIES AND THAT THE WEBSITE SHALL BE USED FOR NO OTHER PURPOSE. LAWPRO MAKES NO REPRESENTATION OR WARRANTY (WHETHER EXPRESS OR IMPLIED) REGARDING THE QUALITY OR FITNESS FOR PURPOSE, WHETHER ARISING BY LAW, STATUTE, USAGE OF TRADE OR COURSE OF DEALING, OF THE WEBSITE. LAWPRO SHALL NOT BE LIABLE FOR ANY LOSS OR DAMAGE, INCLUDING LOSS OF PROFITS, LOSS OF BUSINESS REVENUE OR FAILURE TO REALIZE EXPECTED SAVINGS OR FOR ANY INDIRECT, SPECIAL OR CONSEQUENTIAL DAMAGES ARISING OUT OF THE USE OF, OR THE ABILITY TO USE, THE WEBSITE, EVEN IF NOTICE OF SAME HAS BEEN PROVIDED TO LAWPRO.</b>
     
<b>11.  NOTICE</b>
     
Any notice desired or required to be given under this Agreement shall be in writing and shall be sent by personal delivery, electronically via the Website, by mail, by courier, by email or by facsimile transmission to the parties at the following addresses:

To LAWPRO:
                    250 Yonge Street
                    Suite 3101
                    Toronto, Ontario
                    M5B 2L7

                    Attention: TitlePLUS Department

                    Fax: (416) 599-8341
                    Email: titleplus@lawpro.ca
     
To the Clerk: 
                    at the address, fax number and email address set out during the registration process. Any notice given in the manner set out in this paragraph shall be deemed to have been received on the date of personal delivery, on the date of electronic transmission via the Website, on the second business day following the day of mailing or sending by courier, on the date of facsimile transmission (provided a confirmation of transmission receipt is produced at the time of facsimile transmission), or on the date of email transmission.
     
<b>12.  NO ASSIGNMENT</b>
     
This Agreement is not assignable by the Clerk without the prior written consent of LAWPRO. LAWPRO may unilaterally assign its rights and obligations (either in whole or in part and on one or more occasions) under this Agreement upon written notice of such assignment to the Clerk. This Agreement is, however, binding on and enures to the benefit of any successors, heirs, executors and permitted assigns of the parties hereto. As of the date written above, this Agreement supersedes any preceding Subscription Agreement or Terms of Use Agreement between the Clerk and LAWPRO.
     
<b>13.  JURISDICTION</b>
     
This Agreement shall be read and governed by, and construed in accordance with, the laws of the Province of Ontario.
     
<b>14.  SEVERABILITY OF TERMS</b>
     
The terms and provisions of this Agreement are severable and distinct and if any term of this Agreement is found to be invalid or unenforceable by a court of competent jurisdiction, then such determination shall not impair the validity or enforceability of the remaining provisions of this Agreement, which shall remain in full force and effect.
     
<b>15.  ENTIRE AGREEMENT</b>
     
This Agreement constitutes the entire agreement between the parties with respect to the use of the Website and the right of the Clerk to make application for TitlePLUS title insurance. Any amendments must be made in writing and signed by all the parties.
          
<b>16.  ACCEPTANCE BY LAWPRO</b>
     
This Agreement is subject to acceptance by LAWPRO and is not binding on the parties until LAWPRO advises the Clerk of its acceptance in writing.

<hr/>

<a href="#_ftnref1" name="_ftn1" title="">[1]</a> Applicable in all Canadian provinces and territories except Quebec.

<a href="#_ftnref2" name="_ftn2" title="">[2]</a> “<b>Client(s)</b>” means the purchaser(s), existing owner(s) and/or the mortgagee(s) (if applicable) of the property in the real estate transaction(s) giving rise to the issuing of the TitlePLUS policy.`

    return (
    <Typography
      component="pre"
      style={{ whiteSpace: "pre-wrap"}}
      dangerouslySetInnerHTML={{ __html: rawHtml }}
    >
    </Typography>);
}

export default TandCClerk;
