import React, { useState, useEffect, Fragment } from 'react';
import {useDispatch, useSelector} from "react-redux";
import { fetchAnnouncements, selectAnnouncements } from 'Admin/State/AnnouncementsSlice';
import { Grid, Paper, Container, Alert, Button, Box } from '@mui/material';
import { WebStorageHelper } from 'Util/Helpers';
import makeStyles from '@mui/styles/makeStyles';
import draftToHtml from 'draftjs-to-html';
import Carousel from 'react-material-ui-carousel'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const useStyles = makeStyles(theme => ({
    topMargin: {
        marginTop: theme.spacing(2),
    },
    customBox: {
        minHeight: 60,
        padding: theme.spacing(1),
    },
    carousel: {
        minHeight: 75,
        padding: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(1)
    },
}));

function AnnouncementsView({type, user}) {
    const classes = useStyles();
    const [announcements, setAnnouncements] = useState([]);
    const allAnnouncements = useSelector(selectAnnouncements);
    const announcementsStatus = useSelector((state) => state.announcements.status);
    const dispatch = useDispatch();
    const [isCarouselVisible, setIsCarouselVisible] = useState(true);
    const hideTimerValue = 14400000;
    const btnTitle = `Don't show this for 4 hours`;

    useEffect(() => {
        if (announcementsStatus === 'idle') {
            dispatch(fetchAnnouncements())
        } else if (announcementsStatus === 'succeeded') {
            let viewAnnouncements = allAnnouncements.filter(announcement => announcement.ActivityStatus === 'Active');
            if (type === 'signin') {
                viewAnnouncements = viewAnnouncements.filter(announcement => announcement.SigninPage === 1);
            } else if (type === 'landing') {
                viewAnnouncements = viewAnnouncements.filter(announcement => announcement.LandingPage === 1);
            } else if (type === 'application') {
                viewAnnouncements = viewAnnouncements.filter(announcement => announcement.ApplicationPage === 1);
            }
            viewAnnouncements.sort((a, b) => {
                // Only sort on priority if not identical
                if (a.Priority < b.Priority) return -1;
                if (a.Priority > b.Priority) return 1;
                // Sort on date if priority is identical
                if (a.LastUpdatedOn < b.LastUpdatedOn) return -1;
                if (a.LastUpdatedOn > b.LastUpdatedOn) return 1;
                // Both identical, return 0
                return 0;
            });
            setAnnouncements(viewAnnouncements);
        }
        // console.info(`useEffect-announcementsStatus: ${announcementsStatus}`);
        const value = WebStorageHelper.getItemFromDiskWithExpiry(type, user);
        if (value === 'hide') {
            setIsCarouselVisible(false);
        }
    }, [type, announcementsStatus, allAnnouncements, dispatch, user])

    const createMarkup = (html) => {
        return {
            __html: html
        }
    }

    const closeAnnouncements = (event) => {
        if (!user) {
            return;
        }
        WebStorageHelper.setItemToDiskWithExpiry(type, user, 'hide', hideTimerValue);
        setIsCarouselVisible(false);
    }

    // console.info(`announcements: ${JSON.stringify(announcements)}`);
    // console.info(`announcementsStatus: ${announcementsStatus}`);
    // console.info(`isCarouselVisible: ${isCarouselVisible}`);
    // console.info(`user: ${user}`);
    // console.info(`type: ${type}`);

    if (announcementsStatus !== 'succeeded' ||
        announcements.length === 0 ||
        isCarouselVisible === false) {
            return null;
    }

    return (
        <Container className={classes.topMargin} component="div">
            <Grid container spacing={2}>
                <Grid item xl={1}/>
                <Grid item xs={12} md={12} lg={12} xl={10}>
                    <Carousel item className={classes.carousel}
                              fullHeightHover={false}
                              NextIcon={<ArrowForwardIosIcon/>}
                              PrevIcon={<ArrowBackIosNewIcon/>}>
                        {announcementsStatus === 'succeeded' &&
                            announcements.length > 0 &&
                            announcements.map(record => {
                                const html = draftToHtml(JSON.parse(record.ContentState), null, true, null);
                                return (
                                    <Fragment key={record.Id}>
                                        <Paper key={record.Id} elevation={12}>
                                            <Alert key={record.Id} severity={record.DisplayType}>
                                                <div key={record.Id}
                                                     className={classes.customBox}
                                                     dangerouslySetInnerHTML={createMarkup(html)}>
                                                </div>
                                            </Alert>
                                        </Paper>
                                        <br/>
                                    </Fragment>
                                )
                            })}
                    </Carousel>
                </Grid>
                <Grid item xl={1}/>
            </Grid>
            {user &&
            <Box display="flex" justifyContent="center" mb={3}>
                <Button
                    type="button"
                    variant="outlined"
                    size="small"
                    onClick={() => closeAnnouncements()}
                    color="primary"
                    className={classes.submit}
                >
                    { btnTitle }
                </Button>
            </Box>}
        </Container>
    )
}

export default AnnouncementsView;