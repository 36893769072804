import StringUtil from './StringUtil';
import * as UserPermissions from 'Model/UserPermissions';
import * as UserRoles from 'Model/UserRoles';

class UserHelper {
    static getFullName(userInfo) {
        if(!userInfo) {
            return '';
        }

        return `${userInfo.FirstName} ${userInfo.LastName}`;
    }

    static firstNameLastInitial(userInfo) {
        if(!userInfo) {
            return '';
        }

        let result = userInfo.FirstName;
        const lastName = userInfo.LastName;

        if(lastName) {
            result = result + ' ' + lastName.charAt(0).toUpperCase();
        }

        return result;
    }

    static isLawyerOrClerk(userInfo) {
        if(!userInfo) return false;

        const role = userInfo.Role;

        if(StringUtil.isNullOrEmpty(role)) {
            return false;
        }

        return StringUtil.isEither(role, UserRoles.Lawyer, UserRoles.Clerk);
    }

    static hasPermission(userInfo, permission) {
        if(!userInfo) return false;

        if(!permission) return false;

        const role = userInfo.Role;

        if(StringUtil.isNullOrEmpty(role)) {
            return false;
        }

        // case sensitive
        if(!UserPermissions.byUserTypeCoarseGrain.has(role)) {
            return false;
        }

        const coarseGrain = UserPermissions.byUserTypeCoarseGrain.get(role);

        if(coarseGrain.includes(permission)) {
            return true;
        }

        return false; // as a safety measure against forgetting to check something, default to false
    }
}

export default UserHelper;