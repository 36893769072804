import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import "./TermsAndConditions.css";

const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: 500    
  },
}));

function TandCLawyer(props) {
    const classes = useStyles();
    
    // use <b /> tag for bolding

    const rawHtml = `<b>TITLEPLUS SUBSCRIPTION & TERMS OF USE AGREEMENT<a href="#_ftn1" name="_ftnref1" title="">[1]</a> (the “Agreement”)</b>

<b>TERMS OF USE</b>

<b>1.     INTRODUCTION</b>

    a)     Lawyers’ Professional Indemnity Company (LAWPRO®) has developed a form of insurance known as TitlePLUS® title insurance and is licensed to provide same in various Canadian jurisdictions.

    b)     LAWPRO maintains websites for informational purposes and for creating TitlePLUS policy applications (subject to availability) by the Subscribing Lawyer, including titleplus.ca and mytitleplus.ca (with any such websites being individually and collectively referred to as the “<b>Website</b>”). The Subscribing Lawyer using the Website may access certain electronic information or databases and certain databases provided by third parties. The term “Website” as used in this Agreement is deemed to include any other web-based TitlePLUS policy application system (in addition to or in replacement of mytitleplus.ca) as may be approved by LAWPRO from time to time, in its sole and absolute discretion.

    c)     This Agreement provides the Terms of Use under which you may use the Website. By accessing the Website, the Subscribing Lawyer agrees to be bound by all of the Terms of Use and agrees that these terms constitute a binding contract between LAWPRO and the Subscribing Lawyer.
    
<b>2.   USE OF THE WEBSITE</b>

Subject to your compliance with these Terms of Use and your payment of the TitlePLUS Charge defined in 6. below, LAWPRO grants to the Subscribing Lawyer a non-exclusive, non-transferable, limited personal licence to access and use the Website to make application for TitlePLUS policies upon such Subscribing Lawyer meeting all due diligence and other requirements in LAWPRO’s sole and absolute discretion.

    a)     If the Subscribing Lawyer elects to use the Website, its use shall be at the Subscribing Lawyer’s sole expense and risk and LAWPRO makes no representation or warranty regarding the operation of the Website for any purpose other than making application for TitlePLUS coverage over the Website.

    b)     The Subscribing Lawyer and any Authorized User will each be assigned a unique user identification (the “<b>User ID</b>”) and password from LAWPRO, both of which will be required in order to complete a policy application.

    c)     Only the Subscribing Lawyer or their Authorized User may access the Website through their individual User ID and password. The Subscribing Lawyer and their Authorized User shall each be responsible for the security of their individual User ID and password and shall not disclose it to any other person (including each other). The Subscribing Lawyer agrees to notify LAWPRO immediately of any unauthorized use of their User ID or password, or any other breach of security.

    d)     The Subscribing Lawyer shall use the Website strictly in accordance with the terms and provisions of this Agreement, the lawful requirements of any applicable governmental authorities, such rules of professional conduct as may be issued by the applicable Law Society, and the instructions and requirements issued by LAWPRO from time to time, including without limitation:
           (i)    any LAWPRO-approved training materials;

           (ii)   any instructions communicated through the Website; and

           (iii)  any instructions from or by an authorized LAWPRO representative;

       (collectively referred to as the “<b>Instructions</b>”) on the express understanding that the Instructions may be amended or varied (in whole or in part) on one or more occasions by LAWPRO either in writing (including electronically) or through direct verbal communications with the Subscribing Lawyer.

    e)     Certain materials and features may be added to or withdrawn from the Website without prior notice to the Subscribing Lawyer. The Subscribing Lawyer shall take all reasonable steps to ensure that the Website is not tampered with in any fashion, including without limitation, any attempts to translate, reverse engineer, decompile, disassemble, modify, or merge the Website with any other system.

    f)     The Subscribing Lawyer acknowledges and agrees that they have no right or entitlement under this Agreement to the use of the business name, logo, and related trademarks of LAWPRO (e.g., TitlePLUS) or any of its licensors or suppliers (collectively referred to as the “<b>Trademarks</b>”). In order to use one or more of the Trademarks, the Subscribing Lawyer must inform LAWPRO in writing of the Subscribing Lawyer’s intent to use it and receive written approval from LAWPRO.

<b>3.     SUBSCRIBING LAWYER’S ROLE IN TITLEPLUS TITLE INSURANCE</b>

    a)     The Subscribing Lawyer specifically acknowledges and agrees that:

           (i)    they will only use the TitlePLUS functionality within the Website in connection with the submission of applications for TitlePLUS policies on behalf of Clients (as defined below);

           (ii)   TitlePLUS policies are only issued by LAWPRO if LAWPRO has approved an application submitted through the Website, whether directly by the Subscribing Lawyer, an Authorized User or by LAWPRO staff on the Subscribing Lawyer’s or Authorized User’s behalf;

           (iii)  they will not purport to create, generate or distribute any TitlePLUS policies that are not issued by LAWPRO through the Website, whether directly by the Subscribing Lawyer or by LAWPRO staff on the Subscribing Lawyer’s behalf;

           (iv)   they will respond to all inquiries generated through the Website and provide all requisite information requested by said system;

           (v)    the provision of full responses and information to the Website, including the completion of all requisite data fields and inquiries, on or before a request for issuance of a policy, will constitute a certificate of title from the Subscribing Lawyer (on behalf of the Client as defined below) to and in favour of LAWPRO in respect of each property for which an application for TitlePLUS title insurance is submitted;

           (vi)   notwithstanding the provision of said certificate of title, the Subscribing Lawyer will be representing the purchaser(s), existing owner(s), and/or the mortgagee(s) (if applicable) (collectively referred to as the “<b>Client</b>”) of the property in the real estate transaction(s) giving rise to the issuing of the TitlePLUS policy and not representing LAWPRO, and shall be obliged to exercise care and judgment on behalf of the Client in accordance with the standards of a prudent solicitor in the jurisdiction where the Subscribing Lawyer practises; and

           (vii)  they will be directly responsible for all applications made and TitlePLUS policies issued under the Subscribing Lawyer’s name, using the Authorized User’s User ID and password, notwithstanding that access to and use of the Website may have been undertaken by one or more people authorized and supervised by the Subscribing Lawyer as described above.

    b)     LAWPRO has designed programs within the Website, including the New Home Program, pursuant to which LAWPRO will make arrangements regarding certain new residential subdivisions and condominiums to streamline the requirements for the Subscribing Lawyer acting on behalf of a purchaser of property in such developments.

           (i)    Within the New Home Program, LAWPRO shall provide to the Subscribing Lawyer certain general information and responses necessary to complete a TitlePLUS application (the “<b>Program Responses</b>”), so that the Subscribing Lawyer does not have to complete inquiries or investigations except those specifically set out in the New Home Program. The Subscribing Lawyer’s obligations under paragraphs 3.a)(v) and (vi) of this Agreement are deemed to be fulfilled by the completion of the TitlePLUS application using the Program Responses, but only in respect of the questions and inquiries addressed by the Program Responses.

           (ii)   Notwithstanding the provisions of paragraphs 8.a) and 8.c) of this Agreement, the Subscribing Lawyer will not be obligated to carry out any searches or inquiries that are obviated by the Program Responses, nor maintain records in respect thereof.

    c)     It is hereby acknowledged and agreed that the Subscribing Lawyer is not retained or employed by (or acting as an agent on behalf of) LAWPRO. The Subscribing Lawyer further acknowledges that:

           (i)    in the event of any dispute arising between any Client and LAWPRO, the Subscribing Lawyer will not act for any of the parties in relation to such dispute; and

           (ii)   no information in respect of the same transaction or the property insured under a TitlePLUS policy that is received by the Subscribing Lawyer in connection with LAWPRO issuing a TitlePLUS policy can be treated as confidential in so far as any Client or LAWPRO is concerned;

           provided however that the provisions of this paragraph will not, under any circumstances, prevent the Subscribing Lawyer from acting for the Client in the completion of such real estate transaction and the Subscribing Lawyer is hereby specifically authorized to do so by LAWPRO.

    d)     The Subscribing Lawyer shall not, without the prior written approval of LAWPRO:

           (i)    incur accounts or debts chargeable to LAWPRO;

           (ii)   adjust any claims for loss under a TitlePLUS policy;

           (iii)  accept service of an originating document in a legal proceeding against LAWPRO; or

           (iv)   alter forms furnished by LAWPRO. 

<b>4.   LAWPRO’S ROLE IN TITLEPLUS TITLE INSURANCE</b>

    a)     LAWPRO agrees to provide TitlePLUS coverage to the Clients from time to time upon proper application being made to and accepted by LAWPRO through the Website, whether directly by the Subscribing Lawyer or by LAWPRO staff on the Subscribing Lawyer’s behalf, provided that the Subscribing Lawyer complies with all terms of this Agreement.

    b)     In furtherance of the foregoing, LAWPRO shall advise the Subscribing Lawyer with reasonable promptness of its decision regarding acceptance or rejection of an application for TitlePLUS coverage.

    c)     <b>LAWPRO SHALL NOT BE LIABLE FOR ANY COSTS, CLAIMS, AND/OR DAMAGES ARISING FROM ITS INABILITY TO PROCESS APPLICATIONS FOR TITLEPLUS POLICIES AND/OR ISSUE TITLEPLUS POLICIES DUE TO ANY CAUSE, EXCEPT THOSE RELATED TO LAWPRO’S GROSS NEGLIGENCE OR WILFUL MISCONDUCT.</b>

<b>5.     ACCESS TO THE WEBSITE</b>

The Subscribing Lawyer shall be solely responsible for arranging on-line remote access at their sole cost and expense and for the acquisition, installation and operation of all necessary hardware, software and communications configuration at the Subscribing Lawyer’s location, required for the proper operation of the Website. The use of such equipment shall be at the Subscribing Lawyer’s sole expense and risk and LAWPRO makes no representation or warranty regarding the operation of the Website on, and/or the adequacy or fitness of, said equipment for any of the purposes set out herein.

<b>6.     PAYMENT OF THE TITLEPLUS PREMIUM</b>

    a)     The Subscribing Lawyer will receive from the Clients on behalf of LAWPRO all charges (including premiums and applicable taxes) (the “<b>TitlePLUS Charge</b>”) for TitlePLUS policies delivered by the Subscribing Lawyer, in accordance with the rates established by LAWPRO from time to time, notice of which will be given to the Subscribing Lawyer through the Website or upon ordering if the policy is being ordered on a manual basis. <b>Any monies received by the Subscribing Lawyer on account of a TitlePLUS Charge shall constitute trust monies to be held in trust for the Client until such time as the TitlePLUS Charge is paid to LAWPRO.</b>

    b)     Once a TitlePLUS policy is issued, the Subscribing Lawyer will be responsible to LAWPRO for payment of the TitlePLUS Charge. In the event of any failure by the Subscribing Lawyer to promptly remit such funds to LAWPRO in accordance with the provisions hereof and the rules of the Law Society governing trust funds, the Subscribing Lawyer shall be in breach of this Agreement.

    c)     LAWPRO’s granting of a license to the Subscribing Lawyer is conditional on the Subscribing Lawyer entering payment information and updating it as necessary. The Subscribing Lawyer acknowledges that the payment option that they select on the Website establishes the Subscribing Lawyer’s account with LAWPRO for purposes of payment of the TitlePLUS Charge, which payment shall be made by debit to such account unless otherwise stipulated by LAWPRO. The Subscribing Lawyer further acknowledges that the TitlePLUS Charge for any policy issued by LAWPRO will be debited to the Subscribing Lawyer’s account on the tenth business day of the month following the month of issuance.

    d)     The Subscribing Lawyer shall be responsible for any costs incurred by LAWPRO in collecting the TitlePLUS Charge, including without limitation any NSF charges, rejected credit card fees, and reasonable administrative costs for reinstating a delinquent Subscribing Lawyer.

<b>7.     LEGAL COUNSEL FEE</b>

LAWPRO may, in certain circumstances and in certain jurisdictions, pay the Subscribing Lawyer a legal counsel fee for the work done to complete the TitlePLUS underwriting requirements and policy application on behalf of the Client, including the provision of the certificate of title. The Subscribing Lawyer shall disclose to the Client that LAWPRO will pay the Subscribing Lawyer a fee for legal services performed for LAWPRO in relation to the Policy and that (i) this is not compensation for recommending TitlePLUS title insurance; and (ii) no commission or fee is being furnished by LAWPRO or any agent or intermediary with respect to any title insurance coverage.

<b>8.     MAINTENANCE OF RECORDS AND AUDIT RIGHTS</b>

    a)     The Subscribing Lawyer shall keep all title searches, acknowledgments, letter search responses and/or other documents which have been relied upon in connection with the issuance of TitlePLUS policies. The Subscribing Lawyer shall comply fully with any rules of professional conduct of the Law Society in respect of title search documentation.

    b)     The Subscribing Lawyer will keep safely in the Subscribing Lawyer’s possession any materials or documents provided by LAWPRO which are clearly identified as being the property of LAWPRO including, but not limited to, all training materials, policies, endorsements, forms and other supplies furnished to the Subscribing Lawyer by LAWPRO.

    c)     The Subscribing Lawyer agrees that at any reasonable time or times, the Subscribing Lawyer will permit to be examined and copied by LAWRO (at LAWPRO’s cost and expense, unless otherwise provided herein) all books, searches, abstracts and other records of the Subscribing Lawyer (including without limitation electronic records) relating to any TitlePLUS coverage issued by LAWPRO or in respect of which an application has been submitted to LAWPRO by the Subscribing Lawyer. Such right of examination shall not extend to records which do not relate to applications for TitlePLUS coverage or the property affected thereby. In addition, LAWPRO shall be entitled to examine at any reasonable time or times all financial records, statements and ledgers of the Subscribing Lawyer (including without limitation, any trust ledgers or similar records relating to the use and management of trust monies by the Subscribing Lawyer and/or their firm/organization) affecting Clients for whom applications for TitlePLUS coverage have been made.  The Subscribing Lawyer warrants that they have the authority at their firm/organization to allow the above-noted reviews or has obtained consent of their firm/organization to allow the reviews.  If the Subscribing Lawyer or their firm/organization does not make the relevant documents/records available to LAWPRO, LAWPRO may revoke the Licence granted to the Subscribing Lawyer herein.

<b>9.     SUBSCRIBING LAWYER’S ROLE RE: CLAIMS</b>

    a)     The Subscribing Lawyer shall (without any cost or charge whatsoever to LAWPRO) advise LAWPRO promptly in writing of any claim, possible claim or defect that comes to the Subscribing Lawyer’s attention (whether it is made in connection with legal proceedings or otherwise) in respect of TitlePLUS coverage issued by LAWPRO upon application of any Subscribing Lawyer on behalf of a Client. In connection with such matters and also without charge to LAWPRO, the Subscribing Lawyer shall:

           (i)    forward to LAWPRO copies of all search notes and other documentation; and

           (ii)   provide all reasonable assistance and information to assist LAWPRO with its investigation, adjustment or contestation of any such claim or defect, including without limitation attending at meetings or discoveries, providing affidavits or statutory declarations or giving oral testimony;

           as may be requested from time to time by LAWPRO. Notwithstanding anything contained herein, this obligation does not require the Subscribing Lawyer to act for LAWPRO or to give LAWPRO legal advice in relation to a claim by one or more Clients under a TitlePLUS policy.

    b)     Subject to paragraph 9.c) below and any agreement affecting Lawyer liability regarding TitlePLUS policies, between LAWPRO and the members of the Law Society:

           (i)    the Subscribing Lawyer shall have no liability for any costs, expenses or legal fees incurred in connection with any claim made under the TitlePLUS policy or for any judgment, award or sum paid in compromise or settlement of any claim asserted by or on behalf of an insured under a TitlePLUS policy; and

           (ii)   LAWPRO waives any rights of subrogation it may have against the Subscribing Lawyer in respect of any or all of the foregoing matters.

    c)     Notwithstanding any other section in this Agreement, the Subscribing Lawyer shall be liable to LAWPRO for any loss resulting from:

           (i)    the Subscribing Lawyer’s failure to comply with the terms of this Agreement or the Instructions because of their intentional act or omission, gross negligence or wilful misconduct; and/or      

           (ii)   any fraudulent act or omission by the Subscribing Lawyer.

<b>10.    TERMINATION OF THE LICENCE</b>

    a)     Subject to the terms and provisions of paragraph 10.b) below, the Licence granted to the Subscribing Lawyer herein (and the Subscribing Lawyer’s corresponding entitlement to apply for TitlePLUS policies on behalf of their Client) may be revoked or limited by LAWPRO or released by the Subscribing Lawyer at any time by a written notice of intention to revoke or release (as the case may be) served in the manner set out in this Agreement. Any release, limitation or revocation by the Subscribing Lawyer or LAWPRO shall take effect immediately upon receipt of the notice delivered in the manner specified in this Agreement.

    b)     Upon revocation or release of the Licence in the manner described above, the Subscribing Lawyer shall:

           (i)    no longer have access to the Website to create or complete new policy applications;

           (ii)   furnish to LAWPRO a complete accounting of the TitlePLUS Charge owing, if any, and settle any outstanding amounts; and

           (iii)  return to LAWPRO all materials related to the Website which are the property of LAWPRO;

           provided however that the revocation or release of the Licence shall not affect the Subscribing Lawyer’s obligations to LAWPRO, or the benefits arising out of paragraphs 3.b)(ii) and 8.b) herein, in respect of any approvals for the issuance of a TitlePLUS policy and/or TitlePLUS policies issued by LAWPRO prior to such revocation or release.

<b>11.  INTELLECTUAL PROPERTY RIGHTS</b>

Title to the Website, all associated business processes and all TitlePLUS documentation shall at all times remain with LAWPRO or its licensors and suppliers, and the Subscribing Lawyer shall have no rights therein except as may be expressly granted by this Agreement. The TitlePLUS trademark, the Website and all TitlePLUS documentation are the sole property of LAWPRO or its licensors and suppliers and are protected by copyright and other intellectual property laws. Unauthorized access to, or reproduction, use and/or distribution of, the TitlePLUS trademark, the Website and any TitlePLUS documentation or any portion thereof may result in serious civil and criminal penalties and in prosecution to the full extent of the law. For the purposes of this Agreement, “<b>TitlePLUS documentation</b>” includes the contents of the Website, as amended from time to time.

<b>12.  NO WARRANTIES OR REPRESENTATIONS BY LAWPRO</b>

<b>THE SUBSCRIBING LAWYER EXPRESSLY ACKNOWLEDGES AND AGREES THAT THE WEBSITE HAS BEEN DESIGNED AND MADE AVAILABLE IN ORDER TO FACILITATE APPLICATIONS FOR, AND THE ISSUANCE OF, TITLEPLUS POLICIES AND THAT THE WEBSITE SHALL BE USED FOR NO OTHER PURPOSE. LAWPRO MAKES NO REPRESENTATION OR WARRANTY (WHETHER EXPRESS OR IMPLIED) REGARDING THE QUALITY OR FITNESS FOR PURPOSE, WHETHER ARISING BY LAW, STATUTE, USAGE OF TRADE OR COURSE OF DEALING, OF THE WEBSITE. LAWPRO SHALL NOT BE LIABLE FOR ANY LOSS OR DAMAGE, INCLUDING LOSS OF PROFITS, LOSS OF BUSINESS REVENUE OR FAILURE TO REALIZE EXPECTED SAVINGS OR FOR ANY INDIRECT, SPECIAL OR CONSEQUENTIAL DAMAGES ARISING OUT OF THE USE OF, OR THE ABILITY TO USE, THE WEBSITE, EVEN IF NOTICE OF SAME HAS BEEN PROVIDED TO LAWPRO.</b>

<b>13.  DUE DILIGENCE BY LAWPRO</b>

The Subscribing Lawyer acknowledges that it is in the best interest of the Subscribing Lawyer and LAWPRO for LAWPRO to perform due diligence concerning the Subscribing Lawyer’s background and experience, which may include due diligence concerning the Subscribing Lawyer’s firm, organization and/or employees. The Subscribing Lawyer further acknowledges that they benefit from the efficiencies in the due diligence process that are possible when LAWPRO and other similarly-situated entities in the title insurance/mortgage industry exchange information about their experiences in doing business with individual professionals such as the Lawyer. Therefore, the Subscribing Lawyer hereby consents and gives LAWPRO permission to submit their name for screening through any and all mortgage and/or insurance industry background databases. The Subscribing Lawyer understands that LAWPRO performs quality control reviews of the title insurance applications that the Subscribing Lawyer submits to LAWPRO and hereby consents to the release of information about any title insurance application that is believed by LAWPRO to contain misrepresentations and/or irregularities. The Subscribing Lawyer warrants that they have the authority at their firm/organization to allow LAWPRO to take the aforementioned actions or has obtained consent of their firm/organization and its employees to allow the actions. If the Subscribing Lawyer’s firm/organization or its employees do not provide the requisite consent, LAWPRO may, in its sole discretion, refuse to grant a Licence or revoke the Licence granted to the Subscribing Lawyer herein.

<b>14.  NOTICE</b>

Any notice desired or required to be given under this Agreement shall be in writing and shall be sent by personal delivery, electronically via the Website, by mail, by courier, by email or by facsimile transmission to the parties at the following addresses:

To LAWPRO:
                    250 Yonge Street
                    Suite 3101
                    Toronto, Ontario
                    M5B 2L7

                    Attention: TitlePLUS Department

                    Fax: (416) 599-8341
                    Email: titleplus@lawpro.ca
        
To the Subscribing Lawyer:

                    at the address, fax number and/or email address in LAWPRO’s records or as set out during the registration process

or at such other address as the parties hereto may otherwise in writing advise. Any notice given in the manner set out in this paragraph shall be deemed to have been received on the date of personal delivery, on the date of electronic transmission via the Website, on the second business day following the day of mailing or sending by courier, on the date of facsimile transmission (provided a confirmation of transmission receipt is produced at the time of facsimile transmission), or on the date of email transmission.

<b>15.  NO ASSIGNMENT</b>

This Agreement is not assignable by the Subscribing Lawyer without the prior written consent of LAWPRO. LAWPRO may unilaterally assign its rights and obligations (either in whole or in part and on one or more occasions) under this Agreement upon written notice of such assignment to the Subscribing Lawyer. This Agreement is, however, binding on and enures to the benefit of any successors, heirs, executors and permitted assigns of the parties hereto. As of the date written above, this Agreement supersedes any preceding Subscription Agreement or Terms of Use Agreement between the Subscribing Lawyer and LAWPRO.

<b>16.  JURISDICTION</b>

This Agreement shall be read and governed by, and construed in accordance with, the laws of the Province of Ontario.

<b>17.  ENTIRE AGREEMENT</b>

This Agreement constitutes the entire agreement between the parties with respect to the use of the Website and the right of the Subscribing Lawyer to make application for TitlePLUS title insurance. Any amendments must be made in writing and signed by all the parties.

<b>18.  SEVERABILITY OF TERMS</b>

The terms and provisions of this Agreement are severable and distinct and if any term of this Agreement is found to be invalid or unenforceable by a court of competent jurisdiction, then such determination shall not impair the validity or enforceability of the remaining provisions of this Agreement, which shall remain in full force and effect.

<b>19.  ACCEPTANCE BY LAWPRO</b>

This Agreement is subject to acceptance by LAWPRO and is not binding on the parties until LAWPRO advises the Subscribing Lawyer of its acceptance, in writing.

<hr />
<a href="#_ftnref1" name="_ftn1" title="">[1]</a> Applicable in all Canadian provinces and territories except Quebec`;

  return (
    <Typography
      component="pre"
      style={{ whiteSpace: "pre-wrap"}}
      dangerouslySetInnerHTML={{ __html: rawHtml }}
    >
    </Typography>
  );
}

export default TandCLawyer;

// export default rawHtml;
