import {
  Box,
  Collapse,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Fact from "Components/Fact";
import { Question, SectionHeader } from "Components/Questionary";
import { cloneDeep, isEmpty } from "lodash";
import { PropertyTypeEnum, TransactionTypeEnum } from "Model/PolicyOrder";
import Conditional from "Policy/Conditional";
import { PolicyApiClient } from "Policy/PolicyApiClient";
import React, { Fragment, useMemo } from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { StringUtil } from "Util/Helpers";
import { QuestionaryHelper } from "Util/QuestionaryHelper";
import { ValidationHelper } from "Util/ValidationHelper";
import ClosingInstructions from "./ClosingInstructions";
import Fraud from "./Fraud";
import MortgagesSection from "./MortgagesSection";
import NHPCondoLegalDescriptionSection from "./NHPCondoLegalDescriptionSection";
import NHPFreeholdLegalDescriptionSection from "./NHPFreeholdLegalDescriptionSection";
import OffTitle from "./OffTitle";
import PurchasersSection from "./PurchasersSection";
import TitleSurvey from "./TitleSurvey";
import { grey } from '@mui/material/colors';

const useStyles = makeStyles((theme) => ({
  header: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    backgroundColor: grey[200],
  },
  plainLink: {
    color: "inherit",
    "&:focus, &:hover, &:visited, &:link, &:active": {
      textDecoration: "none",
    },
    width: "100%",
    textAlign: "left",
  },
  lablelessTopBuffer: {
    paddingTop: 20,
  },
}));

function createQuestionarySelector() {
  return createSelector(
    (state) => state.order.questionary,
    (x) => x
  );
}

function createMortgagesSelector() {
  return createSelector(
    (state) => state.order.mortgages,
    (_, mortId) => mortId,
    (x, mortId) =>
      x.filter((m) => StringUtil.isEqual(m.MortgagePropertyId, mortId))
  );
}

function createPurchasersSelector() {
  return createSelector(
    (state) => state.order.purchasers,
    (_, propId) => propId,
    (x, propId) =>
      x.filter((p) => StringUtil.isEqual(p.PurchaserPropertyId, propId))
  );
}

function ExistingOwnerHasTIPolicy(props) {
  const classes = useStyles();
  const { item, questionParams, collectionName, getInitialAnswer } = props;

  let qParams = { ...questionParams };

  qParams.indexer = QuestionaryHelper.createIndexer(collectionName, item);

  const conditionalProps = {
    indexer: qParams.indexer,
    getInitialAnswer: getInitialAnswer,
  };

  return (
    <Fragment>
      <Question qId="ExistingOwnerHasTIPolicy" {...qParams} />
      <Conditional
        upon="ExistingOwnerHasTIPolicy"
        when={(x) => StringUtil.isYes(x)}
        {...conditionalProps}
      >
        <Fact error>
          If the registered owner already has title insurance, they are not
          eligible for Existing Owner Policy.
        </Fact>
      </Conditional>

      <Conditional
        upon="ExistingOwnerHasTIPolicy"
        when={(x) => StringUtil.isNo(x)}
        {...conditionalProps}
      >
        <Question qId="AcquisitionDate" {...qParams} />
        <Question qId="PropertyValueDeterminedBy" {...qParams} />
        <Question qId="PropertyValue" {...qParams} />
      </Conditional>
    </Fragment>
  );
}

function PropertyInfo(props) {
  const classes = useStyles();

  let { questionary } = props;
  const {
    item,
    idx,
    questionParams,
    canAdd,
    add,
    remove,
    openIdx,
    setOpenIdx,
    collectionName,
    loadQuestionary,
    getInitialAnswer,
  } = props;

  let qParams = { ...questionParams };

  const isOpen = idx === openIdx;

  const isFirst = idx === 0;

  qParams.indexer = QuestionaryHelper.createIndexer(collectionName, item);

  function expandCollapse() {
    //TODO Remove
    return;
    if (isOpen) {
      setOpenIdx(-1);
    } else {
      setOpenIdx(idx);
      loadQuestionary(
        item.TransactionType,
        item.PropertyType,
        item.IsEnrolledInNHP,
        item.PropertyAddressProvince
      );
    }
  }

  const province = useSelector((x) => x.order.province);

  //#region Mortgage Dependencies
  const selectMortgages = useMemo(createMortgagesSelector, []);

  const mortgages = useSelector((x) => selectMortgages(x, item.Id));
  //#endregion Mortgage Dependencies

  //#region Purchaser Dependencies
  const selectPurchasers = useMemo(createPurchasersSelector, []);

  const purchasers = useSelector((x) => selectPurchasers(x, item.Id));
  //#endregion Purchaser Dependencies

  const nhpFreeholdLegalDescriptions = useSelector(
    (x) => x.order.nhpFreeholdLegalDescriptions
  ).filter((n) =>
    StringUtil.isEqual(n.NHPFreeholdLegalDescriptionPropertyId, item.Id)
  );
  const nhpCondoLegalDescriptions = useSelector(
    (x) => x.order.nhpCondoLegalDescriptions
  ).filter((n) =>
    StringUtil.isEqual(n.NHPCondoLegalDescriptionPropertyId, item.Id)
  );

  const selectQuestionary = useMemo(createQuestionarySelector, []);

  const reloadedQuestionary = useSelector((x) => selectQuestionary(x));

  if (reloadedQuestionary && !isEmpty(reloadedQuestionary)) {
    const reloadedQuestionaryCopy = cloneDeep(reloadedQuestionary); // reloadedQuestionary is ro and we need to make the questions editable...

    questionary = reloadedQuestionaryCopy;
    qParams.questionary = reloadedQuestionaryCopy;
    questionParams.questionary = reloadedQuestionaryCopy;
  }

  const conditionalProps = {
    indexer: qParams.indexer,
    getInitialAnswer: getInitialAnswer,
  };

  async function searchProjectsEventHandler(searchCriteria) {
    const resp = await PolicyApiClient.searchProject(searchCriteria);

    if (resp.hasError) return null;

    const respData = resp.data;
    const validationMap = ValidationHelper.getValidationMap(respData);
    if (!StringUtil.isNullOrEmpty(validationMap)) return null;

    const opts = respData.ProjectOptions;

    return opts;
  }

  const allowLROFreeText = questionary.Version <= 3;

  //#region Property
  const property = (
    <Fragment>
      <Question qId="TransactionType" {...qParams} />

      <Question qId="IsEnrolledInNHP" {...qParams}>
        <Question
          qId="ShowNewHomeProgramWarningForPartners"
          {...qParams}
          hideLabel
        />

        <Conditional
          upon="IsEnrolledInNHP"
          when={(x) => StringUtil.isYes(x)}
          {...conditionalProps}
        >
          <Question
            qId="NHPCode"
            {...qParams}
            onSearchEventHandler={searchProjectsEventHandler}
          />
          <Conditional
            upon="ProjectName"
            when={(x) => !StringUtil.isNullEmptyOrWhiteSpace(x)}
            {...conditionalProps}
          >
            <Fact success>
              <Question
                qId="ProjectName"
                {...qParams}
                txtWidth={150}
                noborder
                hideLabel
                readOnly
              />
            </Fact>

            <Fact info>
              This property is part of the TitlePLUS New Home Program! Your
              order will be pre-populated with title information and you are not
              required to conduct a title search.
            </Fact>
          </Conditional>

          <Conditional
            upon="AdditionalProjectInformation"
            when={(x) => !StringUtil.isNullEmptyOrWhiteSpace(x)}
            {...conditionalProps}
          >
            <Fact info>
              <Question
                qId="AdditionalProjectInformation"
                {...qParams}
                txtWidth={150}
                multiline
                noborder
                hideLabel
                readOnly
              />
            </Fact>
          </Conditional>
        </Conditional>
      </Question>

      <Question qId="PropertyType" {...qParams} />
      {/* <Conditional upon="PropertyType" when={(x) => StringUtil.isEqual(x, PropertyTypeEnum.Leasehold)} {...conditionalProps}>
            <Fact info>Leasehold properties are subject to review and additional searches</Fact>
        </Conditional>
        <Conditional upon="PropertyType" when={(x) => StringUtil.isEqual(x, PropertyTypeEnum.Farm)} {...conditionalProps}>
            <Fact info>Farm properties are subject to review and additional searches</Fact>
        </Conditional>
        <Conditional upon="PropertyType" when={(x) => StringUtil.isEqual(x, PropertyTypeEnum.Commercial)} {...conditionalProps}>
            <Fact info>Commercial properties are subject to review and additional searches</Fact>
        </Conditional> */}
      {/* <Conditional
        upon="PropertyType"
        when={(x) => StringUtil.isEither(x, PropertyTypeEnum.Leasehold)}
        {...conditionalProps}
      >
        <Fact info>Policies for Leasehold properties are currently not available.</Fact>
      </Conditional> */}

      <Question qId="IsParcelOfTiedLand" {...qParams}>
        <Conditional
          upon="IsParcelOfTiedLand"
          when={(x) => StringUtil.isYes(x)}
          {...conditionalProps}
        >
          <Question
            qId="CommonElementsCondoNumber"
            {...qParams}
            txtWidth={50}
          />
        </Conditional>
      </Question>
      <Question qId="NumberOfUnits" {...qParams} short />
      <Question qId="PropertyAddress" {...qParams} optional>
        <Question qId="PropertyAddressStreet" {...qParams} txtWidth={50} />
        <Question
          qId="PropertyAddressUnit"
          {...qParams}
          optional
          txtWidth={25}
        />
        <Question qId="PropertyAddressCity" {...qParams} txtWidth={50} />
        <Question qId="PropertyAddressProvince" {...qParams} readOnly />
        <Question
          qId="PropertyPostalCode"
          {...qParams}
          txtWidth={25}
          optional
        />
        <Question qId="PropertyPIN" {...qParams} province={province} short />

        <Conditional
          upon="TransactionType"
          when={(x) => StringUtil.isEqual(x, TransactionTypeEnum.New)}
          {...conditionalProps}
        >
          <Conditional
            upon="IsEnrolledInNHP"
            when={(x) => StringUtil.isYes(x)}
            {...conditionalProps}
          >
            <Conditional
              upon="PINPrefix"
              when={(x) => !StringUtil.isNullEmptyOrWhiteSpace(x)}
              {...conditionalProps}
            >
              <Fact warning>
                <Question
                  qId="PINPrefix"
                  {...qParams}
                  txtWidth={50}
                  noborder
                  hideLabel
                  readOnly
                  inline
                />
              </Fact>
            </Conditional>
          </Conditional>
        </Conditional>

        <Conditional
          upon="IsParcelOfTiedLand"
          when={(x) => StringUtil.isYes(x)}
          {...conditionalProps}
        >
          <Question
            qId="CommonElementsPIN"
            {...qParams}
            inline
            txtWidth={25}
            disabled
          />
        </Conditional>
        <Box mb={2} />
      </Question>

      {/* <Question qId="NHPFreeholdLegalDescription" {...qParams} optional>
            <FormGroup row>
                <Box className={classes.lablelessTopBuffer}>
                    <Question qId="SubDivisionType" {...qParams} inline txtWidth={25} hideLabel />
                </Box>
                <Question qId="LotNumber" {...qParams} inline txtWidth={25} />
                <Question qId="Plan" {...qParams} inline txtWidth={25} />
                <Conditional upon="SubDivisionType" when={(x) => StringUtil.isEither(x, "PartOfLot", "PartOfBlock")} {...conditionalProps}>
                    <Question qId="SubSubDivision" {...qParams} short inline txtWidth={25} />
                    <Question qId="ReferencePlan" {...qParams} short inline txtWidth={25} />
                </Conditional>
            </FormGroup>
            <Box mb={2} />
        </Question> */}

      <Conditional
        upon="PropertyType"
        when={(x) =>
          StringUtil.isEither(
            x,
            PropertyTypeEnum.SingleFamily,
            PropertyTypeEnum.MultiUnit,
            PropertyTypeEnum.Freehold,
            PropertyTypeEnum.VacantLand
          )
        }
        {...conditionalProps}
      >
        <NHPFreeholdLegalDescriptionSection
          {...props}
          itemsList={nhpFreeholdLegalDescriptions}
          property={item}
        />
      </Conditional>
      <Conditional
        upon="PropertyType"
        when={(x) => StringUtil.isEqual(x, PropertyTypeEnum.Condominium)}
        {...conditionalProps}
      >
        <NHPCondoLegalDescriptionSection
          {...props}
          itemsList={nhpCondoLegalDescriptions}
          property={item}
        />
      </Conditional>

      <Question qId="PropertyLegalDescription" {...qParams} multiline={true} />
      <Question qId="IsPropertyInLandRegistry" {...qParams} />
      <Question
        qId="LandRegistryOffice"
        allowFreeText={allowLROFreeText}
        {...qParams}
        txtWidth={50}
      />

      <Conditional
        upon="LandRegistryOffice"
        when={(x) => StringUtil.contains(x, "Sudbury")}
        {...conditionalProps}
      >
        <Question qId="PropertySearchRequest" {...qParams}>
          <Conditional
            upon="PropertySearchRequest"
            when={(x) => StringUtil.isEqual(x, "search_not_done")}
            {...conditionalProps}
          >
            <Fact error>Not eligible for TitlePLUS!</Fact>
          </Conditional>
        </Question>

        <Conditional
          upon="TransactionType"
          when={(x) => StringUtil.isEqual(x, TransactionTypeEnum.ExistingOwner)}
          {...conditionalProps}
        >
          <Conditional
            upon="PropertyType"
            when={(x) =>
              StringUtil.isEither(
                x,
                PropertyTypeEnum.SingleFamily,
                PropertyTypeEnum.MultiUnit,
                PropertyTypeEnum.VacantLandCondo
              )
            }
            {...conditionalProps}
          >
            <Fact error>Not eligible for TitlePLUS!</Fact>
          </Conditional>
        </Conditional>

        <Conditional
          upon="AddExistingOwnerPolicy"
          when={(x) => StringUtil.isYes(x)}
          {...conditionalProps}
        >
          <Conditional
            upon="PropertyType"
            when={(x) =>
              StringUtil.isEither(
                x,
                PropertyTypeEnum.SingleFamily,
                PropertyTypeEnum.MultiUnit,
                PropertyTypeEnum.VacantLandCondo
              )
            }
            {...conditionalProps}
          >
            <Fact error>Not eligible for TitlePLUS!</Fact>
          </Conditional>
        </Conditional>
      </Conditional>

      <Question qId="ActingFor" {...qParams} />
      <Question qId="CoverageType" {...qParams} />
      <Question qId="PurchasePrice" {...qParams} />
      <Question qId="PurchasePriceInclHST" {...qParams} />
      <Conditional
        upon="CoverageType"
        when={(x) =>
          StringUtil.isEither(x, "owner_and_lender", "lender_policy_only")
        }
        {...conditionalProps}
      >
        <Question qId="IsMortgagePresent" {...qParams} />
      </Conditional>
      <Question qId="AddExistingOwnerPolicy" {...qParams} />

      <Conditional
        upon="TransactionType"
        when={(x) => StringUtil.isEqual(x, TransactionTypeEnum.ExistingOwner)}
        {...conditionalProps}
      >
        <ExistingOwnerHasTIPolicy {...props} />
      </Conditional>

      <Conditional
        upon="AddExistingOwnerPolicy"
        when={(x) => StringUtil.isYes(x)}
        {...conditionalProps}
      >
        <Conditional
          upon="PropertyType"
          when={(x) =>
            StringUtil.isEither(
              x,
              PropertyTypeEnum.Commercial,
              PropertyTypeEnum.Farm,
              PropertyTypeEnum.Leasehold
            )
          }
          {...conditionalProps}
        >
          <Fact error>
            The property type is not eligible for Existing Owner Policy
          </Fact>
        </Conditional>

        {!StringUtil.isEqual(province, "ON") && (
          <Fact error>Not eligible for TitlePLUS!</Fact>
        )}

        <Conditional
          upon="PropertyType"
          when={(x) =>
            !StringUtil.isEither(
              x,
              PropertyTypeEnum.Commercial,
              PropertyTypeEnum.Farm,
              PropertyTypeEnum.Leasehold
            )
          }
          {...conditionalProps}
        >
          <ExistingOwnerHasTIPolicy {...props} />
        </Conditional>
      </Conditional>
    </Fragment>
  );
  //#endregion Property

  return (
    <Fragment>
      <Box p={1} m={1} display="flex" className={classes.header}>
        <Box flexGrow={1}>
          <Link
            className={classes.plainLink}
            component="button"
            onClick={(e) => {
              e.preventDefault();
              expandCollapse();
            }}
          >
            <Typography>Property {idx + 1}</Typography>
          </Link>
        </Box>
        <Box mr={1}>
          <Tooltip title="Expand/Collapse">
            <span>
              <IconButton
                aria-label="expand/collapse"
                size="small"
                disabled={!canAdd}
                onClick={() => {
                  expandCollapse();
                }}
              >
                {isOpen ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </span>
          </Tooltip>
        </Box>
        <Box mr={1}>
          <Tooltip title="Add Property - Coming Soon!">
            <span>
              <IconButton
                aria-label="add"
                size="small"
                disabled={!canAdd}
                disabled={true}
                onClick={add}
              >
                <AddIcon />
              </IconButton>
            </span>
          </Tooltip>
        </Box>
        <Box>
          <Tooltip title="Remove - Coming Soon!">
            <span>
              <IconButton
                aria-label="remove"
                size="small"
                disabled={isFirst}
                onClick={() => {
                  remove(item);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </span>
          </Tooltip>
        </Box>
      </Box>
      <Collapse in={isOpen}>
        <Box m={2}>
          {property}
          <PurchasersSection
            {...props}
            itemsList={purchasers}
            propertyId={item.Id}
            property={item}
            propertyConditionalProps={conditionalProps}
          />
          <MortgagesSection
            {...props}
            itemsList={mortgages}
            propertyId={item.Id}
            property={item}
            propertyConditionalProps={conditionalProps}
          />
          <TitleSurvey
            {...props}
            conditionalProps={conditionalProps}
            questionary={questionParams.questionary}
          />
          <OffTitle
            {...props}
            conditionalProps={conditionalProps}
            questionary={questionParams.questionary}
          />

          <SectionHeader
            section="additionalConcerns"
            title="Additional Concerns"
            questionary={questionParams.questionary}
            subSection
          />
          <Question
            qId="AdditionalAdverseMatters"
            multiline={true}
            optional={true}
            {...qParams}
          />

          <Fraud
            {...props}
            conditionalProps={conditionalProps}
            questionary={questionParams.questionary}
          />
          <Conditional
            upon="NHPCode"
            when={(x) => !StringUtil.isNullOrEmpty(x)}
            {...conditionalProps}
          >
            <ClosingInstructions {...qParams} />
          </Conditional>
        </Box>
      </Collapse>
    </Fragment>
  );
}

export default PropertyInfo;
