import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { Info as InfoIcon } from '@mui/icons-material';
import React from 'react';

const useStyles = makeStyles(theme => ({
  infoMsg: {
    display: 'inline-flex',
    position: 'relative',
    left: '-198px',
    width: '770px'
  },
  icon: {
    marginRight: theme.spacing(0.5),
    color: theme.palette.primary.main,
    position: 'relative',
    top: 5
  }
}));

function MayUseLawPRO(props) {
  const { children } = props;

  const classes = useStyles();

  return (
    <Box ml={1.5} mr={1.5} pt={1} pb={1} pl={1.5} pr={1.5} border={1} borderColor="#165c7d" borderRadius="5px" className={classes.infoMsg}>
      <Typography>
        <InfoIcon className={classes.icon} />
        {children}
      </Typography>
    </Box>
  );
}

export default MayUseLawPRO;