import { AppSettings } from 'AppSettings';
import * as AppErrors from 'Model/AppErrors';
import { ApiHelper } from 'Util/ApiHelper';
import Logger from 'Util/Logger';
import ApiResponse from 'Model/ApiResponse';
import { IdGenerator } from 'Util/IdGenerator';

const urljoin = require('url-join');

class DocumentsApiClient {
    logger;

    constructor() {
        this.logger = new Logger(DocumentsApiClient.name);
    }

    async generatePolicyDocument(orderSecureId, dealNumber, policyNumber, documentType) {
        const rand = IdGenerator.NewId();

        const url = urljoin(AppSettings.urls.documents, `/policy/GenerateCertificate?orderSecureId=${orderSecureId}&dealNumber=${dealNumber}&policyNumber=${policyNumber}&documentType=${documentType}&rand=${rand}`);

        const authHeaders = await ApiHelper.authHeaders();

        // lambda will base64 binary content, no choice about it!
        // in api gateway -> settings -> Binary Media Types application/pdf needs to be added
        // however, api gateway will not know when to invoke the CONVERT_TO_BINARY converted, unless
        // the callee specifies application/pdf as the type it's expecting ...
        // text/html is added incase of error, etc

        try {
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    "accept": "application/pdf,text/html,application/xhtml+xml",
                    ...authHeaders
                }
            });

            const errorResp = await ApiHelper.httpRespToErrorApiResp(response);

            if(errorResp) {
                return errorResp;
            }

            const apiResp = new ApiResponse(false);
            
            apiResp.data = await response.arrayBuffer();

            return apiResp;
        }
        catch (ex) {
            this.logger.error('error generating pdf', ex);

            const errMsg = AppErrors.generic;

            const apiResp = new ApiResponse(true);
            apiResp.error = errMsg;

            return apiResp;
        }
    }

    async generateInvoice(orderSecureId) {
        const rand = IdGenerator.NewId();

        const url = urljoin(AppSettings.urls.documents, `/policy/generateinvoice?orderSecureId=${orderSecureId}&rand=${rand}`);

        const authHeaders = await ApiHelper.authHeaders();

        // lambda will base64 binary content, no choice about it!
        // in api gateway -> settings -> Binary Media Types application/pdf needs to be added
        // however, api gateway will not know when to invoke the CONVERT_TO_BINARY converted, unless
        // the callee specifies application/pdf as the type it's expecting ...
        // text/html is added incase of error, etc

        try {
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    "accept": "application/pdf,text/html,application/xhtml+xml",
                    ...authHeaders
                }
            });

            const errorResp = await ApiHelper.httpRespToErrorApiResp(response);

            if(errorResp) {
                return errorResp;
            }

            const apiResp = new ApiResponse(false);
            
            apiResp.data = await response.arrayBuffer();

            return apiResp;
        }
        catch (ex) {
            this.logger.error('error generating pdf', ex);

            const errMsg = AppErrors.generic;

            const apiResp = new ApiResponse(true);
            apiResp.error = errMsg;

            return apiResp;
        }
    }
}

const instance = new DocumentsApiClient();

export { instance as DocumentsApiClient }