export const landRegistryOffices = [
    {
        "Id": "Algoma Land Registry (NO. 01)",
        "Label": "Algoma Land Registry (NO. 01)",
        "Province": "ON"
    },
    {
        "Id": "Brant Land Registry (NO. 02)",
        "Label": "Brant Land Registry (NO. 02)",
        "Province": "ON"
    },
    {
        "Id": "Bruce Land Registry (NO. 03)",
        "Label": "Bruce Land Registry (NO. 03)",
        "Province": "ON"
    },
    {
        "Id": "Cochrane Land Registry (NO. 06)",
        "Label": "Cochrane Land Registry (NO. 06)",
        "Province": "ON"
    },
    {
        "Id": "Dufferin Land Registry (NO. 07)",
        "Label": "Dufferin Land Registry (NO. 07)",
        "Province": "ON"
    },
    {
        "Id": "Dundas Land Registry (NO. 08)",
        "Label": "Dundas Land Registry (NO. 08)",
        "Province": "ON"
    },
    {
        "Id": "Durham Land Registry (NO. 40)",
        "Label": "Durham Land Registry (NO. 40)",
        "Province": "ON"
    },
    {
        "Id": "Elgin Land Registry (NO. 11)",
        "Label": "Elgin Land Registry (NO. 11)",
        "Province": "ON"
    },
    {
        "Id": "Essex Land Registry (NO. 12)",
        "Label": "Essex Land Registry (NO. 12)",
        "Province": "ON"
    },
    {
        "Id": "Frontenac Land Registry (NO. 13)",
        "Label": "Frontenac Land Registry (NO. 13)",
        "Province": "ON"
    },
    {
        "Id": "Glengarry Land Registry (NO. 14)",
        "Label": "Glengarry Land Registry (NO. 14)",
        "Province": "ON"
    },
    {
        "Id": "Grenville Land Registry (NO. 15)",
        "Label": "Grenville Land Registry (NO. 15)",
        "Province": "ON"
    },
    {
        "Id": "Grey Land Registry (NO. 16)",
        "Label": "Grey Land Registry (NO. 16)",
        "Province": "ON"
    },
    {
        "Id": "Haldimand Land Registry (NO. 18)",
        "Label": "Haldimand Land Registry (NO. 18)",
        "Province": "ON"
    },
    {
        "Id": "Haliburton Land Registry (NO. 19)",
        "Label": "Haliburton Land Registry (NO. 19)",
        "Province": "ON"
    },
    {
        "Id": "Halton Land Registry (NO. 20)",
        "Label": "Halton Land Registry (NO. 20)",
        "Province": "ON"
    },
    {
        "Id": "Hamilton-Wentworth Land Registry (NO. 62)",
        "Label": "Hamilton-Wentworth Land Registry (NO. 62)",
        "Province": "ON"
    },
    {
        "Id": "Hastings Land Registry (NO. 21)",
        "Label": "Hastings Land Registry (NO. 21)",
        "Province": "ON"
    },
    {
        "Id": "Huron Land Registry (NO. 22)",
        "Label": "Huron Land Registry (NO. 22)",
        "Province": "ON"
    },
    {
        "Id": "Kenora Land Registry (NO. 23)",
        "Label": "Kenora Land Registry (NO. 23)",
        "Province": "ON"
    },
    {
        "Id": "Kent Land Registry (NO. 24)",
        "Label": "Kent Land Registry (NO. 24)",
        "Province": "ON"
    },
    {
        "Id": "Lambton Land Registry (NO. 25)",
        "Label": "Lambton Land Registry (NO. 25)",
        "Province": "ON"
    },
    {
        "Id": "Lanark Land Registry (NO. 27)",
        "Label": "Lanark Land Registry (NO. 27)",
        "Province": "ON"
    },
    {
        "Id": "Leeds Land Registry (NO. 28)",
        "Label": "Leeds Land Registry (NO. 28)",
        "Province": "ON"
    },
    {
        "Id": "Lennox Land Registry (NO. 29)",
        "Label": "Lennox Land Registry (NO. 29)",
        "Province": "ON"
    },
    {
        "Id": "Manitoulin Land Registry (NO. 31)",
        "Label": "Manitoulin Land Registry (NO. 31)",
        "Province": "ON"
    },
    {
        "Id": "Middlesex Land Registry (NO. 33)",
        "Label": "Middlesex Land Registry (NO. 33)",
        "Province": "ON"
    },
    {
        "Id": "Muskoka Land Registry (NO. 35)",
        "Label": "Muskoka Land Registry (NO. 35)",
        "Province": "ON"
    },
    {
        "Id": "Niagara North Land Registry (NO. 30)",
        "Label": "Niagara North Land Registry (NO. 30)",
        "Province": "ON"
    },
    {
        "Id": "Niagara South Land Registry (NO. 59)",
        "Label": "Niagara South Land Registry (NO. 59)",
        "Province": "ON"
    },
    {
        "Id": "Nipissing Land Registry (NO. 36)",
        "Label": "Nipissing Land Registry (NO. 36)",
        "Province": "ON"
    },
    {
        "Id": "Norfolk Land Registry (NO. 37)",
        "Label": "Norfolk Land Registry (NO. 37)",
        "Province": "ON"
    },
    {
        "Id": "Northumberland Land Registry (NO. 39)",
        "Label": "Northumberland Land Registry (NO. 39)",
        "Province": "ON"
    },
    {
        "Id": "Ottawa-Carleton Land Registry (NO. 04)",
        "Label": "Ottawa-Carleton Land Registry (NO. 04)",
        "Province": "ON"
    },
    {
        "Id": "Oxford Land Registry (NO. 41)",
        "Label": "Oxford Land Registry (NO. 41)",
        "Province": "ON"
    },
    {
        "Id": "Parry Sound Land Registry (NO. 42)",
        "Label": "Parry Sound Land Registry (NO. 42)",
        "Province": "ON"
    },
    {
        "Id": "Peel Land Registry (NO. 43)",
        "Label": "Peel Land Registry (NO. 43)",
        "Province": "ON"
    },
    {
        "Id": "Perth Land Registry (NO. 44)",
        "Label": "Perth Land Registry (NO. 44)",
        "Province": "ON"
    },
    {
        "Id": "Peterborough Land Registry (NO. 45)",
        "Label": "Peterborough Land Registry (NO. 45)",
        "Province": "ON"
    },
    {
        "Id": "Prescott Land Registry (NO. 46)",
        "Label": "Prescott Land Registry (NO. 46)",
        "Province": "ON"
    },
    {
        "Id": "Prince Edward Land Registry (NO. 47)",
        "Label": "Prince Edward Land Registry (NO. 47)",
        "Province": "ON"
    },
    {
        "Id": "Rainy River Land Registry (NO. 48)",
        "Label": "Rainy River Land Registry (NO. 48)",
        "Province": "ON"
    },
    {
        "Id": "Renfrew Land Registry (NO. 49)",
        "Label": "Renfrew Land Registry (NO. 49)",
        "Province": "ON"
    },
    {
        "Id": "Russell Land Registry (NO. 50)",
        "Label": "Russell Land Registry (NO. 50)",
        "Province": "ON"
    },
    {
        "Id": "Simcoe Land Registry (NO. 51)",
        "Label": "Simcoe Land Registry (NO. 51)",
        "Province": "ON"
    },
    {
        "Id": "Stormont Land Registry (NO. 52)",
        "Label": "Stormont Land Registry (NO. 52)",
        "Province": "ON"
    },
    {
        "Id": "Sudbury Land Registry (NO. 53)",
        "Label": "Sudbury Land Registry (NO. 53)",
        "Province": "ON"
    },
    {
        "Id": "Thunder Bay Land Registry (NO. 55)",
        "Label": "Thunder Bay Land Registry (NO. 55)",
        "Province": "ON"
    },
    {
        "Id": "Timiskaming Land Registry (NO. 54)",
        "Label": "Timiskaming Land Registry (NO. 54)",
        "Province": "ON"
    },
    {
        "Id": "Toronto Land Registry (NO. 80)",
        "Label": "Toronto Land Registry (NO. 80)",
        "Province": "ON"
    },
    {
        "Id": "Victoria Land Registry (NO. 57)",
        "Label": "Victoria Land Registry (NO. 57)",
        "Province": "ON"
    },
    {
        "Id": "Waterloo Land Registry (NO. 58)",
        "Label": "Waterloo Land Registry (NO. 58)",
        "Province": "ON"
    },
    {
        "Id": "Wellington Land Registry (NO. 61)",
        "Label": "Wellington Land Registry (NO. 61)",
        "Province": "ON"
    },
    {
        "Id": "York Land Registry (NO. 65)",
        "Label": "York Land Registry (NO. 65)",
        "Province": "ON"
    },
    {
        "Id": "Calgary",
        "Label": "Calgary",
        "Province": "AB"
    },
    {
        "Id": "Edmonton",
        "Label": "Edmonton",
        "Province": "AB"
    },
    {
        "Id": "Brandon",
        "Label": "Brandon",
        "Province": "MB"
    },
    {
        "Id": "Dauphin",
        "Label": "Dauphin",
        "Province": "MB"
    },
    {
        "Id": "Morden",
        "Label": "Morden",
        "Province": "MB"
    },
    {
        "Id": "Neepawa",
        "Label": "Neepawa",
        "Province": "MB"
    },
    {
        "Id": "Portage la Prairie",
        "Label": "Portage la Prairie",
        "Province": "MB"
    },
    {
        "Id": "Winnipeg",
        "Label": "Winnipeg",
        "Province": "MB"
    },
    {
        "Id": "Charlottetown",
        "Label": "Charlottetown",
        "Province": "PE"
    },
    {
        "Id": "Summerside",
        "Label": "Summerside",
        "Province": "PE"
    }
]