import React, { useEffect } from 'react';
import { Typography } from '@mui/material';
import RegistrationContainer from './RegistrationContainer';
import { AuthService } from 'Services/AuthService';
import { grey } from '@mui/material/colors';




function IneligibleUser(props) {    
    
    const delay = ms => new Promise(res => setTimeout(res, ms));
    useEffect(() => {
        async function signout() {
            await delay(5000);
            await AuthService.signOut();
          }
          signout();
        
      });

    return  (
        <RegistrationContainer>
            <Typography component="h1" variant="h5" style={{ color: grey[800] }}>You’re currently ineligible to order a TitlePLUS policy</Typography>            
        </RegistrationContainer>
    )
}

export default IneligibleUser;