import * as actions from './PinDirListActions';
import { createReducer } from '@reduxjs/toolkit';

const initialState = {
    pin_list: [],
    pin_list_loading: true,
    pin_list_loading_error: null,
    pin_list_total_records: 0,
    pin_list_total_pages: 0,
    pin_change_busy: { },
    pin: { },
    pin_loading_error: null,
};

const PinDirListReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(actions.set_pin, (state, action) => {
            state.pin = action.payload;
        })
        .addCase(actions.set_pin_list, (state, action) => {
            const withPagination = action.payload;

            const items = withPagination.Items ?? [];
            const totalRecords = withPagination.TotalRecords ?? 0;
            const numPages = withPagination.NumPages ?? 1;

            state.pin_list = items;
            state.pin_list_total_records = totalRecords;
            state.pin_list_total_pages = numPages;
        })
        .addCase(actions.set_pin_loading_error, (state, action) => {
            state.pin_loading_error = action.payload;
        })
        .addCase(actions.set_pin_list_loading, (state, action) => {
            state.pin_list_loading = action.payload;
        })
        .addCase(actions.set_pin_list_loading_error, (state, action) => {
            state.pin_list_loading_error = action.payload;
        })
        .addCase(actions.update_pin_change_busy, (state, action) => {
            state.pin_change_busy = action.payload;
        })
        .addCase(actions.clear_pin_change_busy, (state, action) => {
            state.pin_change_busy = {};
        })
        .addCase(actions.update_pin, (state, action) => {
            const { Id, PIN, Status, Province, LandRegistryOffice, Issues, LastUpdatedBy } = action.payload;
            const pin = state.pin;

            if (!pin || pin.Id < 1) { return; }

            pin.Status = Status;
            pin.Province = Province;
            pin.LandRegistryOffice = LandRegistryOffice;
            pin.Issues = Issues;
            pin.LastUpdatedBy = LastUpdatedBy;
        })
        .addCase(actions.toggle_pin_status, (state, action) => {
            const { Id, PIN, Status, Province, LandRegistryOffice, Issues, LastUpdatedBy } = action.payload;
            const pin = state.pin;

            if (!pin || pin.Id < 1) { return; }

            pin.Status = Status;
            pin.Province = Province;
            pin.LandRegistryOffice = LandRegistryOffice;
            pin.Issues = Issues;
            pin.LastUpdatedBy = LastUpdatedBy;
        })
        .addCase(actions.set_pin_handle_change, (state, action) => {
            const { fieldName, fieldValue } = action.payload;
            const currValue = state.pin[fieldName];
            if (currValue === fieldValue) { return; }
            state.pin[fieldName] = fieldValue;
        })
        .addCase(actions.delete_pin, (state, action) => {
            const {pinId} = action.payload;
            if (!pinId || pinId < 1) { return; }
        })
        .addDefaultCase((state, action) => {
        })
});

export default PinDirListReducer;