import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SectionHelper } from 'Util/SectionHelper';
import StringUtil from 'Util/StringUtil';
import * as ProjectActions from '../State/ProjectActions';
import ExceptionPropertyInfo from './ExceptionPropertyInfo';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(1)
    }
}));

function ExceptionProperties(props) {
    const classes = useStyles();

    const { questionParams, getInitialAnswer, exceptionId } = props;

    const properties = useSelector(x => x.project.exceptionRange);

    const itemsList = properties && properties.length > 0 ? properties.filter(x => StringUtil.isEqual(x.ExceptionId, exceptionId)) : [];

    const collectionName = "ExceptionRange";

    const dispatch = useDispatch();

    // first load
    useEffect(() => {
        let isCancelled = false;

        function onUnload() {
            isCancelled = true;
        }

        if (itemsList)
            if (itemsList.length === 0) {
                add();
            }

        return onUnload;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!itemsList)
        return null;

    const canAdd = itemsList.length < 20;


    function add() {
        const currCount = itemsList.length;

        const newItem = SectionHelper.addNewSection(collectionName, questionParams);

        newItem.ItemNumber = currCount + 1;

        newItem.ExceptionId = exceptionId;

        const fieldName = `${newItem.indexer}ExceptionId`;
        
        dispatch({ type: ProjectActions.setProjectInput.type, payload: { fieldName:fieldName, fieldValue:exceptionId } });

        dispatch({ type: ProjectActions.addExceptionRange.type, payload: newItem });
    }

    function remove(item) {
        SectionHelper.removeSection({ collectionName, item, questionParams });

        dispatch({ type: ProjectActions.removeExceptionRange.type, payload: item });
    }

    return (
        <React.Fragment>
            <div className={classes.root}>
                {itemsList.map((item, index) => {
                    return (
                        <ExceptionPropertyInfo
                            //key={item.Id}
                            key={index}
                            idx={index}
                            totalCount={itemsList.length}
                            collectionName={collectionName}
                            item={item}
                            canAdd={canAdd}
                            add={add}
                            remove={remove}
                            questionParams={questionParams}
                            getInitialAnswer={getInitialAnswer} />
                    );
                })}
            </div>
        </React.Fragment>
    );
}

export default ExceptionProperties;