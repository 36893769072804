import { Typography } from "@mui/material";
import React from "react";
import StringUtil from "Util/StringUtil";

function Label(props) {
  const { defaultValue, leadingChar, minStringLength } = props;
  let val = defaultValue;

  if (
    !StringUtil.isNullOrEmpty(leadingChar) &&
    !StringUtil.isNullEmptyOrWhiteSpace(minStringLength)
  ) {
    val = StringUtil.addLeadingChars(
      defaultValue,
      leadingChar,
      minStringLength
    );
  }

  return <Typography>{val}</Typography>;
}

export default Label;
