import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import { StringUtil } from 'Util/Helpers';

function inputValueSelector() {
    return createSelector(
        state => state,
        (_, fieldName) => fieldName,
        (x, fieldName) => x[fieldName] // case sensitive! ...
    );
}

function Conditional(props) {
    const { upon, when, getInitialAnswer, children, indexer } = props;

    const selectInputVal = useMemo(
        inputValueSelector,
        []
    );

    let fieldName = upon;

    if (!StringUtil.isNullEmptyOrWhiteSpace(indexer))
        fieldName = `${indexer}${upon}`;

    let inputValue = useSelector(x => selectInputVal(x.order.orderChanges, fieldName));

    if (!inputValue && getInitialAnswer) {
        inputValue = getInitialAnswer(fieldName);
    }

    if (when(inputValue) == true)
        return children;
    
    return null;
}

Conditional.propTypes = {
    upon: PropTypes.string.isRequired,
    when: PropTypes.func.isRequired,
    getInitialAnswer: PropTypes.func
};

export default Conditional;
