import { Button, TableRow, TextField, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';
import MuiTableCell from "@mui/material/TableCell";
import AddIcon from '@mui/icons-material/Add';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CurrencyTextField from '../../../Components/CurrencyTextField';
import React, { Fragment } from 'react';
import { StringUtil } from 'Util/Helpers';
import { IdGenerator } from '../../../Util/IdGenerator';

const useStyles = makeStyles((theme) => ({
    alignRight: {
        float: 'right'
    },
    inlineGroup: {
        display: 'inline-flex'
    }
}));

const TableCell = withStyles({
    root: {
        borderBottom: "none",
        padding: 0,
        paddingTop: 8,
        paddingLeft: 8
    }
})(MuiTableCell);

function Discount(props) {
    const classes = useStyles();
    const { item, setItem, remove, readOnly } = props;

    const getWidth = (val) => `${val.toString().length + 8}ch`;

    const inputProps = {
        InputProps: {
            disableUnderline: readOnly
        },
        readOnly: readOnly
    }

    return <Fragment key={item.Id}>
        <TableCell style={{ paddingLeft: 20 }}>
            {!readOnly && <Button
                type="button"
                size="small"
                startIcon={<HighlightOffIcon />}
                onClick={() => { remove(item) }}>
            </Button>}
            <TextField defaultValue={item.Description} name='Description' onChange={(event) => setItem(event, item)} {...inputProps} />
        </TableCell>
        <TableCell>
            <CurrencyTextField
                defaultValue={item.DiscountAmount}
                name='DiscountAmount'
                onChange={(event) => setItem(event, item)}
                className={classes.alignRight}
                {...inputProps}
                style={{ width: getWidth(item.DiscountAmount) }}
            />
        </TableCell>
    </Fragment>;
}

//Can add discounts
function Discounts(props) {
    const classes = useStyles();
    const { items, isAppliedAfterTax, setDiscount, addDiscount, removeDiscount, policyIdx, readOnly } = props;

    if (!items) return null;

    const discounts = items.filter(d => {
        let val = d.ApplyAfterTax;

        if (StringUtil.isNull(val))
            val = false;

        return val === isAppliedAfterTax;
    });

    function add() {
        if (readOnly)
            return;

        const newItem = {
            Id: IdGenerator.NewId(),
            Type: 'Custom',
            ApplyAfterTax: isAppliedAfterTax,
            DiscountAmount: 0
        };

        addDiscount(newItem, policyIdx);
    }

    function remove(item) {
        if (readOnly)
            return;

        removeDiscount(item, policyIdx);
    }

    function setItem(event, item) {
        if (readOnly)
            return;

        const name = event.target.name;
        let value = event.target.value;

        if (StringUtil.endsWith(name, 'Amount')) {
            if (StringUtil.isNullOrEmpty(value))
                value = '0';
            value = value.split(',').join('');
            value = parseFloat(value);
        }

        let discountItem = {
            ...item,
            [name]: value
        };

        setDiscount(discountItem, policyIdx);
    }

    const prefix = isAppliedAfterTax ? 'Post tax' : 'Pre tax';

    return <Fragment>
        <TableRow>
            <TableCell className={classes.inlineGroup}>
                {!readOnly && <Button
                    type="button"
                    size="small"
                    startIcon={<AddIcon />}
                    onClick={add}>
                </Button>}
                <Typography>{prefix} discounts</Typography>
            </TableCell>
        </TableRow>
        {discounts.map((d, index) => <TableRow key={index}><Discount item={d} idx={index} remove={remove} setItem={setItem} readOnly={readOnly} /></TableRow>)}
    </Fragment>;
}

export default Discounts;