import * as UserRoles from './UserRoles';

export const view_status_page = "view_status_page";
export const decision_deal = "decision_deal";

export const delete_note = "deal_note";
export const change_note = "change_note";

export const view_deal_tags = "view_deal_tags";
export const tag_deal = "tag_deal";

export const view_ownership = "view_ownership";
export const change_ownership = "change_ownership";

export const view_transaction_type_filter = "view_transaction_type_filter";
export const view_property_type_filter = "view_property_type_filter";
export const view_is_enrolled_in_nhp_filter = "view_is_enrolled_in_nhp_filter";

export const view_contributors = "view_contributors";
export const change_contributors = "change_contributors";

export const create_deal = "create_deal";
export const change_exceptions = "change_exceptions";
export const change_endorsements = "change_endorsements";
export const change_insure_overs = "change_insure_overs";
export const view_background_checks = "view_background_checks";

export const view_action_logs = "view_action_logs";
export const view_admin_menu = "view_admin_menu";
export const view_users_list = "view_users_list";

// The purpose or coarse grain is to define what is *possible* for each user type.
// Possible does not equate to permissible.
// For example, it may be possible for user to view a deal, but only *certain* deals.
// Coarse grain only deals with possibilities, not with particularities. For particularities
// you have to write additional checks (in API layer). Keep in mind APIs should not trust or use this. This is to fence
// off or "hide" UI stuff. The true guard and check must be done at API layer.
//
// Q. If we are doing checks on API layer (as you must), why are we doing his on UI layer as well?
// A. B/c of UX. It's sub-ideal to show a button to a user or a drop down (etc) only for it to now work. You are teasing the user,
// dangling stuff they cannot use. That is why we hide such things on the UI.
export const byUserTypeCoarseGrain = new Map();

byUserTypeCoarseGrain.set(UserRoles.Clerk, [view_status_page, create_deal]);
byUserTypeCoarseGrain.set(UserRoles.Lawyer, [view_status_page, create_deal]);

byUserTypeCoarseGrain.set(UserRoles.UnderwritingManager, [
    view_status_page,
    view_background_checks,
    decision_deal,
    delete_note,
    change_note,
    view_deal_tags,
    tag_deal,
    view_ownership,
    change_ownership,
    view_contributors,
    change_contributors,
    change_endorsements,
    change_exceptions,
    change_insure_overs,
    create_deal,
    view_action_logs,
    view_admin_menu,
    view_users_list,
    view_transaction_type_filter,
    view_property_type_filter,
    view_is_enrolled_in_nhp_filter
]);
