import { Box, Button, FormGroup } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Question } from 'Components/Questionary';
import Conditional from 'Policy/Conditional';
import React from 'react';
import { QuestionaryHelper } from 'Util/QuestionaryHelper';
import StringUtil from 'Util/StringUtil';

function NHPFreeholdLegalDescriptionInfo(props) {
    const { item, idx, canAdd, add, collectionName, totalCount, remove, questionParams, getInitialAnswer } = props;

    const isFirst = idx === 0;

    let qParams = { ...questionParams };

    qParams.indexer = QuestionaryHelper.createIndexer(collectionName, item);

    const conditionalProps = {
        indexer: qParams.indexer,
        getInitialAnswer: getInitialAnswer
    };

    return <FormGroup row>
        <Box mt={1} mr={2} display="flex" flexDirection="row-reverse">
            {!isFirst && <Button
                type="button"
                size="small"
                startIcon={<DeleteIcon />}
                disabled={isFirst}
                onClick={() => { remove(item) }}>
                Remove
            </Button>}
            {isFirst && <Button
                type="button"
                size="small"
                startIcon={<AddIcon />}
                disabled={!canAdd}
                onClick={add}
                style={{
                    paddingLeft: 28
                }}
            >
                Add
            </Button>}
        </Box>
        <Box style={{
            marginTop: 20
        }}
        //className={classes.lablelessTopBuffer}
        >
            <Question qId="SubDivisionType" {...qParams} inline txtWidth={25} hideLabel />
        </Box>
        <Question qId="LotNumber" {...qParams} inline txtWidth={25} />
        <Question qId="Plan" {...qParams} inline txtWidth={25} />
        <Conditional upon="SubDivisionType" when={(x) => StringUtil.isEither(x, "PartOfLot", "PartOfBlock")} {...conditionalProps}>
            <Question qId="SubSubDivision" {...qParams} short inline txtWidth={25} />
            <Question qId="ReferencePlan" {...qParams} short inline txtWidth={25} />
        </Conditional>
    </FormGroup>;
}

export default NHPFreeholdLegalDescriptionInfo;