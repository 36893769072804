import React, { useState, useMemo} from 'react';
import { Typography, Tooltip } from '@mui/material';
import { alpha } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from '@mui/material/CircularProgress';
import { grey, deepPurple, purple, brown } from '@mui/material/colors';
import DeleteIcon from '@mui/icons-material/Delete';
import { StringUtil, UserHelper } from 'Util/Helpers';
import { DateFormatUtil } from 'Util/DateFormatUtil';
import './Note.css';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useSelector } from 'react-redux';
import * as UserPermissions from 'Model/UserPermissions';
import * as UserActions from 'User/State/UserActions';
import * as UserRoles from 'Model/UserRoles';

const useStyles = makeStyles((theme) => ({
    centerLoaderMini: {
        position: 'absolute',
        top: '40%',
        left: '40%'
    },
    loaderBackdrop: {
        position: 'absolute',
        width: '100%',
        background: alpha(grey[50], 0.5),
        zIndex: 100,
        top: 0,
        left: 0,
        height: '100%'
    },
    title: {
        width: "100%",
        color: "blue",
        display: "inline-block"
    },
    resolvedMini: {
        color: "green",
        fontSize: "1em"
    },
    externalNote: {
        background: brown[50],
        '&:after': {
            borderBottom: `solid 8px ${brown[100]}`,
            borderLeft: `solid 8px ${brown[100]}`,
            borderRight: "solid 8px #fafafa",
            borderTop: "solid 8px #fafafa",
        }
    }
}));

function NotePreview(props) {

    const {
        note,
        onDeleteAsync,
        onNoteClick
    } = props;

    const classes = useStyles();
    const [deleting, setDeleting] = useState(false);

    const isExternal = StringUtil.isEqual(note.Visibility, "External");

    const selectUserInfo = useMemo(
        UserActions.createUserInfoSelector,
        []
    );
    
    const userInfo =  useSelector(x => selectUserInfo(x));

    const canDeleteNote = UserHelper.hasPermission(userInfo, UserPermissions.delete_note);

    if (StringUtil.isNullOrEmpty(note.SecureId))
        return null;

    async function handleDelete(event) {
        if(!canDeleteNote) {
            return;
        }
        
        setDeleting(true);

        onDeleteAsync(note.SecureId).then(resp => {
            setDeleting(false);
        });
    }

    return (
        <div className={`sticky ${isExternal ? classes.externalNote : ""}`}>
            <div className="watermarkContainer">
                <div className="container">
                    <div className="headerWrapper">
                        {StringUtil.isNullOrEmpty(note.UpdatedByName) ? note.CreatedByName : note.UpdatedByName}
                    </div>
                    <div className="preview" onClick={(event) => onNoteClick(note.SecureId)}>

                        <Typography className={classes.title}>
                            {note.Title}
                        &nbsp;
                        {note.Resolved && <CheckCircleIcon className={classes.resolvedMini} />}
                        </Typography>
                        <span>{note.Value}</span>
                    </div>
                    {deleting &&
                        <div className={classes.loaderBackdrop}>
                            <CircularProgress size={35} className={classes.centerLoaderMini} />
                        </div>
                    }
                    <div className="footerWrapper">
                        {DateFormatUtil.relativeToNowCal(StringUtil.isNullOrEmpty(note.UpdatedOn) ? note.CreatedOn : note.UpdatedOn)}
                        
                     {canDeleteNote &&   
                        <Tooltip title="Delete">
                            <DeleteIcon onClick={handleDelete} />
                        </Tooltip>
                        }
                    </div>
                </div>
            </div>
        </div >
    );
}

export default NotePreview;
