import { Box, Button } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { isEmpty } from 'lodash';
import { PermittedOrderActionsEnum } from 'Model/PolicyOrder';
import PolicyViewHelper from 'Policy/PolicyViewHelper';
import * as DocumentThunks from 'Policy/State/DocumentThunks';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { StringUtil } from 'Util/Helpers';

function createOrderVolatileDataSelector() {
    return createSelector(
        state => state.order.orderVolatileData,
        (_, propName) => propName,
        (x, propName) => x[propName]
    );
}

function createGeneratingPDFSelector() {
    return createSelector(
        state => state.order.generatingPDF,
        x => x
    );
}

function InvoiceButton(props) {
    const { dispatch, po, orderSecureId } = props;

    const selectGeneratingPDF = useMemo(
        createGeneratingPDFSelector,
        []
    );

    const generatingPDF = useSelector(x => selectGeneratingPDF(x));

    const dealNumber = po.DealNumber;

    const handleViewPolicyPDF = (event) => {
        dispatch(DocumentThunks.generateInvoice(orderSecureId, dealNumber));
    };

    const isBusy = !isEmpty(generatingPDF);

    let label = "View/Print Invoice";

    if (isBusy)
        label = "Generating...";

    return <Button
        size="small"
        type="button"
        variant="contained"
        color="primary"
        startIcon={<PictureAsPdfIcon />}
        onClick={handleViewPolicyPDF}
        disabled={isBusy}
        fullWidth
    >
        {label}
    </Button>;
}

function InvoiceSection(props) {
    const { po } = props;

    const selectOrderVolatileData = useMemo(
        createOrderVolatileDataSelector,
        []
    );

    const dispatch = useDispatch();
    const viewHelper = new PolicyViewHelper(po, dispatch);
    const orderSecureId = viewHelper.getDealIdFromQueryString();

    const permittedActions = useSelector(x => selectOrderVolatileData(x, "PermittedActions"));

    const allowPrintDocs = StringUtil.contains(permittedActions, PermittedOrderActionsEnum.GenerateInvoice);

    if (!allowPrintDocs) {
        return null; // do not show this section unless it's appropriate
    }

    return (<Box mt={1} mb={2}>
        <InvoiceButton dispatch={dispatch} po={po} orderSecureId={orderSecureId} />
    </Box>);
}

export default InvoiceSection;