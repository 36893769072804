import { createAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import StringUtil from 'Util/StringUtil';

export const set_pin_list = createAction('admin/set_pin_list');
export const set_pin_list_loading = createAction('admin/set_pin_list_loading');
export const set_pin_list_loading_error = createAction('admin/set_pin_list_loading_error');
export const update_pin_change_busy = createAction('admin/set_pin_change_busy');
export const clear_pin_change_busy = createAction('admin/clear_pin_change_busy');
export const create_pin = createAction('admin/create_pin');
export const set_pin = createAction('admin/set_pin');
export const update_pin = createAction('admin/update_pin');
export const delete_pin = createAction('admin/delete_pin');
export const toggle_pin_status = createAction('admin/toggle_pin_status');
export const set_pin_loading_error = createAction('admin/set_pin_loading_error');
export const set_pin_handle_change = createAction('admin/set_pin_handle_change');

export function createPinListSelector() {
    return createSelector(
        state => state.pinDirectory.pin_list,
        x => x
    );
}

export function createPinListLoadingSelector() {
    return createSelector(
        state => state.pinDirectory.pin_list_loading,
        x => x
    );
}

export function createPinListLoadingErrorSelector() {
    return createSelector(
        state => state.pinDirectory.pin_list_loading_error,
        x => x
    );
}

export function createPinListTotalRecordsSelector() {
    return createSelector(
        state => state.pinDirectory.pin_list_total_records,
        x => x
    );
}

export function createPinListTotalPagesSelector() {
    return createSelector(
        state => state.pinDirectory.pin_list_total_pages,
        x => x
    );
}

export function createPinChangeBusySelector() {
    return createSelector(
        state => state.pinDirectory.pin_change_busy,
        x => x
    );
}

export function createPinSelector() {
    return createSelector(
        state => state.pinDirectory.pin,
        x => x
    );
}

export function updatePinSelector() {
    return createSelector(
        state => state.pinDirectory.pin,
        x => x
    );
}

export function togglePinStatusSelector() {
    return createSelector(
        state => state.pinDirectory.pin,
        x => x
    );
}

export function createPinLoadingErrorSelector() {
    return createSelector(
        state => state.pinDirectory.pin_loading_error,
        x => x
    );
}

export function changePinInputSelector() {
    return createSelector(
        state => state.pinDirectory.pin,
        x => x
    );
}
