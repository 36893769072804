import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SectionHelper } from 'Util/SectionHelper';
import * as ProjectActions from '../State/ProjectActions';
import PropertyInfo from './PropertyInfo';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(1)
    }
}));

function Properties(props) {
    const classes = useStyles();

    const { questionParams, getInitialAnswer } = props;

    const collectionName = "PropertyLegalDescription";

    const itemsList = useSelector(x => x.project.propertyLegalDescription);

    const dispatch = useDispatch();

    // first load
    useEffect(() => {
        let isCancelled = false;

        function onUnload() {
            isCancelled = true;
        }

        if (!itemsList || itemsList.length === 0) {
            add();
        }

        return onUnload;
    }, []);

    if (!itemsList)
        return null;

    const canAdd = itemsList.length < 20;


    function add() {
        const currCount = itemsList ? itemsList.length : 0;

        const newItem = SectionHelper.addNewSection(collectionName, questionParams);

        newItem.ItemNumber = currCount + 1;

        dispatch({ type: ProjectActions.addProperty.type, payload: newItem });
    }

    function remove(item) {
        SectionHelper.removeSection({ collectionName, item, questionParams });

        dispatch({ type: ProjectActions.removeProperty.type, payload: item });
    }

    return (
        <React.Fragment>
            <div className={classes.root}>
                {itemsList.map((item, index) => {
                    return (
                        <PropertyInfo
                            // key={item.Id}
                            key={index}
                            idx={index}
                            totalCount={itemsList.length}
                            collectionName={collectionName}
                            item={item}
                            canAdd={canAdd}
                            add={add}
                            remove={remove}
                            questionParams={questionParams}
                            getInitialAnswer={getInitialAnswer} />
                    );
                })}
            </div>
        </React.Fragment>
    );
}

export default Properties;