import { Box, FormControl } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import StringUtil from 'Util/StringUtil';
import React from 'react';
import './TermsAndConditions.css';
import TandCLawyer from './TandCLawyer';
import TandCClerk from './TandCClerk';

const useStyles = makeStyles(theme => ({
    tcContainer: {
        width: '100%',
        maxHeight: '80ch',
        overflow: 'auto',
        padding: theme.spacing(3)
    }
}));

function TermsAndConditions(props) {
    const { qId, userRole } = props;

    const classes = useStyles();

    if (StringUtil.isEqual(userRole, "Clerk")) {
        return (
            <Box border={1} borderColor="grey.500">
                <FormControl name={qId} area-label={qId} className={classes.tcContainer}>
                    <TandCClerk />
                </FormControl>
            </Box>
        );
    }
    else if (StringUtil.isEqual(userRole, "Lawyer")) {
        return (
            <Box border={1} borderColor="grey.500">
                <FormControl name={qId} area-label={qId} className={classes.tcContainer}>
                    <TandCLawyer />
                </FormControl>
            </Box>
        );
    }

    return null;
}

export default TermsAndConditions;
