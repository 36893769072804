import { Box, TableCell, TableRow, Typography } from '@mui/material';
import { Question } from 'Components/Questionary';
import { CollectionTypeEnum } from 'Model/PolicyOrder';
import React, { Fragment } from "react";
import PurchaserInfo from './PurchaserInfo';

function Purchasers(props) {
    const { itemsList, questionParams, propertyConditionalProps } = props;

    if (!itemsList || itemsList.length === 0)
        return null;

    const collectionName = CollectionTypeEnum.Purchasers;

    const item = itemsList[0];

    return (
        <TableRow>
            <TableCell>
                <Typography>
                    Owner Policy {item.PolicyNumber}
                </Typography>
            </TableCell>
            <TableCell>
                <Box style={{ display: 'inline-flex' }}>
                    {itemsList.map(i =>
                        <Fragment key={i.Id}>
                            <PurchaserInfo
                                collectionName={collectionName}
                                item={i}
                                questionParams={questionParams}
                                propertyConditionalProps={propertyConditionalProps} />
                        </Fragment>)
                    }
                </Box>
            </TableCell>
            <TableCell>
                <Box style={{ width:90, textAlign:'right' }}>
                    <Question qId="PurchasePrice" {...propertyConditionalProps} displayOnly hideLabel />
                    <Question qId="PurchasePriceInclHST" {...propertyConditionalProps} displayOnly hideLabel />
                    <Question qId="PropertyValue" {...propertyConditionalProps} displayOnly hideLabel />
                </Box>
            </TableCell>
        </TableRow>);

    // return itemsList.map(item =>
    //     <TableRow key={item.Id}>
    //         <PurchaserInfo
    //             collectionName={collectionName}
    //             item={item}
    //             questionParams={questionParams}
    //             propertyConditionalProps={propertyConditionalProps} />
    //     </TableRow>
    // );
}

export default Purchasers;
