import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AdminApiClient } from 'Admin/AdminApiClient'

const initialState = {
    announcements: [],
    status: 'idle',
    error: null,
    announcements_count: 0,
    announcements_pages_count: 0,
  }

// Thunk functions
export const fetchAnnouncements = createAsyncThunk(
    'announcements/fetch',
    async (req) => {
        const response = await AdminApiClient.getAnnouncements(req)
        // console.info(`[response] - ${JSON.stringify(response)}`);
        return response.data
    })

export const addNewAnnouncement = createAsyncThunk(
    'announcements/addNew',
    async (newAnnouncement) => {
        const response = await AdminApiClient.createAnnouncement(newAnnouncement)
        return response.data
    })

export const updateAnnouncement = createAsyncThunk(
    'announcements/update',
    async (editedAnnouncement) => {
        const response = await AdminApiClient.editAnnouncement(editedAnnouncement);
        return response.data;
    })

export const deleteAnnouncement = createAsyncThunk(
    'announcements/delete',
    async (deletedAnnouncement) => {
        const response = await AdminApiClient.deleteAnnouncement(deletedAnnouncement);
        return response.data;
})

const announcementsSlice = createSlice({
    name: 'announcements',
    initialState: initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchAnnouncements.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchAnnouncements.fulfilled, (state, action) => {
                // console.info(`[fetchAnnouncements-payload] - ${JSON.stringify(action.payload)}`);
                // console.info(`[fetchAnnouncements-state.announcements] - ${JSON.stringify(state.announcements)}`);
                // console.info(`[fetchAnnouncements-action.payload.Announcements] - ${JSON.stringify(action.payload.Announcements)}`);
                if (state.announcements === undefined ||
                    state.announcements === null ||
                    state.announcements.length === 0) {
                        state.announcements = []
                }
                if (action.payload.Announcements === undefined ||
                    action.payload.Announcements === null ||
                    action.payload.Announcements.length === 0) {
                    action.payload.Announcements = []
                }
                if (action.payload.Announcements.length > 0) {
                    // state.announcements = state.announcements.concat(action.payload.Announcements)
                    state.announcements = action.payload.Announcements
                }
                state.announcements_count = action.payload.TotalRecords ?? 0
                state.announcements_pages_count = action.payload.NumPages ?? 1
                state.status = 'succeeded'
            })
            .addCase(fetchAnnouncements.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(addNewAnnouncement.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(addNewAnnouncement.fulfilled, (state, action) => {
                // console.info(`[addNewAnnouncement-state.announcements] - ${JSON.stringify(state.announcements)}`);
                // console.info(`[addNewAnnouncement-action.payload] - ${JSON.stringify(action.payload)}`);
                state.announcements = state.announcements.push(action.payload)
                state.status = 'idle'
            })
            .addCase(updateAnnouncement.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(updateAnnouncement.fulfilled, (state, action) => {
                state.announcements = state.announcements.map(announcement =>
                    announcement.id === action.payload.id ? action.payload : announcement
                )
                state.status = 'idle'
            })
            .addCase(deleteAnnouncement.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(deleteAnnouncement.fulfilled, (state, action) => {
                state.announcements = state.announcements.filter(announcement => announcement.id !== action.payload.id)
                state.status = 'idle'
            })
    }
});

export default announcementsSlice.reducer

// Selectors
export const selectAnnouncements = (state) => state.announcements.announcements
export const selectAnnouncementsCount = (state) => state.announcements.announcements_count
export const selectAnnouncementById = (state, announcementId) => state.announcements.announcements
    .find(announcement => announcement.Id === announcementId)
