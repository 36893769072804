import { Badge, Icon } from '@mui/material';
import { green, grey, purple, red } from '@mui/material/colors';
import withStyles from '@mui/styles/withStyles';
import { Gavel } from '@mui/icons-material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import clsx from 'clsx';
import * as OrderStatusEnum from 'Model/OrderStatus';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from "react-i18next";
import { StringUtil } from 'Util/Helpers';

export const styles = (theme) => {
    const s = {
        root: {
        },
        iconRoot: {
            marginLeft: theme.spacing(0.5)
        },
        imageIcon: {
            height: '100%'
        },
    };

    s[OrderStatusEnum.Issued] =
    {
        color: green[800],
        display: 'flex',
        alignItems: 'center'
    };

    s[OrderStatusEnum.UnderReview] =
    {
        display: 'flex',
        alignItems: 'center'
    };

    s[OrderStatusEnum.Declined] = {
        color: red[700]
    };

    s[OrderStatusEnum.Cancelled] = {
        color: red[700],
        display: 'flex',
        alignItems: 'center'
    };

    s[OrderStatusEnum.Deleted] = {
        color: grey[700],
        display: 'flex',
        alignItems: 'center'
    };

    return s;
};

function OrderStatus(props) {
    const { t } = useTranslation();

    const { status, classes, className } = props;

    let statusText = t(status);

    const textClassName = clsx(classes.root, classes[status], className);

    if (StringUtil.isEqual(status, OrderStatusEnum.UserActionRequired)) {
        return (
            <Badge color="error" variant="dot">
                <span className={textClassName}>{statusText}&nbsp;&nbsp;</span>
            </Badge>
        );
    }
    else if (StringUtil.isEqual(status, OrderStatusEnum.UnderReview)) {
        return (
            <span className={textClassName}>
                {statusText}
                <Icon classes={{ root: classes.iconRoot }}>
                    <img className={classes.imageIcon} src="/img/under_review.svg" alt="under review" />
                </Icon>
            </span>
        );
    }
    else if (StringUtil.isEqual(status, OrderStatusEnum.Issued)) {
        return (
            <span className={textClassName}>
                {statusText}
                <CheckCircleIcon className={classes.iconRoot} />
            </span>
        );
    }

    else if (StringUtil.isEqual(status, OrderStatusEnum)) {
        return (
            <span className={textClassName}>
                {statusText}
                <CancelOutlinedIcon className={classes.iconRoot} />
            </span>
        );
    }

    else if (StringUtil.isEqual(status, OrderStatusEnum.Deleted)) {
        return (
            <span className={textClassName}>
                {statusText}
                <DeleteIcon className={classes.iconRoot} />
            </span>
        );
    }

    return (
        <span className={textClassName}>{statusText}</span>
    );
}

OrderStatus.propTypes = {
    status: PropTypes.string
};

export default withStyles(styles)(OrderStatus);