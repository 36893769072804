import { Box } from '@mui/material';
import { Question } from 'Components/Questionary';
import { CollectionTypeEnum } from 'Model/PolicyOrder';
import * as PolicyActions from 'Policy/State/PolicyActions';
import React from 'react';
import { useDispatch } from 'react-redux';
import { QuestionaryHelper } from 'Util/QuestionaryHelper';
import { SectionHelper } from '../../Util/SectionHelper';
import NHPFreeholdLegalDescriptionInfo from './NHPFreeholdLegalDescriptionInfo';

function NHPFreeholdLegalDescriptionSection(props) {
    const { questionParams, itemsList, property, getInitialAnswer } = props;

    const dispatch = useDispatch();

    const collectionName = CollectionTypeEnum.NHPFreeholdLegalDescriptions;

    let qParams = { ...questionParams };

    qParams.indexer = QuestionaryHelper.createIndexer(CollectionTypeEnum.Properties, property);

    const canAdd = itemsList.length < 12;

    function add() {
        const newItem = SectionHelper.addNewSection(collectionName, questionParams, { NHPFreeholdLegalDescriptionPropertyId: property.Id });

        dispatch({ type: PolicyActions.add_nph_freehold_legal_description.type, payload: newItem });
    }

    function remove(item) {
        SectionHelper.removeSection({ collectionName, item, questionParams });

        dispatch({ type: PolicyActions.remove_nph_freehold_legal_description.type, payload: item });
    }

    return <Question qId="NHPFreeholdLegalDescription" {...qParams} optional>
        {itemsList.map((item, index) => {
            return (
                <NHPFreeholdLegalDescriptionInfo
                    key={item.Id}
                    idx={index}
                    collectionName={collectionName}
                    item={item}
                    canAdd={canAdd}
                    add={add}
                    remove={remove}
                    questionParams={questionParams}
                    getInitialAnswer={getInitialAnswer} />
            );
        })}
        <Box mb={2} />
    </Question>;
}

export default NHPFreeholdLegalDescriptionSection;