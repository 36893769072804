const PermittedOrderActionsEnum = {
    Save: "Save",
    RevertToUnsubmitted: "RevertToUnsubmitted",
    SubmitOrder: "SubmitOrder",
    Approve: "Approve",
    MarkForReview: "MarkForReview",
    RequestUserAction: "RequestUserAction",
    Decline: "Decline",
    Cancel: "Cancel",
    Delete: "Delete",
    Restore: "Restore",
    GenerateDocs: "GenerateDocs",
    GenerateInvoice: "GenerateInvoice",
    ChangeStatus: "ChangeStatus",
    TakeNotes: "TakeNotes",
    ViewExceptions:"ViewExceptions",
    ApplyExceptions: "ApplyExceptions",
    ViewEndorsements:"ViewEndorsements",
    ApplyEndorsements: "ApplyEndorsements",
    ViewInsureOvers: "ViewInsureOvers",
    ApplyInsureOvers: "ApplyInsureOvers"
};

const ExceptionTypeEnum = {
    Manual: "Manual",
    Automatic: "Automatic"
}

const ApplicabilityTypeEnum = {
    Manual: "Manual",
    Automatic: "Automatic"
}

const EndorsementTypeEnum = {
    Standard: "Standard",
    Custom: "Custom"
}

const PolicyTypeEnum = {
    OwnerPolicyOnly: "owner_policy_only",
    LenderPolicyOnly: "lender_policy_only",
    OwnerAndLenderPolicy: "owner_and_lender"
}

const TagsEnum = {
    MngmntReview: "MngmntReview",
    ClaimsMatch: "ClaimsMatch",
    SuppDbMatch: "SuppDbMatch",
    PostClosing: "PostClosing",
    Commercial: "Commercial",
    Leasehold: "Leasehold",
    Farm: "Farm",
    PinDB: "PinDB",
    SuppDB: "SuppDB",
    PoA: "PoA",
    FC001: "FC001",
    FC005: "FC005",
    FC012: "FC012",
    FC013: "FC013",
    FC015: "FC015",
    FC014: "FC014",
    FC010: "FC010",
    FC016: "FC016",
    FC011: "FC011",
    FC007: "FC007",
    FC008: "FC008",
    MtgRecipient: "MtgRecipient", 
    PptyFraud: "PptyFraud",
    IdentityFraud: "IdentityFraud", 
    Registry: "Registry",
    CourtOrder: "CourtOrder",
    CertPendLit: "CertPendLit",
    Lien: "Lien",
    Survey: "Survey",
    Tax_Water: "Tax_Water",
    Executions: "Executions",
    BDWO: "BDWO",
    Zoning: "Zoning",
    StatCert: "StatCert",
    Request: "Request",
    EO_Value: "EO Value",
    NCS_Mtg: "NCS Mtg",
    Claims: "Claims",
    Lender: "Lender",
    DeclinedDeal: "DeclinedDeal",
    PrivateMtgOver500K: "PrivateMtgOver500K",
    BlanketMtg: "BlanketMtg",
    ACQDate: "ACQDate",
    IDV: "IDV",
    IDVWithBanking: "IDVWithBanking",
    IDVWaived: "IDVWaived",
    IDVUnderLimit: "IDVUnderLimit",
    IDVPending: "IDVPending",
    PrivateMtgOver1Mill: "PrivateMtgOver1Mill",
};

const SourceEnum = {
    DD: "DD", //Dye & Durham
    SelfServe: "SelfServe",
    DialIn: "DialIn",
    LDD: "LDD",
    LawLabs: "LawLabs"
}

const RegistrationStatusEnum = {
    NotRegistered: "NotRegistered",
    Registered: "Registered",
    InQueue: "InQueue",
    RegistrationFailed: "RegistrationFailed",
    Cancelled: "Cancelled",
    RegistrationAttemptsExhausted: "RegistrationAttemptsExhausted",
    RegistrationProhibited: "RegistrationProhibited",
    RegistrationBug: "RegistrationBug",
    Synced: "Synced",
    Syncing: "Syncing",
    OutOfSync: "OutOfSync"
}

const TransactionTypeEnum = {
    Resale: "Resale",
    New: "New",
    MortgageOnly: "MortgageOnly",
    ExistingOwner: "ExistingOwner"
}

const PropertyTypeEnum = {
    Freehold: "Freehold",
    Condominium: "Condominium",
    SingleFamily: "SingleFamily",
    MultiUnit: "MultiUnit",
    VacantLand: "VacantLand",
    VacantLandCondo: "VacantLandCondo",
    ParcelOfTiedLand: "ParcelOfTiedLand",
    Farm: "Farm",
    Leasehold: "Leasehold",
    Commercial: "Commercial"
}

const CollectionTypeEnum = {
    Properties: "Properties",
    Mortgages: "Mortgages",
    Purchasers: "Purchasers",
    RemainingMortgages: "RemainingMortgages",
    NHPFreeholdLegalDescriptions: "NHPFreeholdLegalDescriptions",
    NHPCondoLegalDescriptions: "NHPCondoLegalDescriptions"
}

const MortgageTypeEnum = {
    Private: "Private",
    Institutional: "Institutional",
    VendorTakeBack: "VendorTakeBack"
}

const ProvincesDDList = [
    { Id: "ON", Label: "Ontario" },
    { Id: "AB", Label: "Alberta" },
    { Id: "MB", Label: "Manitoba" },
    { Id: "PE", Label: "Prince Edward Island" },
];

export {
    PermittedOrderActionsEnum,
    TagsEnum,
    SourceEnum,
    ExceptionTypeEnum,
    ApplicabilityTypeEnum,
    EndorsementTypeEnum,
    PolicyTypeEnum,
    RegistrationStatusEnum,
    TransactionTypeEnum,
    PropertyTypeEnum,
    CollectionTypeEnum,
    MortgageTypeEnum,
    ProvincesDDList
};
