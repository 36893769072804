import React, { Fragment, useMemo } from 'react';
import { Question } from 'Components/Questionary';
import { QuestionaryHelper } from 'Util/QuestionaryHelper';
import { StringUtil } from 'Util/Helpers';
import { Typography, Box, IconButton, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import makeStyles from '@mui/styles/makeStyles';
import Conditional from 'Policy/Conditional';
import Fact from 'Components/Fact';
import { MortgageTypeEnum, PropertyTypeEnum, TransactionTypeEnum } from 'Model/PolicyOrder';
import { grey } from '@mui/material/colors';

const useStyles = makeStyles((theme) => ({
    header: {
        backgroundColor: grey[200]
    }
}));

function MortgageInfo(props) {
    const { item, idx, questionParams, canAdd, add, remove, collectionName, propertyConditionalProps } = props;

    const classes = useStyles();

    const isFirst = idx === 0;

    questionParams.indexer = QuestionaryHelper.createIndexer(collectionName, item);

    const conditionalProps = {
        indexer: questionParams.indexer,
        getInitialAnswer: propertyConditionalProps.getInitialAnswer
    };

    const privateMortgage1MillionKMessage = 'Private mortgages over $1 million (Ontario) or $500,000 (outside Ontario) will not be insured unless the lender is approved, or a parent is lending to their child. To inquire if a private lender is approved, please contact us. For projects enrolled with the New Home Program, TitlePLUS will insure private mortgages up to $5 million.';
    const privateMortgage500KMessage = 'Private mortgages over $500,000 will not be insured unless the lender is approved, or a parent is lending to their child. To inquire if a private lender is approved, please contact us.';
    const privateMortgageMessage = 'A private lender is one that is not a “lending client” within the meaning of Rule 3.4-13 of the Law Society of Ontario Rules of Professional Conduct. A mortgage investment corporation is not a “lending client” unless it satisfies the criteria in Rule 3.4-13.     Lenders in mortgages registered to a bank, trust company or credit union as trustee for an individual’s registered plan will be considered “lending clients”.';
    const year = new Date().getFullYear();
    

    return (
        <Fragment>
            <Box p={1} display="flex" className={classes.header}>
                <Box flexGrow={1}>
                    <Typography>Mortgage {idx + 1}</Typography>
                </Box>
                <Box mr={1}>
                    <Tooltip title="Add Mortgage">
                        <span>
                            <IconButton
                                aria-label="add"
                                size="small"
                                disabled={!canAdd}
                                onClick={add}
                            >
                                <AddIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                </Box>
                <Box>
                    <Tooltip title="Remove">
                        <span>
                            <IconButton
                                aria-label="remove"
                                size="small"
                                disabled={isFirst}
                                onClick={() => { remove(item) }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                </Box>
            </Box>
            <Box p={2} className={classes.content}>
                {/* <Question qId="MortgageTPInsured" {...questionParams} /> */}

                <Conditional upon="ShouldShowMortgageDetails" when={(x) => x == true} {...conditionalProps}>
                    <Question qId="MortgageAdvanceDate" {...questionParams} />
                    {/* <Question qId="BlanketMortgage" {...questionParams} /> */}
                    <Question qId="MortgageType" {...questionParams} />
                    <Conditional upon="MortgageType" when={(x) => StringUtil.isEqual(x, MortgageTypeEnum.Private)} {...conditionalProps}>
                        <Fact info>

                            <Conditional upon="PropertyType" when={(x) => StringUtil.isEqual(x, PropertyTypeEnum.Farm) || StringUtil.isEqual(x, PropertyTypeEnum.Commercial)} {...propertyConditionalProps}>
                                {privateMortgageMessage}
                            </Conditional>

                            <Conditional upon="PropertyType" when={(x) => !StringUtil.isEqual(x, PropertyTypeEnum.Farm) && !StringUtil.isEqual(x, PropertyTypeEnum.Commercial)} {...propertyConditionalProps}>
                                <Conditional upon="TransactionType" when={(x) => x == TransactionTypeEnum.New} {...propertyConditionalProps}>
                                    <Conditional upon="IsEnrolledInNHP" when={(x) => StringUtil.isYes(x)} {...propertyConditionalProps}>
                                        {privateMortgageMessage}
                                    </Conditional>
                                    <Conditional upon="IsEnrolledInNHP" when={(x) => !StringUtil.isYes(x)} {...propertyConditionalProps}>
                                        { year > 2023 ? privateMortgage1MillionKMessage : privateMortgage500KMessage }
                                    </Conditional>
                                </Conditional>
                                <Conditional upon="TransactionType" when={(x) => x != TransactionTypeEnum.New} {...propertyConditionalProps}>
                                    { year > 2023 ? privateMortgage1MillionKMessage : privateMortgage500KMessage }
                                </Conditional>
                            </Conditional>

                        </Fact>
                    </Conditional>
                    <Question qId="MortgageLenderName" allowFreeText {...questionParams} short />
                    <Question qId="MortgageReferenceNumber" {...questionParams} short />
                    <Question qId="MortgagePrincipalAmount" {...questionParams} />

                    {/* Uncomment once blanket mortgage is introduced */}
                    {/* <Conditional upon="BlanketMortgage" when={(x) => StringUtil.isYes(x)} {...conditionalProps}>
                        <Question qId="PriorityOfBlanketMortgage" {...questionParams} />
                    </Conditional> */}
                    {/* <Conditional upon="BlanketMortgage" when={(x) => StringUtil.isNo(x)} {...conditionalProps}> */}
                    <Question qId="PriorityUponClosing" {...questionParams} />
                    {/* </Conditional> */}

                    <Conditional upon="MortgageType" when={(x) => StringUtil.isEqual(x, MortgageTypeEnum.Private)} {...conditionalProps}>
                        <Question qId="RequestTPExtendedSuperPriorityLienEndorsement" {...questionParams} />
                    </Conditional>
                    {!isFirst && <Question qId="ActingForLender" {...questionParams} />}
                </Conditional>
            </Box>
        </Fragment>
    );
}

export default MortgageInfo;