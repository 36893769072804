import React from 'react';
import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { StringUtil } from 'Util/Helpers';

const useStyles = makeStyles((theme) => ({
    container: {
        display: "inline-block"
    },
    chip: {
        fontSize: "80%",
        fontWeight: 500,
    }
}));

function BoxedChip(props) {
    const classes = useStyles();
    const { color, label, style, className, children } = props;

    if (StringUtil.isNullEmptyOrWhiteSpace(label) && !children)
        return null;

    return (
        <Box ml={0.5} pl={0.5} pr={0.5} border={1} borderColor={color} borderRadius="5px" className={`${classes.container} ${className}`} style={style}>
            <Typography
                variant="caption"
                className={classes.chip}
                style={{ color: color }}
                component="span">
                {label}
                {children}
            </Typography>
        </Box>
    );
}

export default BoxedChip;