import React, { useEffect } from 'react';
import { Container, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Copyright from 'Components/Copyright';
import ActionMsgPopup from 'AppEnvelope/ActionMsgPopup';
import * as AppActions from 'State/AppActions';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
  logo: {
    marginBottom: theme.spacing(1.5),
    width: '40%'
  },
  paper: {
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper
  }
}));

function RegistrationContainer(props) {
  const { children } = props;

  const classes = useStyles();

  const dispatch = useDispatch();

  useEffect(() => {
    let isCancelled = false;

    function onUnload() {
      isCancelled = true;
    }

    dispatch({ type: AppActions.clearActionMessage.type });

    return onUnload;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container component="main" maxWidth="md">
      <div className={classes.paper}>
        <img src="/img/tp_logo.svg" className={classes.logo}></img>
        {children}
      </div>
      <Box mt={8} mb={3}>
        <Copyright />
      </Box>
      <ActionMsgPopup />
    </Container>
  );
}

export default RegistrationContainer;