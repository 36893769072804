import { Box, Button, TextField, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';
import BoxedChip from 'Components/BoxedChip';
import { PolicyTypeEnum } from 'Model/PolicyOrder';
import React from 'react';
import { StringUtil } from 'Util/Helpers';

import { green, grey, purple, teal, yellow } from '@mui/material/colors';

const useStyles = makeStyles(() => ({
    title: {
        fontWeight: 500
    },
    multilineInput: {
        minHeight: 30,
        color: "black",
    },
    fullWidth: {
        width: "100%"
    },
    floatLeft: {
        float: "left"
    },
    floatRight: {
        float: "right"
    }
}));

function InsureOver(props) {

    const classes = useStyles();

    const { index, item, isError, getErrMsg, onDescriptionChange, onDelete, canChange, controlRef } = props;

    //#region Props and HTML
    const itemBoxProps = option => {
        return {
            ml: 1.5,
            mr: 1.5,
            mb: 1.5,
            pt: 1,
            pb: 0.5,
            pl: 1.5,
            pr: 1.5,
            border: 1,
            borderColor: option.IsError ? "error.main" : option.AlreadyApplied ? green[500] : grey[400],
            borderRadius: 5,
            style: { overflow: "hidden", backgroundColor: option.AlreadyApplied ? green[50] : "inherit" }
        };
    };

    const policyNumberProps = option => {
        return {
            color: purple[500],
            label: option.AppliedTo
        };
    };

    const policyTypeChipProps = option => {
        const l = StringUtil.isEqual(option.PolicyType, PolicyTypeEnum.LenderPolicyOnly) ? "Lender Policy" : "Owner Policy";
        return {
            color: StringUtil.isEqual(option.PolicyType, PolicyTypeEnum.LenderPolicyOnly) ? teal[500] : yellow[900],
            label: `${l}`
        };
    };

    return <React.Fragment>
        <Box key={item.SecureId} {...itemBoxProps(item)}>
            <Typography className={`${classes.title}`} component="span">
                {/* Title */}
                {item.Topic}
            </Typography>
            <Typography className={`${classes.floatRight}`} component="span">
                {/* Owner / Lender Policy */}
                <BoxedChip {...policyTypeChipProps(item)} />
                {/* Policy Number */}
                <BoxedChip {...policyNumberProps(item)} />
            </Typography>
            {/* Insure over description */}
            <TextField
                fullWidth
                multiline
                defaultValue={item.Description}
                error={isError(index)}
                helperText={getErrMsg(index)}
                onChange={event => onDescriptionChange(event.target.value, item.SecureId)}
                InputProps={{
                    disableUnderline: true,
                    classes: {
                        inputMultiline: classes.multilineInput
                    },
                }}
                inputRef={controlRef}
                disabled={item.Deleting}
            />
            {/* Delete button */}
            {canChange &&
                <Button className={classes.floatRight} style={{ color: grey[700] }}
                    onClick={() => onDelete(item.SecureId)}
                    startIcon={item.Saving || item.Deleting ? <CircularProgress size={15} /> : <DeleteIcon />}
                    disabled={item.Saving || item.Deleting}>
                    {item.Saving ? "Saving" : item.Deleting ? "Deleting" : "Delete"}
                </Button>
            }
        </Box>
    </React.Fragment>;
    //#endregion Props and HTML 
}

export default InsureOver;