import { FormControl, FormHelperText, InputLabel } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { StringUtil } from 'Util/Helpers';
import AutoCompleteFreeForm from './AutoCompleteFreeForm';
import SyntheticEvent from './SyntheticEvent';

function AutoCompleteFreeFormQuestion(props) {
    const { q, name, fieldName, subQuestion, error, helperText, defaultValue, fieldStateResolver, onChange, allowFreeText, onSearchEventHandler, readOnly, disabled, ...passThroughProps } = props;

    const [opts, setOpts] = useState([]);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (props.opts) {
            setOpts(props.opts);
        }
    }, [props.opts]);

    function handleOnAutoCompleteChange(event, option) {
         

        if (readOnly) {
            return;
        }

        let value = null;

        if (option)
            value = option.Id;

        const ev = new SyntheticEvent(fieldName, value);

        onChange(ev);
    }

    async function searchEventHandler(value) {
        if (readOnly) {
            return;
        }

        const isSelectedFromOpts = opts.some(o => StringUtil.isEqual(o.Id, value));

        if (allowFreeText || isSelectedFromOpts) {
            const ev = new SyntheticEvent(fieldName, value);

            onChange(ev);
        }
        else {
            const ev = new SyntheticEvent(fieldName, null);

            onChange(ev);
        }

        if (onSearchEventHandler) {
            setOpen(true);

            const result = await onSearchEventHandler(value);

            setOpts(result);
        }
    }

    // this is an uncontrolled select
    return (
        <FormControl error={error}>
            {subQuestion &&
                <InputLabel id={name} required={!q.Optional}>{q.Text}</InputLabel>
            }
            <AutoCompleteFreeForm
                bound={true}
                readOnly={readOnly}
                multiSelect={false}
                openPopup={open}
                options={opts}
                optionLabelProp="Label"
                onChangeEventHandler={handleOnAutoCompleteChange}
                //onSearchEventHandler={handleSearch}                
                defaultValue={defaultValue}
                groupByProp="Label"
                onSearchEventHandler={searchEventHandler}
            />

            {!StringUtil.isNullOrEmpty(helperText) &&
                <FormHelperText style={{ marginBottom: '0.8rem' }}>{helperText}</FormHelperText>
            }
        </FormControl>
    );
}

export default AutoCompleteFreeFormQuestion;