import * as actions from './PrivateLendersActions';
import { createReducer } from '@reduxjs/toolkit';

const initialState = {
    private_lenders_list: [],
    private_lenders_list_loading: true,
    private_lenders_list_loading_error: null,
    private_lenders_list_total_records: 0,
    private_lenders_list_total_pages: 0,
    private_lender: { },
    private_lender_busy: { },
    private_lender_loading_error: null,
};

const PrivateLendersReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(actions.set_private_lender, (state, action) => {
            state.private_lender = action.payload;
        })
        .addCase(actions.set_private_lenders_list, (state, action) => {
            const withPagination = action.payload;

            const items = withPagination.Items ?? [];
            const totalRecords = withPagination.TotalRecords ?? 0;
            const numPages = withPagination.NumPages ?? 1;

            state.private_lenders_list = items;
            state.private_lenders_list_total_records = totalRecords;
            state.private_lenders_list_total_pages = numPages;
        })
        .addCase(actions.set_private_lenders_list_loading, (state, action) => {
            state.private_lenders_list_loading = action.payload;
        })
        .addCase(actions.set_private_lenders_list_loading_error, (state, action) => {
            state.private_lenders_list_loading_error = action.payload;
        })
        .addCase(actions.action_private_lender_busy, (state, action) => {
            state.private_lender_busy = action.payload;
        })
        .addCase(actions.create_private_lender, (state, action) => {
            state.private_lender = action.payload;
        })
        .addCase(actions.update_private_lender, (state, action) => {
            const { Id, Name, Type, Province, Issues, CreatedOn, CreatedBy, LastUpdatedBy } = action.payload;
            const privateLender = state.private_lender;

            if (!privateLender || privateLender.Id < 1) { return; }

            privateLender.Name = Name;
            privateLender.Type = Type;
            privateLender.Province = Province;
            privateLender.Issues = Issues;
            privateLender.CreatedBy = CreatedBy;
            privateLender.CreatedOn = CreatedOn;
            privateLender.LastUpdatedBy = LastUpdatedBy;
        })
        .addCase(actions.set_private_lender_handle_change, (state, action) => {
            const { fieldName, fieldValue } = action.payload;
            const currValue = state.private_lender[fieldName];
            if (currValue === fieldValue) { return; }
            state.private_lender[fieldName] = fieldValue;
        })
        .addCase(actions.set_private_lender_loading_error, (state, action) => {
            state.private_lender_loading_error = action.payload;
        })
        .addDefaultCase((state, action) => {
        })
});

export default PrivateLendersReducer;