import { createAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

export const set_user_info = createAction('user/set_user_info');
export const set_user_info_load_status = createAction('user/set_user_info_load_status');
export const update_user_status = createAction('user/update_user_status');
export const update_user_role = createAction('user/update_user_role');
export const update_user_profile_id = createAction('user/update_user_profile_id');
export const set_associations = createAction('user/set_associations');
export const add_user_link_invitation = createAction('user/add_user_link_invitation');
export const remove_user_link_invitation = createAction('user/remove_user_link_invitation');
export const set_user_associations_busy = createAction('user/set_user_associations_busy');
export const update_oms_object_id = createAction('user/update_oms_object_id');

export function createUserInfoSelector() {
    return createSelector(
        state => state.user.userInfo,
        x => x
    );
}

export function createUserInfoLoadStatusSelector() {
    return createSelector(
        state => state.user.userInfoLoadStatus,
        x => x
    );
}