import React, { useState, useRef } from 'react';
import { Button, Popper, Grow, Paper, ClickAwayListener, MenuList } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

function GroupedButton(props) {
    const { disabled, buttonText } = props;
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);

    return <React.Fragment>
        <Button
            color="primary"
            variant="contained"
            ref={anchorRef}
            onClick={event => setOpen(prevState => !prevState)}
            disabled={disabled}
            endIcon={<ArrowDropDownIcon />}>
            {buttonText}
        </Button>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition>
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
                    <Paper>
                        <ClickAwayListener onClickAway={event => setOpen(false)}>
                            <MenuList autoFocusItem={open}>
                                {props.children}
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    </React.Fragment>;
}

export default GroupedButton;