import React, { useMemo } from "react";
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from "@mui/icons-material/Close";
import {
  LinearProgress,
  Slide,
  Typography,
  IconButton,
  Toolbar,
  AppBar,
  Dialog,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import * as PolicyActions from "./State/PolicyActions";
import Logger from "Util/Logger";
import ErrorSnackbar from "Components/ErrorSnackbar";
import { isEmpty } from "lodash";

const logger = new Logger(ViewPolicyPDF.name);

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  content: {
    flexGrow: 1,
  },
  frame: {
    width: "100%",
    height: "100%",
    margin: 0,
    padding: 0,
    border: "none",
  },
  loader: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

function createViewGeneratedPDFSelector() {
  return createSelector(
    (state) => state.order.viewGeneratedPDF,
    (x) => x
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ViewPolicyPDF(props) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const selectViewGeneratedPDF = useMemo(createViewGeneratedPDFSelector, []);

  const viewGeneratedPDF = useSelector((x) => selectViewGeneratedPDF(x));

  if (isEmpty(viewGeneratedPDF)) {
    return null;
  }

  const handleClose = () => {
    window.URL.revokeObjectURL(viewGeneratedPDF.dataUrl); // DISPOSE

    dispatch({ type: PolicyActions.viewGeneratedPDF.type, payload: {} });
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={true}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
              size="large">
              <CloseIcon />
            </IconButton>
            <Typography variant="h5" className={classes.title}>
              {viewGeneratedPDF.label}
              <small>- {viewGeneratedPDF.policyNumber}</small>
            </Typography>
          </Toolbar>
        </AppBar>
        <div className={classes.content}>
          <object
            data={viewGeneratedPDF.dataUrl}
            type="application/pdf"
            width="100%"
            height="100%"
            aria-label="policy pdf view"
          >
            <a href={viewGeneratedPDF.dataUrl}>
              {viewGeneratedPDF.policyNumber}.pdf
            </a>
          </object>
        </div>
      </Dialog>
    </div>
  );
}
