import { FormControl, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import SyntheticEvent from 'Components/SyntheticEvent';
import withFieldStateBindings from 'Components/withFieldStateBindings';
import { withOnChangeDebounced } from 'Components/withOnChangeDebounced';
import React from 'react';
import MaskedInput from 'react-text-mask';

const useStyles = makeStyles(theme => ({
    shortTextField: {
        width: '24ch',
        paddingRight: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
}));

function PostalCodeMask(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            mask={[/[aA-zZ]/, /\d/, /[aA-zZ]/, ' ', /\d/, /[aA-zZ]/, /\d/]}
            placeholderChar={'\u2000'}
        />
    );
}

const TextFieldBound = withFieldStateBindings(withOnChangeDebounced(TextField));

function PostalCode(props) {
    const classes = useStyles();
    const { qId, indexer, textFieldProps, readOnly, other } = props;

    const postalCodeFieldName = `${indexer}${qId}`;
    const readOnlyPostalCodeFieldName = `${indexer}ShouldDisable${qId}`
    const postalCodeProps = { ...textFieldProps(postalCodeFieldName) };
    const defaultVal = textFieldProps(postalCodeFieldName).defaultValue;

    const disablePostalCode = textFieldProps(postalCodeFieldName, `${readOnlyPostalCodeFieldName}`).readOnly;

    const isDisabled = disablePostalCode || readOnly;

    const onChange = event => {
         

        let p = event.target.value;
        const val = p.toUpperCase();
        const ev = new SyntheticEvent(postalCodeFieldName, val);
        postalCodeProps.onChange(ev);
    }

    return (
        <FormControl name={qId} area-label={qId}>
            <TextFieldBound
                name={postalCodeFieldName}
                className={classes.shortTextField}
                defaultValue={defaultVal}
                onChange={onChange}
                inputProps={{ style: { textTransform: 'uppercase' } }}
                InputProps={{
                    readOnly: isDisabled,
                    inputComponent: PostalCodeMask,
                    disableUnderline: false
                }}
                disabled={disablePostalCode}
                {...other}
            />
        </FormControl>

        // <TextFieldBound
        //     className={cName}
        //     style={txtStyle}
        //     fieldStateResolver={fieldStateResolver}
        //     twoWayStateResolver={twoWayStateResolver}
        //     {...other}
        //     {...addl}
        //     {...inputProps(fieldName, readOnlyConfigFieldName)}
        //     disabled={disbld} />
    );
}

export default PostalCode;