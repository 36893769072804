import { randomBytes } from "crypto-browserify";

class IdGenerator {
    NewId() {
        const buffer = randomBytes(18);
        // const id = base64url.encode(buffer);
        const id = buffer.toString('base64');
        return id;
    }
}

const instance = new IdGenerator();

export { instance as IdGenerator }
