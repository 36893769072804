import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IdGenerator, StringUtil } from 'Util/Helpers';
import { FormFieldHelper } from './Form/FormFieldHelper';

// igor -- this is not pure as it modifies props. bad igor.

function withTwoWayFieldStateBindings(Component) {
    function ComponentExtended(props) {
        const { forwardedRef, fieldStateResolver, twoWayStateResolver, ...passThroughProps } = props;

        let twoWayStateResolverFunc = twoWayStateResolver;

        if (!twoWayStateResolver || !passThroughProps.name)
            twoWayStateResolverFunc = FormFieldHelper.nullFieldStateResolver;

        const selectChange = useMemo(
            FormFieldHelper.createFieldStateSelector,
            []
        );

        const updatedValue = useSelector(x => selectChange(twoWayStateResolverFunc(x), passThroughProps.name));

        // https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#recommendation-fully-uncontrolled-component-with-a-key
        // Uncontrolled components do not respond to state changes, hence they cannot be updated
        // Though keys are used for dynamic lists, keys can be used anywhere. Changing the value of the key will cause react to destroy and create a new component instead of updating one.
        if (!StringUtil.isNull(updatedValue)) {
            passThroughProps.defaultValue = updatedValue; // Intercept the initial state and override it with the updated shadow changes

            const existingKey = passThroughProps.key;

            let newKey;
            let rand = IdGenerator.NewId();

            if (StringUtil.isNullOrEmpty(existingKey)) {
                newKey = rand;
            }
            else {
                newKey = `${existingKey}-${rand}`;
            }

            passThroughProps.key = newKey; //Set new id since the a shadow change has probably occured. This will cause the component to re-render.
        }

        return <Component ref={forwardedRef} {...passThroughProps} />;
    }

    function forwardRefBody(props, ref) {
        return <ComponentExtended {...props} forwardedRef={ref} />;
    }

    const name = Component.displayName || Component.name;
    forwardRefBody.displayName = `${withTwoWayFieldStateBindings.name}(${name})`;

    return React.forwardRef(forwardRefBody);
}

export default withTwoWayFieldStateBindings;