import {
    Box, Button, Checkbox, CircularProgress, FormControlLabel,
    Grid, Link, TextField, Typography
} from '@mui/material';
import { green } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import ErrorSnackbar from 'Components/ErrorSnackbar';
import { withOnChangeDebounced } from 'Components/withOnChangeDebounced';
import React, { useState, useEffect, Fragment } from 'react';
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { AuthService } from 'Services/AuthService';
import { StringUtil, WebStorageHelper } from 'Util/Helpers';
import Logger from 'Util/Logger';
import AuthContainer from './AuthContainer';
import HelpSigningIn from './HelpSigningIn';
import MayUseLawPRO from './MayUseLawPRO';
import Alert from '@mui/material/Alert';
import {AdminApiClient} from "../Admin/AdminApiClient";
import AnnouncementsView from "../Components/RichTextEditor/AnnouncementsView";

const useStyles = makeStyles(theme => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    },
    btnBusyInd: {
        color: green[500]
    },
    helpIcon: {
        marginRight: theme.spacing(0.5)
    },
}));

function ErrorBlock(props) {
    const { errorMsg } = props;

    if (StringUtil.isNullOrEmpty(errorMsg))
        return null;

    return (
        <Grid item xs={12}>
            <ErrorSnackbar variant="error" message={errorMsg} />
        </Grid>
    );
}

function textFieldProps(name, value, error) {
    const p = {
        id: name,
        name: name,
        defaultValue: value || '', // uncontrolled component
        error: !StringUtil.isNullEmptyOrWhiteSpace(error),
        helperText: error
    };

    return p;
}

const logger = new Logger(Login.name);
const TextFieldDebounced = withOnChangeDebounced(TextField, 500);
const usernameInitial = WebStorageHelper.getItemFromDisk('login_page', 'username');
let rememberMeInitial = false;

if (!StringUtil.isNullOrEmpty(usernameInitial)) {
    rememberMeInitial = true;
}

function Login(props) {
    const classes = useStyles();
    const [username, setUsername] = useState(usernameInitial);
    const [usernameError, setUsernameError] = useState('');
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [rememberMe, setRememberMe] = useState(rememberMeInitial);
    const [errorMsg, setErrorMsg] = useState('');
    const [busy, setBusy] = useState(false);
    const [messages, setMessages] = useState([]);
    const [isActive, setIsActive] = useState(true);
    const { t } = useTranslation();

    const SignUpLink = React.forwardRef((props, ref) => (
        <RouterLink innerRef={ref} to={{ pathname: '/signup', search: window.location.search }} {...props} />
    ));

    let history = useHistory();

    function validateInputs() {
        let valid = true;
        if (StringUtil.isNullEmptyOrWhiteSpace(username)) {
            setUsernameError('Required');
            valid = false;
        }

        if (StringUtil.isNullEmptyOrWhiteSpace(password)) {
            setPasswordError('Required');
            valid = false;
        }

        return valid;
    }

    function usernameChange(e) {
        const newVal = e.target.value;
        if (!StringUtil.isNullEmptyOrWhiteSpace(newVal) && !StringUtil.isNullOrEmpty(usernameError)) {
            setUsernameError('');
        }

        setUsername(newVal);
    }

    function passwordChange(e) {
        const newVal = e.target.value;
        if (!StringUtil.isNullEmptyOrWhiteSpace(newVal) && !StringUtil.isNullOrEmpty(passwordError)) {
            setPasswordError('');
        }

        setPassword(newVal);
    }

    function rememberMeChange(e) {
        const newVal = e.target.checked;
        setRememberMe(newVal);

        if (newVal === false) {
            WebStorageHelper.removeFromDisk('login_page', 'username');
        }
    }

    async function mainAuth() {
        try {
            const resp = await AuthService.authClient.signInWithCredentials({
                username,
                password
            });

            if (resp.status === "LOCKED_OUT") {
                logger.info(`[LOGIN FAIL] - Login fail for user ${username} - LOCKED_OUT.`);
                throw new LoginException('LOCKED_OUT');
            }
            if (resp.status === "PASSWORD_EXPIRED") {
                logger.info(`[LOGIN FAIL] - Login fail for user ${username} - PASSWORD_EXPIRED.`);
                throw new LoginException('PASSWORD_EXPIRED');
            }

            const sessionToken = resp.sessionToken;
            WebStorageHelper.setItem('login_page', 'user-info', JSON.stringify(resp.user));

            if (rememberMe) {
                WebStorageHelper.setItemToDisk('login_page', 'username', username);
            }

            logger.info(`[LOGIN SUCCESS] - Login success for user ${username}.`);
            await AuthService.authClient.signInWithRedirect({ sessionToken });
        }
        catch (err) {
            let errorMsg = 'Could not log you in. Please check your credentials.';
            let loggerError = 'Error logging in';

            if (err.msg === 'LOCKED_OUT') {
                errorMsg = 'This account is locked. Please try again in 30 minutes or contact Customer Service at 416-598-5899 or 1-800-410-1013.';
            }
            if (err.msg === 'PASSWORD_EXPIRED') {
                history.push( `./expired/${username}/false` );
            }

            setBusy(false);
            logger.error(`${loggerError}: ${err}`);
            setErrorMsg(errorMsg);
        }

        function LoginException(err) {
            this.msg = err;
            this.name = 'LoginException';
        }
    }

    async function doLogin(event) {
        event.preventDefault(); // important otherwise the form will try to post

        setUsernameError('');
        setPasswordError('');
        setErrorMsg('');

        if (!validateInputs()) {
            return;
        }

        setBusy(true);
        await mainAuth();
    }

    useEffect(() => {
        if (isActive) {
            getSignInPageMessages();
        }
        return () => { setIsActive(false) }
    });

    async function getSignInPageMessages() {
        const req = {"IsValid": "true"};
        if (isActive) {
            const result = await AdminApiClient.getCurrentMessages( req );
            if (result === undefined || result.data === undefined || 
                result.data.Messages === undefined || result.data.Messages.length === 0) {
                return;
            }
            if (result.data.Messages.length > 0) {
                const signInMessages = result.data.Messages.filter( msg => {
                    return msg.SigninPage === true
                });
                if (signInMessages.length > 0) {
                    setMessages( signInMessages );
                }
            }
        }
    }

    return (
        <>
        <AuthContainer>
            <Helmet>
                <meta httpEquiv="Cache-Control" content="no-cache, no-store, must-revalidate"/>
            </Helmet>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    {messages.map(msg => {
                        return (
                            <Fragment key={msg.Id} >
                            <Alert key={msg.Id} severity={msg.DisplayType.toLowerCase()}>
                                {msg.Text}
                            </Alert>
                            <br/>
                            </Fragment>
                        )
                    })}
                    <br/>
                </Grid>
            </Grid>
            <Typography component="h1" variant="h5">
                Sign-In
            </Typography>
            <form className={classes.form} noValidate onSubmit={doLogin} autoComplete="off">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextFieldDebounced
                            variant="outlined"
                            required
                            autoFocus
                            fullWidth
                            label={t("Username/LSO#")}
                            autoComplete="username"
                            onChange={usernameChange}
                            {...textFieldProps("Username", username, usernameError)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextFieldDebounced
                            variant="outlined"
                            required
                            fullWidth
                            label={t("Password")}
                            type="password"
                            autoComplete="current-password"
                            onChange={passwordChange}
                            {...textFieldProps("Password", password, passwordError)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={rememberMe}
                                    onChange={rememberMeChange}
                                    name="rememberMe"
                                    color="primary"
                                />
                            }
                            label="Remember Me"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MayUseLawPRO>
                            <span style={{color: "#165c7d"}}>
                                Ontario Lawyers: Please use your LSO# and lawpro.ca password to log-in or activate account, sign up is not required
                            </span>
                        </MayUseLawPRO>
                    </Grid>
                    <ErrorBlock errorMsg={errorMsg}/>
                </Grid>

                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    disabled={busy}
                >
                    Login
                </Button>
                {busy &&
                    <Box display="flex" justifyContent="center" mb={3}>
                        <CircularProgress className={classes.btnBusyInd}/>
                    </Box>
                }
                {!busy &&
                    <React.Fragment>
                        <Box display="flex" justifyContent="flex-end">
                            <Link component={SignUpLink} variant="body1">
                                Not a member of the LSO and don’t currently have an account?
                            </Link>
                        </Box>
                        <HelpSigningIn classes={classes}/>
                    </React.Fragment>
                }
            </form>
        </AuthContainer>
        <AnnouncementsView type={'signin'} user={undefined} />
        </>
    )
}

export default Login;
