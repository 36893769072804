class UriUtil {
    static replaceUrlParam(name, value) {
        const searchParams = new URLSearchParams(window.location.search);
        const currVal = searchParams.get(name);

        if (currVal !== value) {
            searchParams.set(name, value);
            const newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
            window.history.replaceState(null, '', newRelativePathQuery);
        }
    }

    static replaceUrlParamAndNavigate(name, value) {
        const searchParams = new URLSearchParams(window.location.search);
        const currVal = searchParams.get(name);

        searchParams.set(name, value);
        const newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
        
        window.location.assign(newRelativePathQuery);
    }

    static getUpdatedSearchParams(name, value) {
        const searchParams = new URLSearchParams(window.location.search);
        
        searchParams.set(name, value);

        const result = searchParams.toString();

        return result;
    }

    static getDomain(url, subdomain) {
        subdomain = subdomain || false;
    
        url = url.replace(/(https?:\/\/)?(www.)?/i, '');
    
        if (!subdomain) {
            url = url.split('.');
    
            url = url.slice(url.length - 2).join('.');
        }
    
        if (url.indexOf('/') !== -1) {
            return url.split('/')[0];
        }
    
        return url;
    }
}

export default UriUtil;