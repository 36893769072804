import { Box, Button, CardActionArea, CardActions, CardContent, Popover, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { createSelector } from '@reduxjs/toolkit';
import { FormFieldHelper } from 'Components/Form/FormFieldHelper';
import { SectionHeader } from 'Components/Questionary';
import { cloneDeep } from 'lodash';
import { UnderwritingManager } from 'Model/UserRoles';
import { PolicyApiClient } from 'Policy/PolicyApiClient';
import PolicyViewHelper from 'Policy/PolicyViewHelper';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import BrowserHelper from 'Util/BrowserHelper';
import { StringUtil } from 'Util/Helpers';
import { ValidationHelper } from 'Util/ValidationHelper';
import { IdGenerator } from '../../../Util/IdGenerator';
import { Calculator } from './Calculator';
import PremiumBreakdown from './PremiumBreakdown';

const useStyles = makeStyles((theme) => ({
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    alignRight: {
        float: 'right'
    }
}));

function PremiumInfo(props) {
    const classes = useStyles();
    const { t } = useTranslation();

    const [premium, setPremium] = useState(null);
    const [changeCount, setChangeCount] = useState(0);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [actionMsg, setActionMsg] = useState('');
    const [actionMsgType, setActionMsgType] = useState('');
    const [working, setWorking] = useState(false);

    const { po, userInfo } = props;

    const selectOrder = useMemo(
        FormFieldHelper.createFieldStateSelector,
        []
    );

    const viewHelper = new PolicyViewHelper(po, null);

    const premiumStore = useSelector(x => selectOrder(x.order, 'premium'));
    const isCalculatingPremium = useSelector(x => selectOrder(x.order, 'isCalculatingPremium'));

    useEffect(() => {
        //set ids on discounts and fees
        if (premiumStore) {
            let p = cloneDeep(premiumStore);
            const breakdown = p.BreakDown.map(x => {
                const discounts = x.Discounts.map(d => {
                    let isAppliedAfterTax = d.ApplyAfterTax;
                    if (StringUtil.isNull(isAppliedAfterTax))
                        isAppliedAfterTax = false;

                    let id = d.Id;
                    if (StringUtil.isNull(id))
                        id = IdGenerator.NewId();

                    return {
                        ...d,
                        'Id': id,
                        'ApplyAfterTax': isAppliedAfterTax
                    };
                });

                const fees = x.Fees.map(f => {
                    let id = f.Id;
                    if (StringUtil.isNull(id))
                        id = IdGenerator.NewId();

                    return {
                        ...f,
                        'Id': id
                    };
                });

                const taxes = x.Premium.PremiumAmount.Taxes.map(t => {
                    let rate = 0;
                    if (!StringUtil.isNullOrEmpty(t.Rate))
                        rate = t.Rate;

                    return {
                        ...t,
                        'Rate': rate
                    };
                });

                let taxesTotal = 0;
                if (!StringUtil.isNullOrEmpty(x.Premium.PremiumAmount.TaxesTotal))
                    taxesTotal = x.Premium.PremiumAmount.TaxesTotal;

                const premiumAmount = {
                    ...x.Premium.PremiumAmount,
                    'Taxes': taxes,
                    'TaxesTotal': taxesTotal
                };

                const premium = {
                    ...x.Premium,
                    'PremiumAmount': premiumAmount
                };

                return {
                    ...x,
                    'Discounts': discounts,
                    'Fees': fees,
                    'Premium': premium
                };

            });
            setPremium({
                ...p,
                'BreakDown': breakdown
            });
        }
        else
            setPremium(null);
    }, [premiumStore]);

    useEffect(() => {
        if (changeCount > 0)
            if (premium) {
                const newPremium = Calculator.calculatePremium(premium);

                if (newPremium)
                    setPremium(newPremium);
            }
    }, [changeCount]);

    if (!userInfo)
        return null;

    const role = userInfo.Role;

    let readOnly = !StringUtil.isEqual(role, UnderwritingManager);

    if (BrowserHelper.isIE11())
        readOnly = true;

    function addDiscount(item, policyIdx) {
        //todo validate
        const items = [...premium.BreakDown[policyIdx].Discounts, item];

        let breakDown = [...premium.BreakDown];

        breakDown[policyIdx] = {
            ...breakDown[policyIdx],
            'Discounts': [...items]
        };

        setPremium(p => ({
            ...p,
            'BreakDown': [...breakDown]
        }));
    }

    function removeDiscount(item, policyIdx) {
        const discounts = premium.BreakDown[policyIdx].Discounts.filter(x => !StringUtil.isEqual(x.Id, item.Id));

        let breakDown = [...premium.BreakDown];

        breakDown[policyIdx] = {
            ...breakDown[policyIdx],
            'Discounts': [...discounts]
        };

        setPremium(p => ({
            ...p,
            'BreakDown': [...breakDown]
        }));

        setChangeCount(x => x + 1);
    }

    function setDiscount(item, policyIdx) {
        let policyPremiumItem = premium.BreakDown[policyIdx];

        const items = policyPremiumItem.Discounts.map((d, index) => {
            if (StringUtil.isEqual(d.Id, item.Id))
                d = cloneDeep(item);

            return d;
        });

        let breakDown = [...premium.BreakDown];

        breakDown[policyIdx] = {
            ...breakDown[policyIdx],
            'Discounts': [...items]
        };

        setPremium(p => ({
            ...p,
            'BreakDown': [...breakDown]
        }));

        setChangeCount(x => x + 1);
    }

    function addFee(item, policyIdx) {
        //todo validate
        const items = [...premium.BreakDown[policyIdx].Fees, item];

        let breakDown = [...premium.BreakDown];

        breakDown[policyIdx] = {
            ...breakDown[policyIdx],
            'Fees': [...items]
        };

        setPremium(p => ({
            ...p,
            'BreakDown': [...breakDown]
        }));
    }

    function removeFee(item, policyIdx) {
        const items = premium.BreakDown[policyIdx].Fees.filter(x => !StringUtil.isEqual(x.Id, item.Id));

        let breakDown = [...premium.BreakDown];

        breakDown[policyIdx] = {
            ...breakDown[policyIdx],
            'Fees': [...items]
        };

        setPremium(p => ({
            ...p,
            'BreakDown': [...breakDown]
        }));

        setChangeCount(x => x + 1);
    }

    function setFee(item, policyIdx) {
        let policyPremiumItem = premium.BreakDown[policyIdx];

        const items = policyPremiumItem.Fees.map((f, index) => {
            if (StringUtil.isEqual(f.Id, item.Id))
                f = cloneDeep(item);

            return f;
        });

        let breakDown = [...premium.BreakDown];

        breakDown[policyIdx] = {
            ...breakDown[policyIdx],
            'Fees': [...items]
        };

        setPremium(p => ({
            ...p,
            'BreakDown': [...breakDown]
        }));

        setChangeCount(x => x + 1);
    }

    const discountProps = {
        setDiscount: setDiscount,
        addDiscount: addDiscount,
        removeDiscount: removeDiscount
    }

    const feeProps = {
        setFee: setFee,
        addFee: addFee,
        removeFee: removeFee
    }

    function setBasePremium(event, policyIdx) {
        let policyPremiumItem = cloneDeep(premium.BreakDown[policyIdx]);

        let value = event.target.value;

        if (StringUtil.isNullOrEmpty(value))
            value = '0';

        value = value.split(',').join('');

        policyPremiumItem.Premium.PremiumAmount.BasePremium = parseFloat(value);

        let breakDown = [...premium.BreakDown];

        breakDown[policyIdx] = { ...policyPremiumItem };

        setPremium(p => ({
            ...p,
            'BreakDown': [...breakDown]
        }));

        setChangeCount(x => x + 1);
    }

    async function savePremium() {
        setWorking(true);
        const orderSecureId = viewHelper.getDealIdFromQueryString();
        const resp = await PolicyApiClient.savePremium(orderSecureId, po, premium);
        setWorking(false);

        if (resp.hasError) {
            setActionMsgType('error');
            setActionMsg(resp.errorMessage);

            if (!resp.hasValidationResult) {
                return;
            }
        }

        const respData = resp.data;

        const validationMap = ValidationHelper.getValidationMap(respData);

        if (!StringUtil.isNullOrEmpty(validationMap)) {
            return; // we hit a validation error -- TODO, set validations msg
        }
        setActionMsgType(respData.Status);
        setActionMsg(respData.StatusDescription);

        setChangeCount(0);
    }

    const open = Boolean(anchorEl);

    return <Fragment>
        <CardActionArea>
            <CardContent>
                <Fragment>
                    <Typography gutterBottom variant="h5">
                        ${premium ? `${premium.Total}` : `0.00`}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                        {isCalculatingPremium ? 'Recalculating...' : 'Estimated premium total.'}
                    </Typography>
                </Fragment>
            </CardContent>
        </CardActionArea>
        {(!isCalculatingPremium && premium) && <CardActions>
            <Button size="small" color="primary" onClick={(event) => {
                setAnchorEl(event.currentTarget);
            }}>
                Show Breakdown
            </Button>
            <Popover
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}>
                <Box className={`${classes.paper}`}>
                    <SectionHeader title="Premium Details" />
                    <PremiumBreakdown
                        premium={premium}
                        setBasePremium={setBasePremium}
                        feeProps={feeProps}
                        discountProps={discountProps}
                        readOnly={readOnly} />
                    <Button size="small" color="primary" onClick={() => setAnchorEl(null)} disabled={changeCount > 0 && !readOnly}>
                        Close
                    </Button>
                    {!readOnly && <Button size="small" color="primary" className={classes.alignRight} onClick={savePremium} disabled={changeCount === 0 || working}>
                        {working ? 'Saving...' : 'Save'}
                    </Button>}
                </Box>
            </Popover>
        </CardActions>}
    </Fragment>
}

export default PremiumInfo;