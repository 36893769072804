import { createReducer } from '@reduxjs/toolkit';
import * as actions from './AppActions';

const initialState = {
    actionMsg: { type: 'success', message: '' }
}

const AppReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(actions.setActionMessage, (state, action) => {
            console.log(action.payload);
            state.actionMsg = action.payload;
        })
        .addCase(actions.clearActionMessage, (state, action) => {
            state.actionMsg.message = '';
        })
        .addDefaultCase((state, action) => { })
});

export default AppReducer;