import React, { useMemo } from "react";
import { Button, IconButton, Link } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import Fact from "Components/Fact";
import { SourceEnum } from "Model/PolicyOrder";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { createSelector } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import SyntheticEvent from "Components/SyntheticEvent";
import { useState } from "react";
import StringUtil from "Util/StringUtil";

function createOrderSourceSelector() {
  return createSelector(
    (state) => state.order.source,
    (x) => x
  );
}

function NewHomeProgramWarningForPartners(props) {
  const { name, onChange, readOnly, item, defaultValue } = props;
  const { t } = useTranslation();

  const [open, setOpen] = useState(defaultValue);
  //const dispatch = useDispatch();

  const selectOrderSource = useMemo(createOrderSourceSelector, []);

  const orderSource = useSelector((x) => selectOrderSource(x, item));

  const validSources = [SourceEnum.DD, SourceEnum.LDD, SourceEnum.LawLabs];

  if (!validSources.includes(orderSource)) {
    return null;
  }

  const val = t(orderSource);

  function openLink(link) {
    //  dispatch(DocumentThunks.viewNewHomeProgramList());
    window.open(link, "_blank");
  }

  function showHideMessage(shouldShowMessage) {
    if (readOnly) {
      return;
    }

    const ev = new SyntheticEvent(name, shouldShowMessage);
    onChange(ev);
    setOpen(shouldShowMessage);
  }

  return (
    <Fragment>
      {StringUtil.isEqual(open, "yes") && (
        <Fact
          warning
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                showHideMessage("no");
              }}
            >
              <ExpandLess />
            </IconButton>
          }
        >
          Many new home and condo developments are available in the TitlePLUS
          New Home Program for easier and faster underwriting and fewer
          searches. Check this{" "}
          <Link
            onClick={(e) =>
              openLink("https://titleplus.ca/new-home-program/current-projects")
            }
          >
            List
          </Link>{" "}
          to see if your development is part of the program. Take advantage by
          logging directly into{" "}
          <Link onClick={(e) => openLink("https://mytitleplus.ca/login")}>
            TitlePLUS
          </Link>{" "}
          to complete your application. Your order will not be linked to your{" "}
          {val} file.
        </Fact>
      )}
      {StringUtil.isEqual(open, "no") && (
        <Fact
          warning
          action={
            <Button
              onClick={(event) => {
                showHideMessage("yes");
              }}
            >
              <ExpandMore />
            </Button>
          }
        >
          View More
        </Fact>
      )}
    </Fragment>
  );
}

export default NewHomeProgramWarningForPartners;
