import React, { useMemo } from 'react';
import { AppBar, CssBaseline, Chip, Toolbar, Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { StringUtil, UserHelper, TppHelper } from 'Util/Helpers';
// import PremiumSynopsis from 'Policy/Sections/PremiumSection/PremiumSynopsis';
import PremiumSynopsis from 'Policy/PremiumSynopsis';
import Logger from 'Util/Logger';
import { useTranslation } from "react-i18next";
import NavMenu2 from './NavMenu2';
import { Link as RouterLink } from 'react-router-dom';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import SavingIndicator from './SavingIndicator';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import { PermittedOrderActionsEnum } from 'Model/PolicyOrder';
import * as UserPermissions from 'Model/UserPermissions';

const drawerWidth = 180;
const rightBarOffset = 120;

const logger = new Logger(PolicyViewFrame.name);

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    appBar: {
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
        marginTop: theme.spacing(3)
    },
    rightFloatingBar: {
        top: rightBarOffset,
        // Fix IE 11 position sticky issue.
        marginTop: rightBarOffset,
        width: 208,
        flexShrink: 0,
        order: 2,
        position: 'sticky',
        height: `calc(100vh - ${rightBarOffset}px)`,
        overflowY: 'auto',
        padding: theme.spacing(2, 2, 2, 0),
        display: 'none',
        [theme.breakpoints.up('xs')]: {
            display: 'block',
        },
    },
    grow: {
        flexGrow: 1,
    },
    sectionDesktop: {
        maxWidth: 'xs',
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    }
}));

const useStylesSumm = makeStyles(theme => ({
    creatorInfo: {
        marginLeft: theme.spacing(2),
        fontSize: '0.9rem'
    }
}));

function createOrderVolatileDataSelector() {
    return createSelector(
        state => state.order.orderVolatileData,
        (_, propName) => propName,
        (x, propName) => x[propName]
    );
}

function OrderTopSummary(props) {
    const { po } = props;

    const classes = useStylesSumm();

    const selectOrderVolatileData = useMemo(
        createOrderVolatileDataSelector,
        []
    );

    let dealNumber = useSelector(x => selectOrderVolatileData(x, "DealNumber"));
    let createdByName = useSelector(x => selectOrderVolatileData(x, "CreatedByName"));
    let secureId = useSelector(x => selectOrderVolatileData(x, "SecureId"));

    if (!dealNumber) {
        dealNumber = po.DealNumber;
    }

    if (!createdByName) {
        createdByName = po.CreatedByName;
    }

    if (!secureId) {
        secureId = po.SecureId;
    }

    const isNew = StringUtil.isEqual(secureId, 'new');

    const showCreator = !isNew;

    return (
        <Typography variant="h5" noWrap>
            Policy Order {!StringUtil.isNullOrEmpty(dealNumber) && <small>- {dealNumber}</small>}
            {showCreator && <span className={classes.creatorInfo}>{createdByName}</span>}
            <SavingIndicator />
        </Typography>
    );
}

function PolicyViewFrame(props) {
    const { po, userInfo, permittedActions, children } = props;

    const { t } = useTranslation();

    const classes = useStyles();

    const showBgChecks = UserHelper.hasPermission(userInfo, UserPermissions.view_background_checks) && StringUtil.containsAny(po.PermittedActions, PermittedOrderActionsEnum.Approve, PermittedOrderActionsEnum.Decline);
    const showExceptions = StringUtil.containsAny(permittedActions, PermittedOrderActionsEnum.ViewExceptions, PermittedOrderActionsEnum.ApplyExceptions);
    const showEndorsements = StringUtil.containsAny(permittedActions, PermittedOrderActionsEnum.ViewEndorsements, PermittedOrderActionsEnum.ApplyExceptions);

    const menuItems = [
        { id: 'getStarted', text: t('Transaction') },
        { id: 'properties', text: 'Properties' },
        { id: 'purchasers', text: 'Purchasers' },
        { id: 'mortgages', text: 'Mortgages' },
        { id: 'titleSurvey', text: 'Title/Survey' },
        { id: 'offTitle', text: 'Off-Title' },
        { id: 'additionalConcerns', text: 'Additional Concerns' },
        { id: 'fraud', text: t('fraud_prevention') }
    ];

    if (showBgChecks) {
        menuItems.unshift({ id: 'checks', text: 'Background Checks' }); // this will be inserted  into the beginning ...
    }

    if (showExceptions) {
        menuItems.push({ id: 'exceptions', text: t('Exceptions') });
    }

    if (showEndorsements) {
        menuItems.push({ id: 'endorsements', text: t('Endorsements') });
    }

    const shouldDisplayBackNutton = TppHelper.isTPOrigin();

    const backLink = React.forwardRef((props, ref) => (
        <RouterLink innerRef={ref} to={{ pathname: '/' }} {...props} />
    ));

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <OrderTopSummary po={po} />
                    <div className={classes.grow} />
                    <div className={classes.sectionDesktop}>
                        {shouldDisplayBackNutton && <Button color='defButton'
                            variant="contained"
                            size="small"
                            startIcon={<ArrowBackIosRoundedIcon />}
                            component={backLink}
                            style={{ marginRight: 5 }}>
                            Back
                        </Button>}
                    </div>
                </Toolbar>
                <NavMenu2 items={menuItems} />
            </AppBar>
            <div className={classes.rightFloatingBar}>
                <PremiumSynopsis {...props} />
            </div>
            <div className={classes.content}>
                {children}
            </div>
        </div>
    );
}

export default PolicyViewFrame;