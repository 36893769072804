import React from 'react';
import { Box, Link } from '@mui/material';

function HelpSigningIn() {
    const lawProResetPassPath = "/forgot-password";
    const lawProForgutUsernamePath = "/forgot-username";

    function forgotPassword(event) {
        event.preventDefault();
        window.location.assign(lawProResetPassPath);
    }

    function forgotUsername(event) {
        event.preventDefault();
        window.location.assign(lawProForgutUsernamePath);
    }

    return (<React.Fragment>
        <Box mt={1} display="flex" justifyContent="flex-end" style={{ marginTop: 35 }}>
            <Link component="button" variant="body1" onClick={forgotPassword}>
                Forgot/Change Password
            </Link>
        </Box>
        <Box mt={1} display="flex" justifyContent="flex-end">
            <Link component="button" variant="body1" onClick={forgotUsername}>
                Forgot Username
            </Link>
        </Box>
    </React.Fragment>
    );
}

export default HelpSigningIn;
