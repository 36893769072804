import { green } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import { SectionHeader } from 'Components/Questionary';
import { CollectionTypeEnum } from 'Model/PolicyOrder';
import * as PolicyActions from 'Policy/State/PolicyActions';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { StringUtil } from 'Util/Helpers';
import { SectionHelper } from '../../Util/SectionHelper';
import PropertyInfo from './PropertyInfo';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'inline-block',
        width: '100%',
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(3)
    },
    headerIcon: {
        color: green[800]
    }
}));

function PropertiesSection(props) {
    const classes = useStyles();

    const { questionary, questionParams, loadQuestionary, getInitialAnswer } = props;

    const [openIdx, setOpenIdx] = useState(0);

    const itemsList = useSelector(x => x.order.properties);

    const dispatch = useDispatch();

    const collectionName = CollectionTypeEnum.Properties;

    const canAdd = itemsList.length < 6;

    function add() {
        // TODO: Remove
        return;
        const currCount = itemsList.length;

        const newItem = SectionHelper.addNewSection(collectionName, questionParams);

        newItem.ItemNumber = currCount + 1;

        dispatch({ type: PolicyActions.add_property.type, payload: newItem });

        setOpenIdx(currCount);
    }

    function remove(item) {
        // TODO: Remove
        return;
        const newList = itemsList.filter(x => !StringUtil.isEqual(x.Id, item.Id));

        SectionHelper.removeSection({ collectionName, item, questionParams });

        dispatch({ type: PolicyActions.remove_property.type, payload: item });

        setOpenIdx(newList.length - 1);
    }

    return (
        <React.Fragment>
            <SectionHeader section="properties" title="Properties" questionary={questionary} iconElem={<HouseRoundedIcon className={classes.headerIcon} />} />
            <div className={classes.root}>
                {itemsList.map((item, index) => {
                    return (
                        <PropertyInfo
                            key={item.Id}
                            idx={index}
                            item={item}
                            collectionName={collectionName}
                            canAdd={canAdd}
                            add={add}
                            remove={remove}
                            questionParams={questionParams}
                            openIdx={openIdx}
                            setOpenIdx={setOpenIdx}
                            questionary={questionary}
                            loadQuestionary={loadQuestionary}
                            getInitialAnswer={getInitialAnswer}
                        />
                    );
                })}
            </div>
        </React.Fragment>
    );
}

export default PropertiesSection;