import { Box, FormGroup, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { createSelector } from '@reduxjs/toolkit';
import { FormFieldHelper } from 'Components/Form/FormFieldHelper';
import { Question, SectionHeader } from 'Components/Questionary';
import { CollectionTypeEnum } from 'Model/PolicyOrder';
import React, { Fragment, useMemo } from "react";
import { useSelector } from 'react-redux';
import { QuestionaryHelper } from 'Util/QuestionaryHelper';
import StringUtil from 'Util/StringUtil';
import Mortgages from './Mortgages';
import Purchasers from './Purchasers';

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 800,
        width: 'auto'
    },
    displayInline: {
        display: 'inline-flex'
    }
}));

function createOrderSelector() {
    return createSelector(
        state => state,
        x => x
    );
}

function PropertyInfo(props) {
    const classes = useStyles();
    const { item, idx, questionParams, questionary } = props;

    const indexer = QuestionaryHelper.createIndexer(CollectionTypeEnum.Properties, item);

    const select = useMemo(
        createOrderSelector,
        []
    );

    const purchasers = useSelector(x => select(x.order.purchasers))
        .filter(p => StringUtil.isEqual(p.PurchaserPropertyId, item.Id));

    const mortgages = useSelector(x => select(x.order.mortgages))
        .filter(m => StringUtil.isEqual(m.MortgagePropertyId, item.Id))
        .sort((a, b) => {
            if (a.PriorityUponClosing > b.PriorityUponClosing)
                return 1;
            if (a.PriorityUponClosing < b.PriorityUponClosing)
                return -1;
            return 0;
        });

    const selectPriorityOfClosingMortgagesChangeCount = useMemo(
        FormFieldHelper.createFieldStateSelector,
        []
    );

    const priorityOfClosingMortgageChangeCount = useSelector(x => selectPriorityOfClosingMortgagesChangeCount(x.order.orderChanges, `${indexer}PriorityOfClosingMortgageChangeCount`));

    const policyMap = useSelector(x => select(x.order.policyMap));

    const collectionName = CollectionTypeEnum.Properties;
    questionParams.indexer = QuestionaryHelper.createIndexer(collectionName, item);

    const conditionalProps = {
        indexer: questionParams.indexer,
        ...questionParams
    };

    if (!policyMap)
        return null;

    const owners = purchasers.map((p) => {
        let policyNumber = null;

        const policyDetails = policyMap.find(x => x.Ids.some(id => StringUtil.isEqual(p.Id, id)));

        if (policyDetails)
            policyNumber = policyDetails.PolicyNumber;

        return {
            ...p,
            PolicyNumber: policyNumber
        };
    }).filter(p => !StringUtil.isNullOrEmpty(p.PolicyNumber));

    const lenders = mortgages.map((m) => {
        let policyNumber = null;

        const policyDetails = policyMap.find(x => x.Ids.some(id => StringUtil.isEqual(m.Id, id)));

        if (policyDetails)
            policyNumber = policyDetails.PolicyNumber;

        return {
            ...m,
            PolicyNumber: policyNumber
        };
    }).filter(m => !StringUtil.isNullOrEmpty(m.PolicyNumber));

    const title = `Property ${idx + 1}`;

    return <Fragment>
        <SectionHeader section={title} title={title} questionary={questionary} subSection>
            <Box className={`${classes.displayInline}`}>
                <Question qId="PropertyAddressStreet" {...questionParams} displayOnly hideLabel />
                <Typography>,</Typography>
                <Question qId="PropertyAddressCity" {...questionParams} displayOnly hideLabel />
                <Typography>,</Typography>
                <Question qId="PropertyAddressProvince" {...questionParams} displayOnly hideLabel />
                <Question qId="PropertyPIN" {...questionParams} displayOnly hideLabel />
            </Box>
        </SectionHeader>
        <TableContainer component={Paper} className={classes.table}>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell>Policy Ordered</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Policy Amount</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <Purchasers {...props} itemsList={owners} propertyConditionalProps={conditionalProps} />
                    <Mortgages key={priorityOfClosingMortgageChangeCount} {...props} itemsList={lenders} propertyConditionalProps={conditionalProps} />
                </TableBody>
            </Table>
        </TableContainer>
    </Fragment>;
}

export default PropertyInfo;