import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTPStaffUsers } from 'Admin/State/TPStaffUsersSlice';
import * as UserActions from 'User/State/UserActions';

function EagerLoader(props)
{
    const selectUserInfo = useMemo(() => UserActions.createUserInfoSelector(), []);
    const userInfo =  useSelector(x => selectUserInfo(x));
    const tpStaffUsersStatus = useSelector(x => x.tpStaffUsers.status);
    const dispatch = useDispatch();

    useEffect(() => {
        if (userInfo && tpStaffUsersStatus === 'idle') {
            dispatch(fetchTPStaffUsers());
        }
    }, [userInfo, tpStaffUsersStatus, dispatch]);

    return null;
}

export default EagerLoader;
