import * as actions from './ProjectActions';
import { createReducer } from '@reduxjs/toolkit';
import { StringUtil } from 'Util/Helpers';
import { SectionHelper } from 'Util/SectionHelper';


const initialState = {
    userInput: {}, //input changes
    formState: {}, //validations
    propertyLegalDescription: [],
    exceptions: [],
    exceptionRange: [],
    isBusy: false,
    errorMsg: '',
    redirectToPayment: false
}

const ProjectReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(actions.set_new_project, (state, action) => {
            const projectData = action.payload;
            state.userInput = {};
            state.formState = {};
            state.propertyLegalDescription = projectData.PropertyLegalDescription;
            state.exceptions = projectData.Exceptions;
            state.exceptionRange = projectData.ExceptionRange; //To Fix
            state.isBusy = false;
            state.errorMsg = '';
            state.redirectToPayment = false;
        })
        .addCase(actions.setProjectInput, (state, action) => {
            const { fieldName, fieldValue } = action.payload;

            if (StringUtil.endsWith(fieldName, '.IsDeleted')) {
                const fieldsToRemove = SectionHelper.getSectionsAnswersToRemove(fieldName, state.userInput);

                if (fieldsToRemove.length > 0) {
                    for (const fieldToRemove of fieldsToRemove) {
                        delete state.userInput[fieldToRemove];
                    }
                }
            }

            const currValue = state.userInput[fieldName];

            if (currValue === fieldValue) {
                return; // do not change anything...
            }

            state.userInput[fieldName] = fieldValue;
        })
        .addCase(actions.setValidationResult, (state, action) => {
            const { validationMap } = action.payload;

            if (!validationMap) {
                return;
            }

            const formStateUpdate = {};

            // fieldName should alreay be lowercase ...
            for (const fieldName of Object.keys(validationMap)) {
                let fieldState = state.formState[fieldName];
                const validationError = validationMap[fieldName];
                const isError = StringUtil.isEqual(validationError.Severity, "error");

                let newState;

                if (fieldState) {
                    newState = { ...fieldState };
                }
                else {
                    newState = { name: fieldName };
                }

                newState.error = isError;
                newState.helperText = validationError.ErrorMessage;

                formStateUpdate[fieldName] = newState;
            }

            state.formState = { ...state.formState, ...formStateUpdate };
        })
        .addCase(actions.clearValidationError, (state, action) => {
            const { fieldName } = action.payload;

            if (!fieldName) {
                return;
            }

            const fieldNameLower = fieldName.toLowerCase();

            const fieldState = state.formState[fieldNameLower];

            if (!fieldState) { return; }

            const newState = { ...fieldState };

            newState.error = false;
            newState.helperText = '';

            state.formState[fieldNameLower] = newState;
        })
        .addCase(actions.setError, (state, action) => {
            const newErrMsg = action.payload;

            state.errorMsg = newErrMsg;
        })
        .addCase(actions.clearError, (state, action) => {
            state.errorMsg = '';
        })
        .addCase(actions.setBusy, (state, action) => {
            const isBusy = action.payload;

            state.isBusy = isBusy;
        })
        .addCase(actions.addProperty, (state, action) => {
            const property = action.payload;

            if (!state.propertyLegalDescription || state.propertyLegalDescription.length === 0)
            state.propertyLegalDescription = [];

            state.propertyLegalDescription.push(property);
        })
        .addCase(actions.removeProperty, (state, action) => {
            const item = action.payload;

            state.propertyLegalDescription = state.propertyLegalDescription.filter(x => !StringUtil.isEqual(x.Id, item.Id))
        })

        .addCase(actions.addException, (state, action) => {
            const exception = action.payload;

            if (!state.exceptions || state.exceptions.length === 0)
                state.exceptions = [];

            state.exceptions.push(exception);
        })
        .addCase(actions.removeException, (state, action) => {
            const item = action.payload;

            state.exceptions = state.exceptions.filter(x => !StringUtil.isEqual(x.Id, item.Id))
        })

        .addCase(actions.addExceptionRange, (state, action) => {
            const property = action.payload;

            if (!state.exceptionRange || state.exceptionRange.length === 0)
                state.exceptionRange = [];

            state.exceptionRange.push(property);
        })
        .addCase(actions.removeExceptionRange, (state, action) => {
            const item = action.payload;

            state.exceptionRange = state.exceptionRange.filter(x => !StringUtil.isEqual(x.Id, item.Id))
        })


        .addDefaultCase((state, action) => { })
});

export default ProjectReducer;