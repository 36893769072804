import { Collapse, Divider, Grid, Icon, ListItem } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import React, { useState } from 'react';
import { StringUtil } from 'Util/Helpers';
import { QuestionaryHelper } from 'Util/QuestionaryHelper';
import Stipulation from './Stipulation';

const useStyles = makeStyles(theme => ({
    stipDivider: {
        margin: theme.spacing(1, 3, 0, 0)
    }
}));

const titleStyles = makeStyles(theme => ({
    title: {
        color: theme.palette.grey[800]
    },
    titleWithExpand: {
        display: 'flex',
        alignItems: 'center'
    },
    expandInd: {
        marginLeft: theme.spacing(0.5)
    },
    iconRoot: {
        left: -35,
        marginRight: -21,
        position: 'relative',
        float: 'left',
        overflow: 'visible'
    },
    icon: {
        maxHeight: 24,
        maxWidth: 24
    },
    fixMargin: {
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5)
    }
}));

function TitleIcon(props) {
    const { iconImgPath, iconElem } = props;

    if (!iconImgPath && !iconElem) {
        return null;
    }

    const classes = titleStyles();

    if (iconElem) {
        return (
            <span className={classes.iconRoot}>{iconElem}</span>
        );
    }

    return (
        <Icon classes={{ root: classes.iconRoot }}>
            <img src={iconImgPath} className={classes.icon} />
        </Icon>
    );
}

function TitleText(props) {
    const classes = titleStyles();

    const { collapsible, title, open, setOpen, childrenElems } = props;

    if (!collapsible || !childrenElems) {
        return (<Typography variant="h5" className={classes.title}><TitleIcon {...props} />{title}</Typography>);
    }

    const handleClick = (event) => {
        setOpen(!open);
    };

    return (
        <ListItem disableGutters dense button onClick={handleClick}>
            <Typography variant="h5" className={classes.titleWithExpand}>
                <TitleIcon {...props} />{title}{open ? <ExpandLess className={classes.expandInd} /> : <ExpandMore className={classes.expandInd} />}
            </Typography>
        </ListItem>
    );
}

function ChildrenComponents(props) {
    const { children, collapsible, open, childrenElems } = props;

    if (!childrenElems) {
        return null;
    }

    if (collapsible) {
        if (!open) {
            return null;
        }

        return (
            <Grid item xs={12}>
                <Collapse in={open} timeout="auto">
                    {childrenElems}
                </Collapse>
            </Grid>
        );
    }

    return (
        <Grid item xs={12}>
            {childrenElems}
        </Grid>
    );
}

function SectionHeader(props) {
    const classes = useStyles();

    const { questionary, section, subSection, children } = props;

    let isOpen = false;
    if(props.open)
        isOpen = props.open;

    const [open, setOpen] = useState(isOpen);

    const stips = QuestionaryHelper.getStipulations(questionary);

    const sectionStips = stips.filter((s) => StringUtil.isEqual(section, s.Section));

    return (
        <React.Fragment>
            <Grid item xs={12} style={subSection && { marginTop: 10, marginBottom: 10 }}>
                <div id={section} style={{ position: 'relative', top: -127, left: 0 }}></div>
                <TitleText {...props} open={open} setOpen={setOpen} childrenElems={children} />
            </Grid>
            <ChildrenComponents {...props} open={open} childrenElems={children} />
            {sectionStips.length > 0 &&
                <Grid item xs={12}>
                    {sectionStips.map((s) =>
                        <Stipulation key={s.Id} questionary={questionary} stipId={s.Id}></Stipulation>
                    )}
                    <Divider className={classes.stipDivider} />
                </Grid>}
        </React.Fragment>
    )
}

export default SectionHeader;