import React from 'react';
import { Container, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Copyright from 'Components/Copyright';

const useStyles = makeStyles(theme => ({
  logo: {
    marginBottom: theme.spacing(1.5),
    width: '80%'
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }
}));

function AuthContainer(props) {
  const { children } = props;

  const classes = useStyles();

  return (
    <Container component="div" maxWidth="xs">
      <div className={classes.paper}>
        <img className={classes.logo} src='/img/tp_logo.svg'></img>
        <Box mt={1} />
        {children}
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}

export default AuthContainer;