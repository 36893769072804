import makeStyles from '@mui/styles/makeStyles';
import GavelIcon from '@mui/icons-material/Gavel';
import { SectionHeader } from 'Components/Questionary';
import { isEqual } from 'lodash';
import { PermittedOrderActionsEnum } from 'Model/PolicyOrder';
import * as UserPermissions from 'Model/UserPermissions';
import React, { createRef, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import * as UserActions from 'User/State/UserActions';
import BrowserHelper from 'Util/BrowserHelper';
import { StringUtil, UserHelper } from 'Util/Helpers';
import useDebounce from 'Util/UseDebounce';
import { ValidationHelper } from 'Util/ValidationHelper';
import { PolicyApiClient } from '../../PolicyApiClient';
import Exception from './Exception';
import SearchException from './SearchException';
import { brown } from '@mui/material/colors';

const useStyles = makeStyles(() => ({
    attention: {
        color: brown[400]
    }
}));

function ExceptionsView(props) {
    const classes = useStyles();
    const { po, userInfo, permittedActions } = props;

    const [appliedExceptions, setAppliedExceptions] = useState([]);
    const [changeCount, setChangeCount] = useState(0);
    const debouncedSave = useDebounce(changeCount, 4000);

    const [validationsMap, setValidationsMap] = useState();
    const [actionMsg, setActionMsg] = useState('');
    const [actionMsgType, setActionMsgType] = useState('');

    const [refs, setRefs] = useState([]);

    const exceptions = useSelector(x => x.order.exceptions);

    useEffect(() => {
        if (exceptions) {
            const excptns = exceptions.map(item => {
                const _item = {
                    ...item,
                    AlreadyApplied: false
                }
                return _item;
            });
            setAppliedExceptions(excptns);
        }
    }, [exceptions]);

    useEffect(() => {
        setRefs(r => (
            appliedExceptions.map((_, i) => r[i] || createRef())
        ));

        appliedExceptions.forEach((o, index) => {
            if (o.AlreadyApplied)
                refs[index].current.focus();
        })
    }, [appliedExceptions]);

    useEffect(() => {
        if (changeCount === 0)
            return;
        save();
    }, [debouncedSave]);

    if (!StringUtil.containsAny(permittedActions, PermittedOrderActionsEnum.ViewExceptions, PermittedOrderActionsEnum.ApplyExceptions))
        return null;

    //#region Save
    async function save() {
        const optionsToSave = appliedExceptions.filter(o => o.ToSave === true && o.Deleting !== true);

        if (optionsToSave.length === 0)
            return;

        setSaving(true);
        const resp = await PolicyApiClient.savePolicyExceptions(po.SecureId, optionsToSave);
        setSaving(false);
        setIsErrorOnSave(false);

        if (resp.hasError) {
            setActionMsgType('error');
            setActionMsg(resp.errorMessage);

            if (!resp.hasValidationResult) {
                return;
            }
        }

        const respData = resp.data;
        const validationMap = ValidationHelper.getValidationMap(respData);
        setValidationsMap(validationMap);
        if (!StringUtil.isNullOrEmpty(validationMap)) {
            setIsErrorOnSave(true);
            return;
        }

        setAppliedExceptions(prevState => prevState.map(item => {
            optionsToSave.forEach(savedItem => {
                if (isEqual(item, savedItem)) {
                    item.ToSave = false;
                    item.IsNew = false;
                }
            });
            return item;
        }));
    }

    function setSaving(val) {
        setAppliedExceptions(prevState => prevState.map(item => {
            if (item.ToSave === true && item.Deleting !== true)
                item.Saving = val;
            return item;
        }));
    }

    function setIsErrorOnSave(val) {
        setAppliedExceptions(prevState => prevState.map(item => {
            if (item.ToSave === true && item.Deleting !== true)
                item.IsError = val;
            return item;
        }));
    }

    function onDescriptionChange(value, secureId) {
        setAppliedExceptions(prevState => prevState.map(item => {
            const _item = { ...item };
            if (StringUtil.isEqual(secureId, item.SecureId)) {
                _item.Description = value;
                _item.AlreadyApplied = false;
                _item.ToSave = true;
            }
            return _item;
        }));
        setChangeCount(prevState => prevState + 1);
    }
    //#endregion Save

    //#region Delete
    async function onDeleteException(secureId) {
        const itemToDelete = appliedExceptions.find(o => StringUtil.isEqual(secureId, o.SecureId));

        if (!itemToDelete.IsNew) {
            setDeleting(secureId, true);
            const resp = await PolicyApiClient.deletePolicyException(po.SecureId, itemToDelete);
            setDeleting(secureId, false);
            setIsErrorOnDelete(secureId, false);

            if (resp.hasError) {
                setActionMsgType('error');
                setActionMsg(resp.errorMessage);
                setIsErrorOnDelete(secureId, true);

                if (!resp.hasValidationResult) {
                    return;
                }
            }

            const respData = resp.data;
            const validationMap = ValidationHelper.getValidationMap(respData);
            setValidationsMap(validationMap);
            if (!StringUtil.isNullOrEmpty(validationMap)) {
                setIsErrorOnDelete(secureId, true);
                return;
            }
        }

        setAppliedExceptions(prevState => prevState.filter(o => !StringUtil.isEqual(secureId, o.SecureId)));
    }

    function setDeleting(secureId, val) {
        setAppliedExceptions(prevState => prevState.map(item => {
            if (StringUtil.isEqual(secureId, item.SecureId))
                item.Deleting = val;

            return item;
        }));
    }

    function setIsErrorOnDelete(secureId, val) {
        setAppliedExceptions(prevState => prevState.map(item => {
            if (StringUtil.isEqual(secureId, item.SecureId))
                item.IsError = val;
            return item;
        }));
    }
    //#endregion Delete

    //#region Error Handling
    const getErrMsg = (index) => {
        if (StringUtil.isNullOrEmpty(validationsMap))
            return null;

        const secureId = appliedExceptions.find((item, i) => i === index).SecureId;

        if (secureId) {
            const items = appliedExceptions.filter(item => item.IsError === true);
            let e = null;
            items.forEach((item, index) => {
                if (item.SecureId === secureId)
                    e = validationsMap[`exceptions[${index}].description`];
            });

            if (StringUtil.isNullOrEmpty(e))
                return null;

            if (!StringUtil.isEqual(e.Severity, 'error'))
                return null;

            return e.ErrorMessage;
        }

        return null;
    }

    function isError(index) {
        return appliedExceptions.some((o, i) => o.IsError === true && i === index);
    }
    //#endregion Error Handling

    //#region Props and HTML   

    const canChangeExceptions = UserHelper.hasPermission(userInfo, UserPermissions.change_exceptions);

    const searchExceptionProps = {
        po: po,
        appliedExceptions: appliedExceptions,
        setAppliedExceptions: setAppliedExceptions,
        setChangeCount: setChangeCount,
        canChangeExceptions: canChangeExceptions
    };

    const exceptionProps = {
        isError: isError,
        getErrMsg: getErrMsg,
        onDescriptionChange: onDescriptionChange,
        onDeleteException: onDeleteException,
        canChangeExceptions: canChangeExceptions
    };

    return <SectionHeader section="exceptions" title="Exceptions" collapsible={!BrowserHelper.isIE11()} iconElem={<GavelIcon className={classes.attention} />}>
        <SearchException {...searchExceptionProps} />
        {/* Applied exceptions */}
        {appliedExceptions.map((e, index) =>
            <Exception
                key={e.SecureId}
                ex={e}
                index={index}
                exRef={refs[index]}
                {...exceptionProps}
            />)}
    </SectionHeader>;
    //#endregion Props and HTML 
}

export default ExceptionsView;