import * as actions from './AdminActions';
import { createReducer } from '@reduxjs/toolkit';
import StringUtil from 'Util/StringUtil';

const initialState = {
    users_list: [],
    users_list_loading: true,
    users_list_loading_error: null,
    users_list_total_records: 0,
    users_list_total_pages: 0,
    user_status_change_busy: {}
};

const UserReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(actions.set_users_list, (state, action) => {
            const withPagination = action.payload;

            const records = withPagination.Records ?? [];
            const totalRecords = withPagination.TotalRecords ?? 0;
            const numPages = withPagination.NumPages ?? 1;

            state.users_list = records;
            state.users_list_total_records = totalRecords;
            state.users_list_total_pages = numPages;
        })
        .addCase(actions.set_users_list_loading, (state, action) => {
            state.users_list_loading = action.payload;
        })
        .addCase(actions.set_users_list_loading_error, (state, action) => {
            state.users_list_loading_error = action.payload;
        })
        .addCase(actions.update_user_status_change_busy, (state, action) => {
            const { Id, IsBusy } = action.payload;

            state.user_status_change_busy[Id] = IsBusy;
        })
        .addCase(actions.clear_user_status_change_busy, (state, action) => {
            state.user_status_change_busy = {};
        })
        .addCase(actions.update_user_status, (state, action) => {
            const { Id, UserStatus, UpdatedOn } = action.payload;
            
            const userRecord = state.users_list.find(x => StringUtil.isEqual(x.SecureId, Id));

            if(!userRecord) {
                return;
            }

            userRecord.Status = UserStatus;

            if(UpdatedOn) {
                userRecord.LastUpdatedOn = UpdatedOn;
            }
        })
        .addDefaultCase((state, action) => { })
});

export default UserReducer;