import { TableRow } from '@mui/material';
import { CollectionTypeEnum } from 'Model/PolicyOrder';
import React from "react";
import MortgageInfo from './MortgageInfo';

function Mortgages(props) {
    const { itemsList, questionParams } = props;

    if (!itemsList)
        return null;

    const collectionName = CollectionTypeEnum.Mortgages;

    return itemsList.map((item, index) =>
        <TableRow key={item.Id}>
            <MortgageInfo
                idx={index}
                collectionName={collectionName}
                item={item}
                questionParams={questionParams} />
        </TableRow>
    );
}
export default Mortgages;