import React, { useState } from 'react';
import { FormControl, FormGroup, Checkbox, FormControlLabel, FormHelperText } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { StringUtil } from 'Util/Helpers';
import SyntheticEvent from './SyntheticEvent';

const useStyles = makeStyles(theme => ({
    error: {
        color: theme.palette.error.main
    }
}));

function CheckboxStateful(props) {
    const { name, onChange, label, classN, defaultValue, readOnly } = props;
    let { checkedVal } = props;

    if (!checkedVal) {
        checkedVal = 'yes';
    }
    
    const isCheckedDefault = StringUtil.isEqual(defaultValue, checkedVal);

    const [isChecked, setIsChecked] = useState(isCheckedDefault);

    function handleInputChange(event) {
         

        if(readOnly) {
            return;
        }

        const checked = event.target.checked;

        const val = checked ? checkedVal : null;

        setIsChecked(checked); // remember state, re-render

        const ev = new SyntheticEvent(name, val);

        onChange(ev);
    }
    
    return (
        <FormControlLabel
            control={<Checkbox name={name} checked={isChecked} onChange={handleInputChange} className={classN} />}
            label={label}
        />
    );
}

function SingleCheckBox(props) {
    const { error, name, label, defaultValue, readOnly, helperText, onChange, checkedVal } = props;

    const classes = useStyles();

    const classN = error ? classes.error : null;

    return (
        <FormControl error={error}>
            <FormGroup>
                <CheckboxStateful name={name} onChange={onChange} classN={classN} label={label} defaultValue={defaultValue} checkedVal={checkedVal} readOnly={readOnly}  />
            </FormGroup>
            {!StringUtil.isNullOrEmpty(helperText) &&
                <FormHelperText style={{ marginBottom: '0.8rem' }}>{helperText}</FormHelperText>
            }
        </FormControl>
    );
}

export default SingleCheckBox;