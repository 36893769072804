import Logger from 'Util/Logger';
import { StringUtil, WebStorageHelper, UserHelper } from 'Util/Helpers';
import * as UserRoles from 'Model/UserRoles';
import * as OrderStatusEnum from 'Model/OrderStatus';

class DealsListFilterSettings
{
    logger;
    
    constructor()
    {
        this.logger = new Logger(DealsListFilterSettings.name);
    }
    
    _resolveUserDefault(userInfo) {                
        const now = new Date();


        const goBack = new Date(now);
        goBack.setDate(goBack.getDay() - 365); // go one year back

        const future = new Date(now);
        future.setDate(future.getDay() + 365); // when buying from builder u may have closing date 1 yr into future. possible.

        // hard-code for now. Same value for all users.
        const settings = {
            Page: 1,
            PageSize: 15,
            OrderBy: 'LastUpdatedOn',
            SortDirection: 'desc',
            ClosingDate: [null, null],
            LastUpdatedOn: [goBack, now]
        };

        if(userInfo && StringUtil.isEither(userInfo.Role, UserRoles.Underwriter, UserRoles.UnderwritingManager)) {
            settings.Status = [ OrderStatusEnum.UnderReview ];
        }

        return settings;
    }

    getLastUsedFilter(userInfo) {
        let lastFilter;
        
        const lastFilterJson = WebStorageHelper.getItem('status_page', 'last_filter');

        if (!StringUtil.isNullOrEmpty(lastFilterJson)) {
            try {
                lastFilter = JSON.parse(lastFilterJson);
                
                const userDefaultFilter = this._resolveUserDefault(userInfo);

                if(!lastFilter.ClosingDateChanged) {
                    lastFilter.ClosingDate = userDefaultFilter.ClosingDate;
                }
                if(!lastFilter.LastUpdatedOnChanged)
                {
                    lastFilter.LastUpdatedOn = userDefaultFilter.LastUpdatedOn;
                }
            }
            catch (err) {
                this.logger.error('error deserializing last filter', err);
            }
        }
        else
        {
            lastFilter = this._resolveUserDefault(userInfo);
        }

        return lastFilter;
    }

    saveLastUsedFilter(filter) {        
        WebStorageHelper.setItem('status_page', 'last_filter', JSON.stringify(filter));
    }
}

const instance = new DealsListFilterSettings();

export { instance as DealsListFilterSettings }