import { Button } from '@mui/material';
import { green, grey, purple, red } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import { Gavel } from '@mui/icons-material';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import { createSelector } from '@reduxjs/toolkit';
import { SectionHeader } from 'Components/Questionary';
import { PermittedOrderActionsEnum } from 'Model/PolicyOrder';
import * as OrderStatusEnum from 'Model/OrderStatus';
import * as UserPermissions from 'Model/UserPermissions';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as UserActions from 'User/State/UserActions';
import { StringUtil, UserHelper } from 'Util/Helpers';

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: 'xs',
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        }
    },
    btnMarkForReview: {
        margin: 2,
        backgroundColor: purple[900],
        color: grey[50],
        '&:hover': {
            background: purple[900],
            color: grey[50]
        }
    },
    btnApprove: {
        margin: 2,
        backgroundColor: green[500],
        color: grey[50],
        '&:hover': {
            background: green[900],
            color: grey[50]
        }
    },
    btnDecline: {
        margin: 2,
        backgroundColor: red[500],
        color: grey[50],
        '&:hover': {
            background: red[900],
            color: grey[50]
        }
    },
    pointer: {
        cursor: 'default'
    },
    decisionComments: {
        width: '100%',
        paddingRight: theme.spacing(3),
        marginBottom: theme.spacing(3)
    },
    icon: {
        color: grey[50]
    }
}));

function createOrderVolatileDataSelector() {
    return createSelector(
        state => state.order.orderVolatileData,
        (_, propName) => propName,
        (x, propName) => x[propName]
    );
}

function createIsSavingSelector() {
    return createSelector(
        state => state.order.isSaving,
        x => x
    );
}

function PolicyDecisionActions(props) {
    const { changeOrderStatus } = props;

    const classes = useStyles();

    const selectOrderVolatileData = useMemo(
        createOrderVolatileDataSelector,
        []
    );

    const selectUserInfo = useMemo(
        UserActions.createUserInfoSelector,
        []
    );

    const selectIsBusy = useMemo(
        createIsSavingSelector,
        []
    );

    const isBusy = useSelector(x => selectIsBusy(x)); // this is used in many places, so the control will re-render if busy ...

    const userInfo = useSelector(x => selectUserInfo(x));

    const permittedActions = useSelector(x => selectOrderVolatileData(x, "PermittedActions"));

    const allowApprove = StringUtil.contains(permittedActions, PermittedOrderActionsEnum.Approve);
    const allowDecline = StringUtil.contains(permittedActions, PermittedOrderActionsEnum.Decline);
    const allowMarkForReview = StringUtil.contains(permittedActions, PermittedOrderActionsEnum.MarkForReview);
    const allowRequestForUserAction = StringUtil.contains(permittedActions, PermittedOrderActionsEnum.RequestUserAction);

    if (!allowApprove && !allowDecline) {
        return null;
    }

    if (!UserHelper.hasPermission(userInfo, UserPermissions.decision_deal)) {
        return null;
    }

    return (
        <React.Fragment>
            <SectionHeader title="Decision" />
            <div className={classes.container}>
                <div>
                    <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        disabled={!allowMarkForReview || isBusy}
                        className={classes.btnMarkForReview}
                        startIcon={<Gavel className={classes.icon} />}
                        onClick={(event) => changeOrderStatus(event, OrderStatusEnum.UnderReview)}>Under Review</Button>
                    <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        startIcon={<TouchAppIcon className={classes.icon} />}
                        disabled={!allowRequestForUserAction || isBusy}
                        onClick={(event) => changeOrderStatus(event, OrderStatusEnum.UserActionRequired)}>User Action Required</Button>
                    <Button
                        label="Approve"
                        startIcon={<ThumbUpAltIcon className={classes.icon} />}
                        disabled={!allowApprove || isBusy}
                        className={classes.btnApprove}
                        onClick={(event) => changeOrderStatus(event, OrderStatusEnum.Issued)}>Approve</Button>
                    <Button
                        label="Decline"
                        endIcon={<ThumbDownAltIcon className={classes.icon} />}
                        disabled={!allowDecline || isBusy}
                        className={classes.btnDecline}
                        onClick={(event) => changeOrderStatus(event, OrderStatusEnum.Declined)}>Decline</Button>
                </div>
            </div>
        </React.Fragment>
    );
}

export default PolicyDecisionActions;