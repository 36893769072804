import React from 'react';
import { Chip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

const useStyles = makeStyles(theme => ({
    savingChip: {
        color: 'white',
        borderColor: 'white'
    }
}));

const selectIsSaving = createSelector(
    state => state.order.isSaving,
    x => x
);

function SavingIndicator(props) {
    const classes = useStyles();

    const isSaving = useSelector(selectIsSaving);

    if (!isSaving) {
        return null;
    }

    return (
        <React.Fragment>&nbsp;<Chip variant="outlined" size="small" label="Saving..." className={classes.savingChip} /></React.Fragment>
    );
}

export default SavingIndicator;