import React from 'react';
import { RadioGroup, FormControl, FormControlLabel, Radio, FormHelperText } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { StringUtil } from 'Util/Helpers';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  error: {
    color: theme.palette.error.main
  },
  readOnlyRadioPrimary: {
    '&.Mui-disabled': {
      color: 'inherit'
    },
      
    '.MuiFormControlLabel-label.Mui-disabled': {
      color: 'inherit'
    }
  },
  readOnlyFormControlLabel: {
    '&.Mui-disabled': {
      color: 'inherit'
    },
    '&.MuiFormControlLabel-label.Mui-disabled': {
      color: 'inherit'
    }
  }
}));

function OptionGroup(props) {
  const { name, fieldName, autoFocus, fieldStateResolver, defaultValue, error, helperText, readOnly, disabled, ...other } = props;

  const classes = useStyles();

  let opts = props.opts;

  if (StringUtil.isNullOrEmpty(opts))
    opts = [];

  const classN = error ? classes.error : null;

  let radioGroupProps = { ...other };

  if (readOnly) {
    // radioGroupProps["value"] = defaultValue;
    
    delete radioGroupProps.onChange; // this will prevent firing onChange event ....
  }

  radioGroupProps.defaultValue = defaultValue;

  const readOnlyPrimaryRadioClassN = readOnly ? classes.readOnlyRadioPrimary : null;
  const formControlLabelClassN = readOnly? classes.readOnlyFormControlLabel : null;

  // this is an unctonrolled component...
  return (
    <FormControl error={error}>
      <RadioGroup aria-label={name} name={name} row className={classN} {...radioGroupProps}>
        {opts.map((opt) => {
          return <FormControlLabel
            key={opt.Id}
            value={opt.Id}
            control={<Radio
              color="primary"
              disabled={readOnly}
              className={readOnlyPrimaryRadioClassN} 
              autoFocus={autoFocus} />}
            label={opt.Label}
            labelPlacement="end"
            classes={{
              label: formControlLabelClassN
            }}
          />
        })}
      </RadioGroup>
      {!StringUtil.isNullOrEmpty(helperText) &&
        <FormHelperText style={{ marginBottom: '0.8rem' }}>{helperText}</FormHelperText>
      }
    </FormControl>
  );
}

export default OptionGroup;