import React, { useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash/throttle';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import Logger from 'Util/Logger';
import { MenuList, MenuItem } from '@mui/material';

const logger = new Logger(NavMenu2.name);

const useStyles = makeStyles(theme => ({
    menuItem: {
        color: theme.palette.text.secondary,
        borderBottom: '4px solid transparent',
        boxSizing: 'content-box',
        '&:hover': {
            borderBottom: `4px solid ${theme.palette.grey[400]}`,
        },
        '&$active,&:acative': {
            borderBottom: `4px solid ${theme.palette.grey[600]}`,
        },
    },
    menu: {
        display: 'flex',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        backgroundColor: 'white',
        paddingTop: 3,
        paddingBottom: 0
    },
    active: {
        borderBottom: `4px solid ${theme.palette.grey[600]}`,
        color: theme.palette.text.primary
    }
}));

function getItemsClient(headings) {
    const itemsWithNode = [];

    headings.forEach((item) => {
        itemsWithNode.push({
            ...item,
            node: document.getElementById(item.id),
        });
    });

    return itemsWithNode;
}

const noop = () => { };

function useThrottledOnScroll(callback, delay) {
    const throttledCallback = React.useMemo(() => (callback ? throttle(callback, delay) : noop), [
        callback,
        delay,
    ]);

    React.useEffect(() => {
        if (throttledCallback === noop) {
            return undefined;
        }

        window.addEventListener('scroll', throttledCallback);
        return () => {
            window.removeEventListener('scroll', throttledCallback);
            throttledCallback.cancel();
        };
    }, [throttledCallback]);
}

export default function NavMenu2(props) {
    const { items } = props;
    const classes = useStyles();

    const itemsWithNodeRef = useRef([]);

    useEffect(() => {
        itemsWithNodeRef.current = getItemsClient(items);
    }, [items]);

    const defaultActiveId = items[0].id;

    const [activeState, setActiveState] = useState(defaultActiveId);
    const clickedRef = useRef(false);
    const unsetClickedRef = useRef(null);
    const findActiveIndex = useCallback(() => {
        // Don't set the active index based on scroll if a link was just clicked
        if (clickedRef.current) {
            return;
        }

        let active;
        for (let i = itemsWithNodeRef.current.length - 1; i >= 0; i -= 1) {
            // No id if we're near the top of the page
            if (document.documentElement.scrollTop < 80) {
                active = { id: defaultActiveId };
                break;
            }

            const item = itemsWithNodeRef.current[i];

            if (!item.node) {
                logger.error(`Missing node on the item ${JSON.stringify(item, null, 2)}`);
            }

            const sectionEagerCutOff = 50;

            if (
                item.node &&
                item.node.offsetTop <
                document.documentElement.scrollTop - sectionEagerCutOff + document.documentElement.clientHeight / 8
            ) {
                active = item;
                break;
            }
        }

        if (active && activeState !== active.id) {
            setActiveState(active.id);
        }
    }, [activeState]);

    const frames = 10;
    const delay = Math.round(1000 / 60 * frames); // how many fames to delay by, at 60hz

    // useThrottledOnScroll(items.length > 0 ? findActiveIndex : null, delay); // IGOR IE 11 FOR HENRY

    const handleClick = (id) => (event) => {
        if (
            event.defaultPrevented ||
            event.button !== 0 || // ignore everything but left-click
            event.metaKey ||
            event.ctrlKey ||
            event.altKey ||
            event.shiftKey
        ) {
            return;
        }

        const node = document.getElementById(id);

        if (!node)
            return;

        node.scrollIntoView({ behavior: 'smooth', block: 'start' });

        // Used to disable findActiveIndex if the page scrolls due to a click
        clickedRef.current = true;
        unsetClickedRef.current = setTimeout(() => {
            clickedRef.current = false;
        }, 1000);

        if (activeState !== id) {
            setActiveState(id);
        }
    };

    React.useEffect(
        () => () => {
            clearTimeout(unsetClickedRef.current);
        },
        [],
    );

    const itemLink = (item) => (
        <MenuItem key={item.text}
            className={clsx(
                classes.menuItem,
                activeState === item.id ? classes.active : undefined,
            )}
            onClick={handleClick(item.id)}
        >
            {item.text}
        </MenuItem>
    );

    return (
        <MenuList className={classes.menu} area-label="navigation menu">
            {items.map((item) => (
                itemLink(item)
            ))}
        </MenuList>
    );
}

NavMenu2.propTypes = {
    items: PropTypes.array.isRequired,
};