
import { createTheme,  adaptV4Theme } from '@mui/material/styles';

const theme = createTheme(adaptV4Theme({
  
    props: {
     MuiSelect:{
       variant: 'standard',
     },
     MuiTextField: {
       variant: 'standard',
     },
   },
 
   palette: {
     primary: {
       main: '#165c7d', /* Pantone 7700 C*/
       mainSemi: '#248db0',
       mainLight: '#b7e6ec',
       mainExtremeLight: '#e3f5f7',
     },
     defButton: {
       main:  '#616161',
       light: '#bdbdbd',
       dark: '#424242',
       contrastText: '#fff',
     }
   },

   typography: {
     fontSize: 12,
     caption: {
       fontSize: '0.8rem'
     }
   },
   
   overrides: {
     MuiTooltip: {
       tooltip: {
         fontSize: "1em"
       }
     }
   }
 }));



export function AppTheme() {
    return theme;
}
