import StringUtil from './StringUtil';
import { isEmpty } from "lodash";
import { CollectionTypeEnum } from 'Model/PolicyOrder';

class QuestionaryHelper {
  getQuestion(questionary, id) {
    if (StringUtil.isNullOrEmpty(id)) {
      return null;
    }

    if (StringUtil.isNullOrEmpty(questionary) || StringUtil.isNullOrEmpty(questionary.Questions) || questionary.Questions.length === 0) {
      return null;
    }

    const questions = questionary.Questions.filter((q) => {
      return StringUtil.isEqual(id, q.Id);
    });

    if (questions.length === 0) {
      return null;
    }

    return questions[0];
  }

  getStipulation(questionary, id) {
    if (StringUtil.isNullOrEmpty(questionary) || StringUtil.isNullOrEmpty(questionary.Stipulations) || questionary.Stipulations.length === 0) {
      return null;
    }

    const stips = questionary.Stipulations.filter((q) => {
      return StringUtil.isEqual(id, q.Id);
    });

    if (stips.length === 0) {
      return null;
    }

    return stips[0];
  }

  getStipulations(questionary) {
    if (StringUtil.isNullOrEmpty(questionary) || StringUtil.isNullOrEmpty(questionary.Stipulations) || questionary.Stipulations.length === 0) {
      return [];
    }

    return questionary.Stipulations;
  }

  houseNestedAnswer(fieldName, answer, container) {
    if (StringUtil.isNullOrEmpty(fieldName) || StringUtil.isNullOrEmpty(container)) {
      return false;
    }

    if (!StringUtil.contains(fieldName, '.')) {
      return false;
    }

    const parts = fieldName.split('.');

    // assuming 1 level deep!

    const collectionName = parts[0];
    const itemId = parts[1];
    const itemFieldName = parts[2];

    // if (StringUtil.isEqual(itemFieldName, 'IsDeleted')) {
    if (StringUtil.contains(itemFieldName, 'IsDeleted')) {
      if (!StringUtil.isNullOrEmpty(container[collectionName])) {
        // remove item from collection if it has been flagged for removal

        const filtered = container[collectionName].filter(x => !StringUtil.isEqual(x.Id, itemId));

        container[collectionName] = filtered;
      }
      return true;
    }

    if (StringUtil.isNullOrEmpty(container[collectionName])) {
      container[collectionName] = [];
    }

    const index = container[collectionName].findIndex(x => StringUtil.isEqual(x.Id, itemId));

    if (index > -1) {
      container[collectionName][index][itemFieldName] = answer;
    }
    else {
      // add new

      const item = {
        Id: itemId
      };

      item[itemFieldName] = answer;

      container[collectionName].push(item);
    }

    return true;
  }

  createIndexer(collectionName, item) {
    const indexer = `${collectionName}.${item.Id}.`;

    return indexer;
  }

  _setAnswerInitialValue(container, q, fieldName, answersMap, isCancelled, nestingPath, itemId) {
    const qId = q.Id;

    if (StringUtil.isNullOrEmpty(fieldName)) {
      fieldName = qId;
    }

    const multiPart = !StringUtil.isNullOrEmpty(q.Parts) && q.Parts.length > 0;

    if (!multiPart) {
      const answer = container[qId];

      if (!StringUtil.isNullOrEmpty(answer)) {
        answersMap.set(fieldName, answer);
      }

      return;
    }

    for (const part of q.Parts) {

      let qIdPart = qId + part;

      const answer = container[qIdPart];

      if (!isCancelled && !StringUtil.isNullOrEmpty(answer)) {
        if (!StringUtil.isNullOrEmpty(nestingPath) && !StringUtil.isNullOrEmpty(itemId))
          qIdPart = `${nestingPath}.${itemId}.${qIdPart}`;
        
        answersMap.set(qIdPart, answer);
      }
    }
  }

  setAnswerInitialValue(container, q, answersMap, isCancelled) {
    const qId = q.Id;

    if (StringUtil.isNullOrEmpty(q.NestingPath))
      return this._setAnswerInitialValue(container, q, qId, answersMap, isCancelled, null);

    const collection = container[q.NestingPath]; // assuming 1 level deep

    if (StringUtil.isNullOrEmpty(collection) || collection.length === 0) {
      return; // nothing in the collection, return
    }

    for (const item of collection) {
      const indexedId = `${q.NestingPath}.${item.Id}.${qId}`; // assuming 1 level deep

      // instead of passing the container, we pass the item of the collection...
      this._setAnswerInitialValue(item, q, indexedId, answersMap, isCancelled, q.NestingPath, item.Id);
    }
  }

  getIndexer(fieldName) {
    if (StringUtil.isNullOrEmpty(fieldName)) {
      return '';
    }

    if (!StringUtil.contains(fieldName, '.')) {
      return ''; // not indexed
    }

    const parts = fieldName.split('.');

    parts.pop(); // remove last

    if (parts.length === 0) {
      return '';
    }

    const indexer = parts.join('.') + '.';

    return indexer;
  }

  getId(fieldName) {
    if (StringUtil.isNullOrEmpty(fieldName)) {
      return '';
    }

    if (!StringUtil.contains(fieldName, '.')) {
      return ''; // not indexed
    }

    const parts = fieldName.split('.');

    parts.pop(); // remove last

    if (parts.length === 0) {
      return '';
    }

    const id = parts.pop();

    return id;
  }

  getCollectionName(fieldName) {
    if (StringUtil.isNullOrEmpty(fieldName)) {
      return '';
    }

    if (!StringUtil.contains(fieldName, '.')) {
      return ''; // not indexed
    }

    const parts = fieldName.split('.');

    if (parts.length === 0) {
      return '';
    }

    const collectionName = parts[0];

    return collectionName;
  }
}

const instance = new QuestionaryHelper();

export { instance as QuestionaryHelper };