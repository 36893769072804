import React from 'react';
import { TagsEnum } from 'Model/PolicyOrder';
import MultiSelectDropDown from 'Components/MultiSelectDropDown';
import { DealsListFilterSettings } from './DealsListFilterSettings';
import { StringUtil } from 'Util/Helpers';

const items = Object.values(TagsEnum);

function resolveInitialValue()
{
    const lastFilter = DealsListFilterSettings.getLastUsedFilter();

    if(!StringUtil.isNullOrEmpty(lastFilter)) {
        return lastFilter.Tags;
    }

    return undefined;
}


function TagsFilterDL(props) {
    return (
        <MultiSelectDropDown {...props} items={items} resolveInitialValue={resolveInitialValue} />
    );
}

export default TagsFilterDL;