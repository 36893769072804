import React, { useState } from 'react';
import { StringUtil } from 'Util/Helpers';
import { FormControl, FormHelperText, InputLabel } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import SyntheticEvent from 'Components/SyntheticEvent';
import { cloneDeep } from 'lodash';

export default function DateControl(props) {
    const { q, fieldName, onChange, defaultValue, readOnly, error, helperText, ...other } = props;
    // debugger;
    let minDate, maxDate;

    let initialValue = defaultValue;

    if (StringUtil.isNullEmptyOrWhiteSpace(initialValue)) {
        initialValue = null;
    }

    const [value, setValue] = useState(initialValue);

    if (!StringUtil.isNullOrEmpty(q.MinDateInDays)) {
        const d = new Date();
        d.setDate(d.getDate() - q.MinDateInDays);

        minDate = d;
    }

    if (!StringUtil.isNullOrEmpty(q.MaxDateInDays)) {
        const d = new Date();
        d.setDate(d.getDate() + q.MaxDateInDays);

        maxDate = d;
    }

    function invokeOnChange(val) {
        let _val = cloneDeep(val);
        setValue(_val);

        if (val != null) {
            const d = val._d;
            const t = new Date(1, 0, 1, 8);

            val = new Date(d.getFullYear(),
                d.getMonth(),
                d.getDate(),
                t.getHours(),
                t.getMinutes(),
                t.getSeconds(),
                t.getMilliseconds());
        }

        if (!onChange) {
            return;
        }

        const ev = new SyntheticEvent(fieldName, val);

        onChange(ev);
    }

    // this has to be a controlled component
    return <FormControl error={error}><DatePicker
        autoOk={true}
        readOnly={readOnly}
        clearable={true}
        onChange={invokeOnChange}
        value={moment(value)}
        minDate={moment(minDate)}
        maxDate={moment(maxDate)}
        format='LL'
        {...other}
    />

        {!StringUtil.isNullOrEmpty(helperText) &&
            <FormHelperText style={{ marginBottom: '0.8rem' }}>{helperText}</FormHelperText>
        }

    </FormControl>
}