import React from 'react';

function SupplementaryDbMatch(props) {  
    return <React.Fragment>        
        First Name: {props.Source["FIRST.NAME"]} <br />
        Last Name: {props.Source["LAST.NAME"]} <br />
        Corporate Name: {props.Source["CORPORATE.NAME"]} <br />
        <br />

    </React.Fragment>
}

export default SupplementaryDbMatch;
