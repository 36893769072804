import React from 'react';
import { Button, Box } from '@mui/material';
import { AuthService } from 'Services/AuthService';
import ErrorSnackbar from './ErrorSnackbar';
import * as AppErrors from 'Model/AppErrors';

function NotAuthorized(props) {
    return (
        <React.Fragment>
            <ErrorSnackbar variant="error" message="user not authorized" errorCode={AppErrors.code28} centered={true} />
            <Box mt={1} display="flex" flexDirection="row-reverse">
                <Button
                    variant="contained"
                    onClick={async () => { await AuthService.authClient.signOut() }}
                >
                    Logout
                </Button>
            </Box>
        </React.Fragment>
    );
}

export default NotAuthorized;