import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux'
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import * as PolicyThunks from 'Policy/State/PolicyThunks';
import { isEmpty, cloneDeep } from 'lodash';
import { StringUtil } from 'Util/Helpers';
import { QuestionaryHelper } from 'Util/QuestionaryHelper';

function createReloadQuestionaryEventSelector() {
    return createSelector(
        state => state.order.reloadQuestionaryEvent,
        x => x
    );
}

function QuestionaryReloader(props) {    
    const { getInitialAnswer } = props;
    
    const selectReloadQuestionaryEvent = useMemo(
        createReloadQuestionaryEventSelector,
        []
    );

    const reloadQuestionaryEvent = useSelector(x => selectReloadQuestionaryEvent(x));

    const dispatch = useDispatch();
    
    useEffect(() => {
        let isCancelled = false;

        function onUnload() {
            isCancelled = true;
        }

        if (reloadQuestionaryEvent && !isEmpty(reloadQuestionaryEvent)) {
            const ev = cloneDeep(reloadQuestionaryEvent);
            const indexer = QuestionaryHelper.getIndexer(reloadQuestionaryEvent.TriggeringField);
            
            // if we just loaded the deal and only changed one of the fields, we need to find the value for the other ...
            if (StringUtil.isNullOrEmpty(ev.TransactionType)) {
                ev.TransactionType = getInitialAnswer(indexer + "TransactionType");
            }
            
            if (StringUtil.isNullOrEmpty(ev.PropertyType)) {
                ev.PropertyType = getInitialAnswer(indexer + "PropertyType");
            }

            if (StringUtil.isNullOrEmpty(ev.IsEnrolledInNHP)) {
                ev.IsEnrolledInNHP = getInitialAnswer(indexer + "IsEnrolledInNHP");
            }

            if (StringUtil.isNullOrEmpty(ev.NHPCode)) {
                ev.NHPCode = getInitialAnswer(indexer + "NHPCode");
            }

            if (StringUtil.isNullOrEmpty(ev.PropertyAddressProvince)){
                ev.PropertyAddressProvince = getInitialAnswer(indexer + "PropertyAddressProvince");
            }

            if (StringUtil.isNullOrEmpty(ev.AddExistingOwnerPolicy)){
                ev.AddExistingOwnerPolicy = getInitialAnswer(indexer + "AddExistingOwnerPolicy");
            }

            if (StringUtil.isNullOrEmpty(ev.ExistingOwnerHasTIPolicy)){
                ev.ExistingOwnerHasTIPolicy = getInitialAnswer(indexer + "ExistingOwnerHasTIPolicy");
            }
            
            dispatch(PolicyThunks.loadQuestionary(ev));
        }

        return onUnload;
    }, [reloadQuestionaryEvent]);

    return null;
}

export default QuestionaryReloader;