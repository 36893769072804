import { Box, Button, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Question } from 'Components/Questionary';
import Conditional from 'NewHomeProjects/Conditional';
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { StringUtil } from 'Util/Helpers';
import { QuestionaryHelper } from 'Util/QuestionaryHelper';
import ExceptionProperties from './ExceptionProperties';

const useStyles = makeStyles((theme) => ({
}));

function RemoveButton(props) {
    const { isFirst, item, remove } = props;

    return (
        <Tooltip title="Remove">
            <span>
                <Button
                    type="button"
                    size="small"
                    startIcon={<DeleteIcon />}
                    disabled={isFirst}
                    onClick={() => { remove(item) }}
                >
                    Remove Exception
                </Button>
            </span>
        </Tooltip>
    );
}

function AddButton(props) {
    const { add } = props;

    return (
        <Tooltip title="Add Exception">
            <span>
                <Button
                    type="button"
                    size="small"
                    startIcon={<AddIcon />}
                    onClick={add}
                >
                    Add Exception
                </Button>
            </span>
        </Tooltip>
    );
}

function ExceptionInfo(props) {
    const { item, idx, questionParams,  add, remove, collectionName, getInitialAnswer } = props;

    let qParams = { ...questionParams };

    const exceptionRange = useSelector(x => x.project.exceptionRange);

    qParams.indexer = QuestionaryHelper.createIndexer(collectionName, item);

    let properties = null;

    if (exceptionRange && exceptionRange.length > 0)
        properties = exceptionRange.filter(x => StringUtil.isEqual(x.ExceptionId, item.Id));

    const conditionalProps = {
        indexer: qParams.indexer,
        getInitialAnswer: getInitialAnswer
    };

    return (
        <Fragment>
            <Box mt={1.5} pt={1.5} pb={1.5} pl={1.5} pr={1.5} border={1} borderColor="grey.400" borderRadius="5px">
                <Question qId="Code" {...qParams} txtWidth={25} />
                <Question qId="Topic" {...qParams} txtWidth={50} />
                <Question qId="Description" {...qParams} multiline />
                <Question qId="PolicyType" {...qParams} />
                <Question qId="FlagForManualReview" {...qParams} />
                <Question qId="ExceptionAppliesTo" {...qParams} />

                <Conditional upon="ExceptionAppliesTo" when={(x) => StringUtil.isEqual(x, "RangeOfProperties")} {...conditionalProps}>
                    <Question qId="Range" {...qParams} optional>
                        <ExceptionProperties
                            exceptionId={item.Id}
                            questionParams={questionParams}
                            getInitialAnswer={getInitialAnswer}
                            itemsList={properties} />
                    </Question>
                </Conditional>
            </Box>

            <AddButton add={add} getInitialAnswer={getInitialAnswer} />
            <RemoveButton isFirst={false} item={item} remove={remove} getInitialAnswer={getInitialAnswer} />
        </Fragment>
    );
}

export default ExceptionInfo;