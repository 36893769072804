import { StringUtil } from "Util/Helpers";
import Logger from 'Util/Logger';

const env = process.env.REACT_APP_ENV;
const appSettings = [];

const authConfigNonProdOkta = {
    issuer: 'https://lawpro.oktapreview.com/oauth2/default',
    client_id: '0oa4v5u3w3UdQHvfq1d6'
};

const authConfigProdOkta = {
    issuer: 'https://lawpro.okta.com/oauth2/default',
    client_id: '0oanmpk5oe43nGN5y5d6'
};

appSettings["local"] = {
    urls: {
        kyc: "https://treeforttech.com/kyc2092012516122119/",
        policy: "http://localhost:5010",
        documents: "http://localhost:5012",
        premiumCalculator: "http://localhost:5011",
        lpPayment: "https://mydev.lawpro.ca/file_online/myl_sso/tp_auth_handler.asp",        
        // omsLpPayment:"https://lawproportalqa.oms.oneshield.com/api/title-plus-payment"
        omsLpPayment:"https://lawproportalpreprodcc.oms.oneshield.com/api/title-plus-payment"
        //premiumCalculator: "https://api.titlesmatter.ca/calculator"
    },
    activationEmailFrom: "noreply@titlesmatter.ca",
    auth_config: authConfigNonProdOkta, //authConfigLocalToDev
    idrm: {
        forgotPassword: "https://idrm-okta-ps1.icsynergy.info/guest/forgot-password/l2",
        forgotUsername: "https://myaccountsdev.lawpro.ca/guest/forgot-username"
    },
    isOMSEnabled: true
};

appSettings["development0"] = {
    urls: {
        kyc: "https://treeforttech.com/kyc2092012516122119/",
        policy: "https://api.titlesmatter.ca/policy",
        documents: "https://api.titlesmatter.ca/documents",
        premiumCalculator: "https://api.titlesmatter.ca/calculator",
        lpPayment: "https://mydev.lawpro.ca/file_online/myl_sso/tp_auth_handler.asp",        
        omsLpPayment:"https://lawproportalqa.oms.oneshield.com/api/title-plus-payment"
    },
    activationEmailFrom: "noreply@titlesmatter.ca",
    auth_config: authConfigNonProdOkta, //authConfigDev
    idrm: {
        forgotPassword: "https://idrm-okta-ps1.icsynergy.info/guest/forgot-password/l2",
        forgotUsername: "https://myaccountsdev.lawpro.ca/guest/forgot-username"
    },
    isOMSEnabled: false
};

appSettings["local-to-dev0"] = {
    urls: {
        kyc: "https://treeforttech.com/kyc2092012516122119/",
        policy: "https://api.titlesmatter.ca/policy",
        documents: "https://api.titlesmatter.ca/documents",
        premiumCalculator: "https://api.titlesmatter.ca/calculator",
        lpPayment: "https://mydev.lawpro.ca/file_online/myl_sso/tp_auth_handler.asp",        
        omsLpPayment:"https://lawproportalqa.oms.oneshield.com/api/title-plus-payment"
    },
    activationEmailFrom: "noreply@titlesmatter.ca",
    auth_config: authConfigNonProdOkta, //authConfigLocalToDev    
    isOMSEnabled: false
};

appSettings["development"] = {
    urls: {
        kyc: "https://treeforttech.com/kyc2092012516122119/",
        policy: "https://dev-api.mytitleplus.ca/policy",
        documents: "https://dev-api.mytitleplus.ca/documents",
        premiumCalculator: "https://dev-api.mytitleplus.ca/calculator",
        lpPayment: "https://mydev.lawpro.ca/file_online/myl_sso/tp_auth_handler.asp",        
        // omsLpPayment:"https://lawproportalqa.oms.oneshield.com/api/title-plus-payment"
        omsLpPayment:"https://lawproportalpreprodcc.oms.oneshield.com/api/title-plus-payment"
    },
    activationEmailFrom: "noreply@titlesmatter.ca",
    auth_config: authConfigNonProdOkta,
    idrm: {
        forgotPassword: "https://idrm-okta-ps1.icsynergy.info/guest/forgot-password/l2",
        forgotUsername: "https://myaccountsdev.lawpro.ca/guest/forgot-username"
    },
    isOMSEnabled: true
};

appSettings["qa"] = {
    urls: {
        kyc: "https://treeforttech.com/kyc2092012516122119/",
        policy: "https://qa-api.mytitleplus.ca/policy",
        documents: "https://qa-api.mytitleplus.ca/documents",
        premiumCalculator: "https://qa-api.mytitleplus.ca/calculator",
        lpPayment: "https://mydev.lawpro.ca/file_online/myl_sso/tp_auth_handler.asp",
        omsLpPayment:"https://lawproportalpreprodcc.oms.oneshield.com/api/title-plus-payment"
    },
    activationEmailFrom: "noreply-titleplus@lawpro.ca",
    auth_config: authConfigNonProdOkta,
    idrm: {
        forgotPassword: "https://idrm-okta-ps1.icsynergy.info/guest/forgot-password/l2",
        forgotUsername: "https://myaccountsdev.lawpro.ca/guest/forgot-username"
    },
    isOMSEnabled: true
};

appSettings["production"] = {
    urls: {
        kyc: "https://treeforttech.com/kyc2092012516122119/",
        policy: "https://api.mytitleplus.ca/policy",
        documents: "https://api.mytitleplus.ca/documents",
        premiumCalculator: "https://api.mytitleplus.ca/calculator",
        lpPayment: "https://my.lawpro.ca/file_online/myl_sso/tp_auth_handler.asp",        
        omsLpPayment:"https://my.lawpro.ca/api/title-plus-payment"
    },
    activationEmailFrom: "noreply-titleplus@lawpro.ca",
    auth_config: authConfigProdOkta,
    idrm: {
        forgotPassword: "https://myaccounts.lawpro.ca/guest/forgot-password/l2",
        forgotUsername: "https://myaccounts.lawpro.ca/guest/forgot-username"
    },
    isOMSEnabled: true
};

const logger = new Logger("AppSettings");

logger.info(`process.env.REACT_APP_ENV is ${env}`);

class EnvironmentInferer
{
    envExplicit = null;

    constructor(envExplicit) {
        // explicitly set environment
        this.envExplicit = envExplicit;
    }

    get currEnv() {
        if (!StringUtil.isNullOrEmpty(this.envExplicit)) {
            // if we have an explicitly set environment -- it is king
            return this.envExplicit;
        }

        const hostname = window.location.hostname;

        // infer based on where the app is running...

        if (StringUtil.isEqual(hostname, 'localhost')) {
            return 'local';
        }
        else if(StringUtil.contains(hostname, 'titlesmatter.ca')) {
            return 'development0';
        }
        else if(StringUtil.contains(hostname, 'dev.mytitleplus.ca')) {
            return 'development';
        }
        else if(StringUtil.contains(hostname, 'qa.mytitleplus.ca')) {
            return 'qa';
        }

        return 'production';
    }
}

const envInferer = new EnvironmentInferer(env);

logger.info(`current environment is ${envInferer.currEnv}`);

const current = appSettings[envInferer.currEnv];

// const current = appSettings["local-to-dev"];

export { current as AppSettings }
