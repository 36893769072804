import * as UserActions from 'User/State/UserActions';
import * as AppActions from "State/AppActions";
import { AuthApiClient } from 'Auth/AuthApiClient';
import Logger from 'Util/Logger';
import { UserApiClient } from 'User/UserApiClient';

const logger = new Logger("UserThunks");

export function loadUserProfile(isAuthenticated, whenDone) {
    return async function (dispatch, getState) {
        if(!isAuthenticated) {
            dispatch({ type: UserActions.set_user_info.type, payload: null });

            return;
        }

        dispatch({ type: UserActions.set_user_info_load_status.type, payload: { status: 'loading' } });        
        const resp = await AuthApiClient.loadUserProfile();
        if (resp.hasError) {            
            let status = resp.error && resp.error==="Declined" ? "declined": "error";
            dispatch({ type: UserActions.set_user_info_load_status.type, payload: { status: status, errorMessage: resp.errorMessage } });
            
            return;
        }

        const up = resp.data;

        dispatch({ type: UserActions.set_user_info.type, payload: up });

        if (up && up.Role === "Lawyer" && up.LSN){
            const omsObjectIdResp =  await UserApiClient.getOmsObjectId(up.LSN );
            if (omsObjectIdResp && omsObjectIdResp.data && omsObjectIdResp.data.ObjectId)
                dispatch({ type: UserActions.update_oms_object_id.type, payload: omsObjectIdResp.data.ObjectId });
        }

        if(typeof(whenDone) !== typeof(undefined)) {
            whenDone();
        }
    }
}


export function addUserAssociation(effectiveUserId, firstName, lastName, email, associatingUserRole, lsn, requestProcessed) {
    return async function (dispatch, getState) {
        dispatch({ type: AppActions.clearActionMessage.type });
        dispatch({ type: UserActions.set_user_associations_busy.type, payload: true }); // SET BUSY
        
        const resp = await UserApiClient.addUserAssociation(effectiveUserId, firstName, lastName, email, associatingUserRole, lsn);       
        dispatch({ type: UserActions.set_user_associations_busy.type, payload: false }); // SET BUSY FALSE

        if (resp.hasError) {
            dispatch({ type: AppActions.setActionMessage.type, payload: { type: 'error', message: resp.errorMessage } });
            if (requestProcessed) requestProcessed(false);
            return;
        }

        const respData = resp.data;

        const newUserAssociations = respData.UserAssociations;

        dispatch({ type: UserActions.set_associations.type, payload: newUserAssociations });       
        if (requestProcessed) requestProcessed(true);
    }
}


export function setUserAssociationStatus(userAssociationId, status, effectiveUserId = null) {
    return async function (dispatch, getState) {
        dispatch({ type: AppActions.clearActionMessage.type });
        dispatch({ type: UserActions.set_user_associations_busy.type, payload: true }); // SET BUSY
        
        const resp = await UserApiClient.setUserAssociationStatus(userAssociationId, status, effectiveUserId);

        dispatch({ type: UserActions.set_user_associations_busy.type, payload: false }); // SET BUSY FALSE

        if (resp.hasError) {
            dispatch({ type: AppActions.setActionMessage.type, payload: { type: 'error', message: resp.errorMessage } });

            return;
        }

        const respData = resp.data;

        const newUserAssociations = respData.UserAssociations;

        dispatch({ type: UserActions.set_associations.type, payload: newUserAssociations });
    }
}
