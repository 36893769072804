import React, { useMemo } from 'react';
import { Snackbar } from '@mui/material';
import { StringUtil } from 'Util/Helpers';
import MuiAlert from '@mui/material/Alert';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import * as AppActions from 'State/AppActions';

function createActionMsgSelector() {
    return createSelector(
        state => state.app.actionMsg,
        x => x
    );
}

function ActionMsgPopup() {
    const dispatch = useDispatch();

    const selectActionMsg = useMemo(
        createActionMsgSelector,
        []
    );

    const actionMsg = useSelector(x => selectActionMsg(x));

    const actionResultOpen = !StringUtil.isNullOrEmpty(actionMsg.message);

    const closeActionResult = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        dispatch({ type: AppActions.clearActionMessage.type });
    };

    return (
        <Snackbar open={actionResultOpen} autoHideDuration={6000} onClose={closeActionResult}>
            <MuiAlert elevation={6} variant="filled" onClose={closeActionResult} severity={actionMsg.type}>
                {actionMsg.message}
            </MuiAlert>
        </Snackbar>
    );
}

export default ActionMsgPopup;