import React, { useRef } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { FormGroup, FormControl, FormControlLabel, Checkbox, TextField } from '@mui/material';
import { useTranslation } from "react-i18next";
import { StringUtil } from 'Util/Helpers';
import { TextFieldDebounced, withOnChangeDebounced } from 'Components/withOnChangeDebounced';
import SyntheticEvent from 'Components/SyntheticEvent';
import CheckBoxOptionGroup from 'Components/CheckBoxOptionGroup';
import withFieldStateBindings from 'Components/withFieldStateBindings';
import MultipleOptionGroup from 'Components/MultipleOptionGroup';

const useStyles = makeStyles(theme => ({
    root: {
        marginRight: theme.spacing(2)
    },
    nameField: {
        marginRight: theme.spacing(2),
        width: '50ch'
    }
}));

const CheckBoxOptionGroupBound = withFieldStateBindings(CheckBoxOptionGroup);
const TextFieldBound = withFieldStateBindings(withOnChangeDebounced(TextField));

function BorrowerLawyerName(props) {
    const { qId, textFieldProps, addl, optionGroupProps, indexer, other } = props;

    const firstInputRef = useRef();
    const lastInputRef = useRef();

    const classes = useStyles();

    const { t } = useTranslation();

    const naFieldName = `${indexer}${qId}NA`;
    const naProps = optionGroupProps(naFieldName);
    const naChecked = StringUtil.isEqual(naProps.defaultValue, 'yes');

    addl.required = false;

    function naChange(e) {
        e.persist();

        if (addl.readOnly) {
            return;
        }

        const value = e.target.value;

        const ev = new SyntheticEvent(naFieldName, value);

        naProps.onChange(ev);

        // clear first and last values
        if (StringUtil.isEqual(value, 'yes')) {
            firstInputRef.current.value = '';
            lastInputRef.current.value = '';
        }
    }

    return (
        <FormControl name={qId} area-label={qId}>
            <FormGroup row>
                <TextFieldBound className={classes.nameField} label={t("First")} {...textFieldProps(`${indexer}${qId}First`)} {...addl} inputRef={firstInputRef} {...other} />
                <TextFieldBound className={classes.nameField} label={t("Last")} {...textFieldProps(`${indexer}${qId}Last`)} {...addl} inputRef={lastInputRef} {...other} />
                <CheckBoxOptionGroupBound
                    opts={[{ Id: `yes`, Label: "N/A" }]}
                    {...naProps}
                    onChange={naChange}
                    name={naFieldName}
                    {...other}
                />
            </FormGroup>
        </FormControl>
    );
}

export default BorrowerLawyerName;