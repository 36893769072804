import { createAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import StringUtil from 'Util/StringUtil';

export const set_users_list = createAction('admin/set_users_list');
export const set_users_list_loading = createAction('admin/set_users_list_loading');
export const set_users_list_loading_error = createAction('admin/set_users_list_loading_error');
export const update_user_status_change_busy = createAction('admin/set_user_status_change_busy');
export const clear_user_status_change_busy = createAction('admin/clear_user_status_change_busy');
export const update_user_status = createAction('admin/update_user_status');

export function createUsersListSelector() {
    return createSelector(
        state => state.admin.users_list,
        x => x
    );
}

export function createUsersListLoadingSelector() {
    return createSelector(
        state => state.admin.users_list_loading,
        x => x
    );
}

export function createUsersListLoadingErrorSelector() {
    return createSelector(
        state => state.admin.users_list_loading_error,
        x => x
    );
}

export function createUsersListTotalRecordsSelector() {
    return createSelector(
        state => state.admin.users_list_total_records,
        x => x
    );
}

export function createUsersListTotalPagesSelector() {
    return createSelector(
        state => state.admin.users_list_total_pages,
        x => x
    );
}

export function createUserStatusChangeBusySelector() {
    return createSelector(
        state => state.admin.user_status_change_busy,
        (_, id) => id,
        (x, id) => x[id]
    );
}
