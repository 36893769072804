import { Box, Button, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { green } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { SectionHeader } from 'Components/Questionary';
import ColumnHeader from 'Components/Table/ColumnHeader';
import { isEmpty } from 'lodash';
import { PermittedOrderActionsEnum, PolicyTypeEnum, TransactionTypeEnum } from 'Model/PolicyOrder';
import PolicyViewHelper from 'Policy/PolicyViewHelper';
import * as DocumentThunks from 'Policy/State/DocumentThunks';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { StringUtil } from 'Util/Helpers';

const useStylesBusyInd = makeStyles(theme => ({
    btnBusyInd: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        color: green[500]
    },
}));

function createOrderVolatileDataSelector() {
    return createSelector(
        state => state.order.orderVolatileData,
        (_, propName) => propName,
        (x, propName) => x[propName]
    );
}

function createGeneratingPDFSelector() {
    return createSelector(
        state => state.order.generatingPDF,
        x => x
    );
}

function SmallBusyInd(props) {
    const classes = useStylesBusyInd();

    return (
        <React.Fragment>
            <Typography>Generating...</Typography> <CircularProgress size={24} className={classes.btnBusyInd} />
        </React.Fragment>
    );
}

function InjectDisclosures(isPrairies, hasEOPolicy, arr) {
    const newArr = [];

    for (const item of arr) {
        if (StringUtil.isEither(item.Type, PolicyTypeEnum.LenderPolicyOnly)) {
            newArr.push(item);

            const itemClone = { ...item };
            itemClone.Type = "Disclosure";
            itemClone.TypeHeading = "Confirmation to Lender";

            newArr.push(itemClone);
        }

        if (StringUtil.isEither(item.Type, PolicyTypeEnum.OwnerPolicyOnly)) {
            newArr.push(item);

            const itemClone = { ...item };
            itemClone.Type = "Disclosure";
            itemClone.TypeHeading = "Purchaser Acknowledgement";

            if (isPrairies) {
                itemClone.TypeHeading = "Confirmation to Purchaser";
            }

            if(hasEOPolicy) {
                itemClone.TypeHeading = "Owner Declaration and Acknowledgment";
            }

            newArr.push(itemClone);
        }
    }

    return newArr;
}

function DocumentRow(props) {
    const { premium, dispatch, po, generatingPolicyNum, orderSecureId } = props;

    const selectGeneratingPDF = useMemo(
        createGeneratingPDFSelector,
        []
    );

    const generatingPDF = useSelector(x => selectGeneratingPDF(x));

    // todo: show which lender
    const policyNumber = premium.PolicyNumber;

    let documentType = premium.Type;

    if (StringUtil.isEqual(premium.Type, PolicyTypeEnum.LenderPolicyOnly)) {
        documentType = 'Lender Policy';
    }
    else if (StringUtil.isEqual(premium.Type, PolicyTypeEnum.OwnerPolicyOnly)) {
        documentType = 'Owner Policy';
    }

    let typeHeading = documentType;

    if (!StringUtil.isNullOrEmpty(premium.TypeHeading)) {
        typeHeading = premium.TypeHeading;
    }

    const handleViewPolicyPDF = (event) => {
        dispatch(DocumentThunks.generatePolicyDocument(orderSecureId, po.DealNumber, policyNumber, documentType));
    };

    const isBusy = !isEmpty(generatingPDF);

    return (
        <TableRow hover tabIndex={-1}>
            <TableCell>{premium.PolicyNumber}</TableCell>
            <TableCell>{typeHeading}</TableCell>
            <TableCell></TableCell>
            <TableCell align="right">
                <span style={{ display: 'inline-flex' }}>
                    <Box display="flex" alignItems="center">
                        {StringUtil.isEqual(generatingPDF.policyNumber, policyNumber) && StringUtil.isEqual(generatingPDF.documentType, documentType) &&
                            <SmallBusyInd />
                        }
                        <Button
                            size="small"
                            type="button"
                            variant="contained"
                            color="primary"
                            startIcon={<PictureAsPdfIcon />}
                            onClick={handleViewPolicyPDF}
                            disabled={isBusy}
                        >
                            View/Print
                        </Button>
                    </Box>
                </span>
            </TableCell>
        </TableRow>
    );
}

function PolicyDocumentsSection(props) {
    const { questionary, questionParams, getInitialAnswer, po } = props;

    const selectOrderVolatileData = useMemo(
        createOrderVolatileDataSelector,
        []
    );

    const dispatch = useDispatch();

    const viewHelper = new PolicyViewHelper(po, dispatch);
    const orderSecureId = viewHelper.getDealIdFromQueryString();

    const premium = useSelector(x => selectOrderVolatileData(x, "Premium"));

    const permittedActions = useSelector(x => selectOrderVolatileData(x, "PermittedActions"));

    const allowPrintDocs = StringUtil.contains(permittedActions, PermittedOrderActionsEnum.GenerateDocs);

    if (!allowPrintDocs) {
        return null; // do not show this section unless it's appropriate
    }

    if (!premium || !premium.BreakDown) {
        return null;
    }

    const breakDownArr = premium.BreakDown;

    const isPrairies = StringUtil.isEither(premium.Province, 'MB', 'AB');

    const breakDownArrSorted = [...breakDownArr].sort((a, b) => { return b.Type.localeCompare(a.Type) }); // sort so that "Owner" always comes at top ...}

    const hasEOPolicy = StringUtil.isEqual(premium.TransactionType, TransactionTypeEnum.ExistingOwner) || 
        (StringUtil.isEqual(premium.TransactionType, TransactionTypeEnum.MortgageOnly) && 
        StringUtil.isEqual( breakDownArrSorted[0].Type, PolicyTypeEnum.OwnerPolicyOnly));

    const breakDownArrFinal = InjectDisclosures(isPrairies, hasEOPolicy, breakDownArrSorted);

    return (
        <React.Fragment>
            <SectionHeader section="documents" title="Documents" questionary={questionary} />
            <Box mt={1} mb={2} flexGrow={1}>
                <TableContainer>
                    <Table aria-label="documents table" size="small">
                        <TableHead>
                            <TableRow>
                                <ColumnHeader id={"PolicyNum"}>Policy #</ColumnHeader>
                                <ColumnHeader id={"Type"}>Type</ColumnHeader>
                                <ColumnHeader id={"Info"}>Additional Info</ColumnHeader>
                                <ColumnHeader id={"Action"} align="right">Action</ColumnHeader>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {breakDownArrFinal.map((premium, index) => (
                                <DocumentRow premium={premium} key={index} dispatch={dispatch} po={po} orderSecureId={orderSecureId} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </React.Fragment>
    );
}

export default PolicyDocumentsSection;
