import { Box, MenuItem } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { createSelector } from '@reduxjs/toolkit';
import GroupedButton from 'Components/GroupedButton';
import MultiSelectAutoComplete from 'Components/MultiSelectAutoComplete';
import { ApplicabilityTypeEnum, PolicyTypeEnum } from 'Model/PolicyOrder';
import React, { useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { IdGenerator, StringUtil } from 'Util/Helpers';
import { ValidationHelper } from 'Util/ValidationHelper';
import { PolicyApiClient } from '../../PolicyApiClient';

const useStyles = makeStyles(() => ({
    fullWidth: {
        width: "100%"
    }
}));

function createOrderVolatileDataSelector() {
    return createSelector(
        state => state.order.orderVolatileData,
        (_, propName) => propName,
        (x, propName) => x[propName]
    );
}

function SearchException(props) {
    const classes = useStyles();
    const { appliedExceptions, setAppliedExceptions, canChangeExceptions, setChangeCount } = props;
    const [openSearchPopup, setOpenSearchPopup] = useState(false);
    const [options, setOptions] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [actionMsg, setActionMsg] = useState('');
    const [actionMsgType, setActionMsgType] = useState('');

    const multiSelectAutoCompleteRef = useRef();

    const selectOrderVolatileData = useMemo(
        createOrderVolatileDataSelector,
        []
    );

    const policyMap = useSelector(x => selectOrderVolatileData(x, `PolicyMap`));
    const properties = useSelector(x => selectOrderVolatileData(x, `Properties`));
    let province = '';
    
    if (properties)
        province = properties[0].PropertyAddressProvince;

    if (!canChangeExceptions)
        return null;

    //#region Search
    async function handleSearch(searchCriteria) {
        if (StringUtil.isNullEmptyOrWhiteSpace(searchCriteria))
            return;

        const resp = await PolicyApiClient.searchPolicyException(searchCriteria, province);

        if (resp.hasError) {
            setActionMsgType('error');
            setActionMsg(resp.errorMessage);

            if (!resp.hasValidationResult) {
                return;
            }
        }

        const respData = resp.data;
        const validationMap = ValidationHelper.getValidationMap(respData);
        if (!StringUtil.isNullOrEmpty(validationMap)) {
            setActionMsgType('error');
            setActionMsg('Search failed');
            return;
        }

        const opts = respData.Exceptions.map((option) => {
            const firstLetter = option.Topic[0].toUpperCase();
            return {
                FirstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
                ...option,
            };
        });

        setOptions(opts);
        setOpenSearchPopup(true);
    }

    function handleOnAutoCompleteChange(event, value) {
        setSelectedOptions(value);
    }

    function handleApplyBtnClick(policyType, policyNumber) {
        const appliedItems = [...appliedExceptions];
        appliedItems.forEach(item => item.AlreadyApplied = false);
        const policyTypes = StringUtil.isEqual(policyType, PolicyTypeEnum.OwnerAndLenderPolicy) ? [PolicyTypeEnum.LenderPolicyOnly, PolicyTypeEnum.OwnerPolicyOnly] : [policyType];

        selectedOptions.forEach((item) => {
            policyTypes.forEach(policyType => {
                const existingItem = appliedItems.find(o => StringUtil.isEqual(o.Code, item.Code) && StringUtil.isEqual(o.AppliedTo, policyNumber));
                if (existingItem) {
                    const existingItemIndex = appliedItems.indexOf(existingItem);
                    appliedItems[existingItemIndex].AlreadyApplied = true;
                    return;
                }

                const exception = { ...item };
                exception.SecureId = IdGenerator.NewId();
                exception.Type = ApplicabilityTypeEnum.Manual;
                exception.AppliedTo = policyNumber;
                exception.PolicyType = policyType;
                exception.ToSave = true;
                exception.IsNew = true;

                appliedItems.push(exception);
                setChangeCount(prevState => prevState + 1);
            });
        });
        setAppliedExceptions(appliedItems);
        multiSelectAutoCompleteRef.current.clearSearch();
    }
    //#endregion Search

    //#region Props and HTML
    const shouldDisableApplyButton = appliedExceptions.some(o => o.Saving === true) || selectedOptions.length === 0;

    const hasOwnerPolicies = policyMap.some(p => StringUtil.isEqual(p.Type, PolicyTypeEnum.OwnerPolicyOnly));
    const hasLenderPolicies = policyMap.some(p => StringUtil.isEqual(p.Type, PolicyTypeEnum.LenderPolicyOnly));

    return <React.Fragment>
        {canChangeExceptions &&
            <Box style={{ display: "inline-flex", marginTop: 10, marginBottom: 10 }} className={classes.fullWidth}>
                <Box style={{ paddingRight: 2 }} className={classes.fullWidth}>
                    <MultiSelectAutoComplete
                        ref={multiSelectAutoCompleteRef}
                        openPopup={openSearchPopup}
                        options={options}
                        onChangeEventHandler={handleOnAutoCompleteChange}
                        onSearchEventHandler={handleSearch}
                        textFieldLabel="Search exceptions"
                        optionLabelProp="Topic"
                        optionIdProp="Code"
                        groupByProp="FirstLetter"
                    />
                </Box>
                {/* Apply button */}
                <GroupedButton disabled={shouldDisableApplyButton} buttonText="Apply">
                    {hasOwnerPolicies && [
                        <MenuItem disabled key="ownerPolicy" value="">
                            <em>Owner Policy(s)</em>
                        </MenuItem>,
                        policyMap.filter(p => StringUtil.isEqual(p.Type, PolicyTypeEnum.OwnerPolicyOnly))
                            .map((p) => {
                                return <MenuItem
                                    key={p.PolicyNumber}
                                    onClick={() => handleApplyBtnClick(PolicyTypeEnum.OwnerPolicyOnly, p.PolicyNumber)}>
                                    {p.PolicyNumber}
                                </MenuItem>;
                            })

                    ]}
                    {hasLenderPolicies && [
                        <MenuItem disabled key="lenderPolicies" value="">
                            <em>Lender Policy(s)</em>
                        </MenuItem>,
                        policyMap.filter(p => StringUtil.isEqual(p.Type, PolicyTypeEnum.LenderPolicyOnly))
                            .map((p) => {
                                return <MenuItem
                                    key={p.PolicyNumber}
                                    onClick={() => handleApplyBtnClick(PolicyTypeEnum.LenderPolicyOnly, p.PolicyNumber)}>
                                    {p.PolicyNumber}
                                </MenuItem>;
                            })
                    ]}
                </GroupedButton>
            </Box>
        }
    </React.Fragment>;
    //#endregion Props and HTML 
}

export default SearchException;