import { CircularProgress, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { StringUtil } from 'Util/Helpers';
import useDebounce from 'Util/UseDebounce';

const AutoCompleteFreeForm = forwardRef((props, ref) => {
    const { bound, multiSelect, openPopup, options, optionLabelProp, groupByProp, textFieldLabel, defaultValue, onChangeEventHandler, onSearchEventHandler, readOnly } = props;

    const [showHideGroup, setShowHideGroup] = useState({});
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [changeCount, setChangeCount] = useState(0);
    const searchInputRef = useRef();
    const debouncedSearch = useDebounce(changeCount, 2000);
    const autoCompleteRef = useRef();

    useEffect(() => {
        if (options) {
            const grps = options.map(o => o[groupByProp]).reduce((x, y) => x.includes(y) ? x : [...x, y], []);

            if (grps) {
                var distinctGroups = [...grps];

                distinctGroups.map(g => setShowHideGroup(prevState => ({ ...prevState, [g]: true })));
            }
        }
        setOpen(openPopup);
    }, [openPopup, options]);

    useEffect(() => {
        if (changeCount == 0)
            return;

        if (onSearchEventHandler) {
            setLoading(true);
            onSearchEventHandler(searchInputRef.current.value).then(result => {
                setLoading(false);
            });
        }

    }, [debouncedSearch]);

    //Exposing the function to the parent
    useImperativeHandle(ref, () => ({
        clearSearch() {
            autoCompleteRef.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0].click(); //need to figure out a better approach        
        }
    }));

    let defaultOption = null;

    if (options)
        defaultOption = options.find(option => StringUtil.isEqual(option.Id, defaultValue));

    if (!defaultOption)
        defaultOption = { "Id": defaultValue, "Label": defaultValue };

    const disableClearable = readOnly ?? false;

    function searchTextBoxOnChangeEventHandler(event) {
        if (readOnly) {
            event.preventDefault();

            return;
        }

        setChangeCount(prevState => prevState + 1);
    }

    return (
        <Autocomplete
            style={{ minWidth: 500 }}
            freeSolo
            ref={autoCompleteRef}
            multiple={multiSelect}
            disableCloseOnSelect={multiSelect}
            open={open}
            onOpen={() => readOnly ? setOpen(false) : setOpen(true)}
            onClose={() => readOnly ? setOpen(false) : setOpen(false)}
            loading={loading}
            getOptionLabel={(option) => option[optionLabelProp]}
            isOptionEqualToValue={(option, value) => StringUtil.isEqual(option[optionLabelProp], value[optionLabelProp])}
            //groupBy={(option) => option[groupByProp]}
            //options={options.sort((a, b) => StringUtil.compareOrdinality(a[groupByProp], b[groupByProp]))} //Sort to fix MUI Autocomplete duplicate headers
            options={options} //Sort to fix MUI Autocomplete duplicate headers
            disableClearable={disableClearable}
            renderInput={(params) =>
                <TextField
                    label={textFieldLabel}
                    {...params}
                    //variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        readOnly: readOnly,
                        endAdornment: (
                            <React.Fragment>
                                {loading && <CircularProgress size={20} />}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        )
                    }}
                    inputRef={searchInputRef}
                    onChange={searchTextBoxOnChangeEventHandler}
                />
            }
            onChange={onChangeEventHandler} // spits out the selected option item
            defaultValue={defaultOption}
        />
    );
});

export default AutoCompleteFreeForm;