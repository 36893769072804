import { createSelector } from '@reduxjs/toolkit';
import { Question, SectionHeader } from 'Components/Questionary';
import { TransactionTypeEnum } from 'Model/PolicyOrder';
import Conditional from 'Policy/Conditional';
import React, { Fragment, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { StringUtil } from 'Util/Helpers';
import { QuestionaryHelper } from 'Util/QuestionaryHelper';

function createOrderChangesSelector() {
    return createSelector(
        state => state.order.orderChanges,
        (_, propName) => propName,
        (x, propName) => x[propName]
    );
}

function OffTitle(props) {
    const { item, questionParams, questionary, collectionName, conditionalProps } = props;

    questionParams.indexer = QuestionaryHelper.createIndexer(collectionName, item);

    const selectOrderChanges = useMemo(
        createOrderChangesSelector,
        []
    );

    const propertyType = useSelector(x => selectOrderChanges(x, `${questionParams.indexer}TransactionType`));
    const transactionType = useSelector(x => selectOrderChanges(x, `${questionParams.indexer}PropertyType`));

    if (!propertyType || !transactionType)
        return null;

    return (
        <Fragment>
            <SectionHeader section="offTitle" title="Off-Title" questionary={questionary} subSection />

            <Question qId="TenantedOnClosing" {...questionParams} />
            <Question qId="RealtyTaxesPaid" {...questionParams} />
            <Question qId="UtReadjustTaxesObtained" {...questionParams} />
            <Question qId="WaterSewagePayment" {...questionParams} />
            <Question qId="IsOnPvtWaterSewageServices" {...questionParams} />
            {/* if Resale */}
            <Conditional upon="WaterSewagePayment" when={(x) => StringUtil.isEqual(x, "on_private_water_or_sewage_services")} {...conditionalProps}>
                <Question qId="SepticSystemEndorsement" {...questionParams} />
            </Conditional>
            {/* if New */}
            <Conditional upon="IsOnPvtWaterSewageServices" when={(x) => StringUtil.isYes(x)} {...conditionalProps}>
                <Question qId="SepticSystemEndorsement" {...questionParams} />
            </Conditional>
            {/* if MortgageOnly Transaction type */}
            <Conditional upon="AddExistingOwnerPolicy" when={(x) => StringUtil.isNo(x)} {...conditionalProps}>
                <Question qId="SepticSystemEndorsement" {...questionParams} />
            </Conditional>

            <Conditional upon="AddExistingOwnerPolicy" when={(x) => StringUtil.isYes(x)} {...conditionalProps}>
                <Question qId="SepticSystemEndorsementForLender" {...questionParams} />
            </Conditional>
            {/* TODO implement NHP Rules for this question */}
            <Conditional upon="HasTpInsuredMortgage" when={(x) => x == true} {...conditionalProps}>
                <Conditional upon="WaterSewagePayment" when={(x) => StringUtil.isEqual(x, "on_private_water_or_sewage_services")} {...conditionalProps}>
                    <Question qId="WaterPotabilityEndorsement" {...questionParams} />
                </Conditional>
                <Conditional upon="IsOnPvtWaterSewageServices" when={(x) => StringUtil.isYes(x)} {...conditionalProps}>
                    <Question qId="WaterPotabilityEndorsement" {...questionParams} />
                </Conditional>
            </Conditional>
            <Conditional upon="TransactionType" when={(x) => StringUtil.isEqual(x, TransactionTypeEnum.MortgageOnly)} {...conditionalProps}>
                <Question qId="WaterPotabilityEndorsement" {...questionParams} />
            </Conditional>
            <Conditional upon="CoverageType" when={(x) => StringUtil.isEither(x, 'owner_and_lender', 'lender_policy_only')} {...conditionalProps}>
                <Conditional upon="HasTpInsuredMortgage" when={(x) => x == true} {...conditionalProps}>
                    <Question qId="ExecAgainstVendorPurchaserResolved" {...questionParams} />
                </Conditional>
            </Conditional>
            <Question qId="ExecAgainstBorrowerResolved" {...questionParams} />


            <Conditional upon="HasTpInsuredMortgage" when={(x) => x == true} {...conditionalProps}>
                    <Question qId="ExecAgainstPurchaserResolved" {...questionParams} />
            </Conditional>            
            <Conditional upon="HasMortgage" when={(x) => !x} {...conditionalProps}>
                <Question qId="ExecAgainstVendorResolved" {...questionParams} />
            </Conditional>


            <Conditional upon="NoExecutionReasonRequired" when={(x) => x == true} {...conditionalProps}>
                <Question qId="NoExecutionReason" subQuestion {...questionParams} />
            </Conditional>

            <Conditional upon="LandRegistryOffice" when={(x) => !StringUtil.contains(x, "Sudbury")}  {...conditionalProps}>
                <Question qId="BuildingZoningSearch" {...questionParams}>

                    <Conditional upon="BuildingZoningSearch" when={(x) => StringUtil.isEqual(x, 'issue')} {...conditionalProps}>
                        <Question qId="BuildingZoningIssues" {...questionParams}>
                            <Conditional upon="BuildingZoningIssues" when={(x) => StringUtil.containsAny(x, 'no_permits', 'other')} {...conditionalProps}>
                                <Question qId="BuildingZoningOtherIssues" subQuestion {...questionParams} />
                            </Conditional>
                        </Question>
                    </Conditional>

                    <Conditional upon="BuildingZoningSearch" when={(x) => StringUtil.isEither(x, 'clear', 'issue')} {...conditionalProps}>
                        <Question qId="NumberOfUnitsPermitted" {...questionParams} />
                    </Conditional>

                </Question>

                <Conditional upon="BuildingZoningSearch" when={(x) => StringUtil.isEither(x, 'clear', 'issue')} {...conditionalProps}>
                    <Question qId="FireSearch" {...questionParams} />
                </Conditional>
            </Conditional>

            <Conditional upon="LandRegistryOffice" when={(x) => StringUtil.contains(x, "Sudbury")}  {...conditionalProps}>
                <Conditional upon="PropertySearchRequest" when={(x) => StringUtil.isEqual(x, "search_done")}  {...conditionalProps}>
                    <Question qId="PropertySearchResult" {...questionParams}>

                        <Conditional upon="PropertySearchResult" when={(x) => StringUtil.isEqual(x, 'issue')} {...conditionalProps}>
                            <Question qId="BuildingZoningIssues" {...questionParams}>
                                <Conditional upon="BuildingZoningIssues" when={(x) => StringUtil.containsAny(x, 'no_permits', 'other')} {...conditionalProps}>
                                    <Question qId="BuildingZoningOtherIssues" subQuestion {...questionParams} />
                                </Conditional>
                            </Question>
                        </Conditional>

                        <Conditional upon="PropertySearchResult" when={(x) => StringUtil.isEither(x, 'clear', 'issue')} {...conditionalProps}>
                            <Question qId="NumberOfUnitsPermitted" {...questionParams} />
                        </Conditional>

                    </Question>

                    <Conditional upon="PropertySearchResult" when={(x) => StringUtil.isEither(x, 'clear', 'issue')} {...conditionalProps}>
                        <Question qId="FireSearch" {...questionParams} />
                    </Conditional>
                </Conditional>
            </Conditional>

            <Conditional upon="CertificateInformationRequired" when={(x) => x == true} {...conditionalProps}>
                <Question qId="CertificateStatus" {...questionParams}>
                    <Conditional upon="CertificateStatus" when={(x) => StringUtil.isNo(x)} {...conditionalProps}>
                        <Question qId="CertificateIssues" {...questionParams}>
                            <Conditional upon="CertificateIssues" when={(x) => StringUtil.hasOther(x)} {...conditionalProps}>
                                <Question qId="OtherCertificateIssues" optional subQuestion {...questionParams} />
                            </Conditional>
                        </Question>
                    </Conditional>
                </Question>
            </Conditional>
        </Fragment>
    );
}

export default OffTitle;