import React, { useEffect, useMemo, useReducer } from 'react';
import { Avatar, Grid, Tooltip, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DateFormatUtil } from 'Util/DateFormatUtil';
import { SectionHeader } from 'Components/Questionary';
import { StringUtil, UserHelper } from 'Util/Helpers';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { PolicyApiClient } from './PolicyApiClient';
import Logger from 'Util/Logger';
import * as UserPermissions from 'Model/UserPermissions';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        overflow: 'hidden',
        padding: theme.spacing(0, 3),
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        fontSize: 'revert'
    },
}));

function createOrderVolatileDataSelector() {
    return createSelector(
        state => state.order.orderVolatileData,
        (_, propName) => propName,
        (x, propName) => x[propName]
    );
}

const logger = new Logger(EventLogs.name);

export default function EventLogs(props) {
    const { po, userInfo, permittedActions } = props;
    
    const classes = useStyles();

    const [initLoadData, setInitLoadData] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            loading: true,
            eventLogs: null
        }
    );

    const selectOrderVolatileData = useMemo(
        createOrderVolatileDataSelector,
        []
    );

    let secureId = useSelector(x => selectOrderVolatileData(x, "SecureId"));

    if (!secureId) {
        secureId = po.SecureId;
    }

    const isNew = StringUtil.isEqual(secureId, 'new');

    const canViewLogs = UserHelper.hasPermission(userInfo, UserPermissions.view_action_logs);

    useEffect(() => {
        let isCancelled = false;

        function onUnload() {
            isCancelled = true;

            logger.debug('unload event logs');
        }

        async function loadEventLogs() {
            const resp = await PolicyApiClient.loadEventLogs(po.SecureId);

            if (resp.hasError) {
              return;
            }
      
            const eventLogs = resp.data.EventLogs;

            if (!isCancelled) {
                setInitLoadData({
                    loading: false,
                    eventLogs: eventLogs
                });
            }
        }

        loadEventLogs();

        return onUnload;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if(initLoadData.loading)
    {
        return null; // todo -- return loader, etc ...
    }
    if(!canViewLogs) {
        return null;
    }
    if(StringUtil.isNullOrEmpty(secureId) || isNew) {
        return null;
    }
    if(initLoadData.eventLogs === null || initLoadData.eventLogs.length === 0) {
        return null;
    }

    const controls = initLoadData.eventLogs.map((l, index) => {
        let initials;
        let createdByName;

        if (StringUtil.isNullOrEmpty(l.CreatedByName)) {
            initials = l.CreatedById;
            createdByName = l.CreatedById;
        }
        else {
            initials = l.CreatedByName.split(" ").map(x => x[0]).join('');
            createdByName = l.CreatedByName;
        }

        return <Grid key={index} container wrap="nowrap" spacing={2}>
            <Grid item>
                <Tooltip title={createdByName}>
                    <Avatar className={classes.small}>{initials}</Avatar>
                </Tooltip>
            </Grid>
            <Grid item>
                <Typography>{DateFormatUtil.relativeToNowCal(l.CreatedOn)}</Typography>
            </Grid>
            <Grid item xs>
                <Typography>{l.EventDescription}</Typography>
            </Grid>
        </Grid>;
    });

    return <SectionHeader section="actionLogs" title="Action Logs" collapsible={true} iconImgPath={"/img/action_log.png"}>
        {controls}
    </SectionHeader>;
}