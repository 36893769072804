import { Box, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { grey } from "@mui/material/colors";
import { Question, SectionHeader } from "Components/Questionary";
import { Clerk, Lawyer, UnderwritingManager } from "Model/UserRoles";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import StringUtil from "Util/StringUtil";

const useStyles = makeStyles((theme) => ({
  stipDivider: {
    margin: theme.spacing(1, 3, 0, 0),
  },
  container: {
    width: "775px",
    display: "flex",
    flexWrap: "wrap",
    margin: theme.spacing(3, 1.5, 0, 1.5),
    padding: theme.spacing(0, 1.5, 1, 1.5),
    border: `${1}px solid`,
    borderColor: grey[400],
    borderRadius: 5,
  },
  centerAlignText: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    textAlign: "center",
  },
  fixSpacing: {
    marginTop: 9,
  },
}));

function createOrderSelector() {
  return createSelector(
    (state) => state,
    (x) => x
  );
}

function CertificateOfTitleSection(props) {
  const classes = useStyles();

  const { questionary, questionParams, getInitialAnswer, userInfo } = props;

  const selectOrder = useMemo(createOrderSelector, []);

  const solicitorNameFirst = useSelector((x) =>
    selectOrder(x.order.orderVolatileData.SolicitorNameFirst)
  );
  const solicitorNameLast = useSelector((x) =>
    selectOrder(x.order.orderVolatileData.SolicitorNameLast)
  );
  const solicitorSecureId = useSelector((x) =>
    selectOrder(x.order.orderVolatileData.Solicitor)
  );

  const hasPurchaseTransaction = useSelector((x) =>
    selectOrder(x.order.orderChanges.HasPurchaseTransaction)
  );

  const hasExistingOwnerTransaction = useSelector((x) =>
    selectOrder(x.order.orderChanges.HasExistingOwnerTransaction)
  );

  const isMOEOTransaction = useSelector((x) =>
    selectOrder(x.order.orderChanges.IsMOEOTransaction)
  );

  let solicitor = "";

  if (!userInfo) return null;

  if (!StringUtil.isNullOrEmpty(solicitorNameFirst))
    solicitor = solicitorNameFirst;
  if (!StringUtil.isNullOrEmpty(solicitorNameLast))
    solicitor += ` ${solicitorNameLast}`;

  if (StringUtil.isNullEmptyOrWhiteSpace(solicitor))
    solicitor = "{solicitor name}";

  const role = userInfo.Role;

  const isLawyer = StringUtil.isEqual(role, Lawyer);
  const isClerk = StringUtil.isEqual(role, Clerk);
  const isParalegal = StringUtil.isEqual(role, "Paralegal");
  const isUnderwritingManager = StringUtil.isEqual(role, UnderwritingManager);

  let isSubmittedByDifferentLawyer = isLawyer && !StringUtil.isEqual(userInfo.SecureId, solicitorSecureId);

  const onBehalfOfLawyerText = `${isSubmittedByDifferentLawyer ? `
        •	I, ${userInfo.FirstName} ${userInfo.LastName}, have authority to give this Certificate of Title on behalf of lawyer ${solicitor}.;` : ``}`;

  let certificateDescription = `To: Lawyers’ Professional Indemnity Company (LAWPRO)

I confirm that:
        ${onBehalfOfLawyerText}
        •	I am a lawyer in good standing with my Law Society, entitled to act on this transaction;
        •	I have investigated title and other matters relating to the insured real property as prescribed by 
        my law Society, except for those that you do not require;
        •	In my opinion, insofar as those investigations revealed, each insured party will obtain a good and 
        marketable interest in the property as described in Schedule A, subject to any exceptions in Schedule B;
        •	I have disclosed all matters that would otherwise qualify my opinion;
        •	I will advise you of any additional title registrations or material changes to the information 
        submitted that I become aware of before closing; and
        •	I will disclose all exceptions in Schedule B to the purchaser, mortgagor and/or mortgage lender, 
        as applicable;`;

  if (hasExistingOwnerTransaction) {
    certificateDescription = `To: Lawyers’ Professional Indemnity Company (LAWPRO)

I confirm that:
        ${onBehalfOfLawyerText}
        •	I am a lawyer in good standing with my Law Society, entitled to act on this policy application;
        •	I have investigated title and other matters relating to the insured real property as prescribed by
        my Law Society, except for those that you do not require;
        •	In my opinion, insofar as those investigations revealed, each insured party has a good and marketable
        title in the property as described in Schedule A, subject to any exceptions in Schedule B;
        •	I have disclosed all matters that would otherwise qualify my opinion;
        •	I will advise you of any additional title registrations or material changes to the information
        submitted that I become aware of before the policy is issued;
        •	I will disclose all exceptions in Schedule B to the owner(s); and
        •	I will have the owner(s) swear the TitlePLUS Declaration and Acknowledgment.`;
  }

  if (isMOEOTransaction) {
    certificateDescription = `To: Lawyers’ Professional Indemnity Company (LAWPRO)

I confirm that:
        ${onBehalfOfLawyerText}
        •	I am a lawyer in good standing with my Law Society, entitled to act on this policy application;
        •	I have investigated title and other matters relating to the insured real property as prescribed by my
        Law Society, except for those that you do not require;
        •	In my opinion, insofar as those investigations revealed, each insured party has or will obtain
        a good and marketable title or interest in the property as described in Schedule A, subject to any
        exceptions in Schedule B;
        •	I have disclosed all matters that would otherwise qualify my opinion;
        •	I will advise you of any additional title registrations or material changes to the information
        submitted that I become aware of before the policy is issued or the mortgage transaction closes;
        •	I will disclose all exceptions in Schedule B to the owner(s), mortgagor and/or mortgage lender,
        as applicable; and
        •	I will have the owner(s) swear the TitlePLUS Declaration and Acknowledgment.`;
  }

  if (isClerk || isParalegal || isUnderwritingManager)
    certificateDescription = `I ${userInfo.FirstName} ${userInfo.LastName} have authority to give this Certificate of Title on behalf of lawyer 
${solicitor}.
        
${certificateDescription}`;

  if (hasPurchaseTransaction) {
    // certificateDescription = `${certificateDescription}
    // •	I will have the purchaser(s) sign the TitlePLUS Acknowledgment.`;
    certificateDescription = `${certificateDescription}
        •	(Ontario and Atlantic Canada): I will have the purchaser(s) sign the TitlePLUS Acknowledgment.
        •	(Western Canada): I will send the Confirmation to Purchaser to the purchaser(s).`;
  }

  return (
    <React.Fragment>
      <SectionHeader
        section="acknowledgement"
        title="Acknowledgement"
        questionary={questionary}
        subSection
        iconImgPath={"/img/certificate-of-title.svg"}
      />
      <Box className={classes.container}>
        <Box className={`${classes.centerAlignText}`}>
          <SectionHeader
            section="certificateOfTitle"
            title="Certificate of Title"
            questionary={questionary}
            subSection
          />
        </Box>
        <Typography component="pre" style={{whiteSpace:"pre-wrap"}}>
          {certificateDescription}
          </Typography>
        
        <Question
          qId="CertificateOfTitleAcknowledgement"
          {...questionParams}
          indexer={null}
          hideLabel
        />
      </Box>
    </React.Fragment>
  );
}

export default CertificateOfTitleSection;
