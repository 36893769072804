import React, { Fragment, useMemo } from "react";
import { Question } from "Components/Questionary";
import { QuestionaryHelper } from "Util/QuestionaryHelper";
import { StringUtil } from "Util/Helpers";
import { SectionHeader } from "Components/Questionary";
import { TransactionTypeEnum } from "Model/PolicyOrder";
import RemainingMortgagesSection from "./RemainingMortgagesSection";
import Conditional from "Policy/Conditional";
import { useSelector } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";

function createOrderChangesSelector() {
  return createSelector(
    (state) => state.order.orderChanges,
    (_, propName) => propName,
    (x, propName) => x[propName]
  );
}

//Need to optimize this
function RemainingMortgages(props) {
  const { item, questionParams, collectionName, conditionalProps } = props;

  questionParams.indexer = QuestionaryHelper.createIndexer(
    collectionName,
    item
  );

  const state = {
    remainingMortgages: useSelector((x) => x.order.remainingMortgages),
  };

  const remainingMortgages = state.remainingMortgages.filter((x) =>
    StringUtil.isEqual(x.MortgagePropertyId, item.Id)
  );

  return (
    <Fragment>
      <Conditional
        upon="CoverageType"
        when={(x) =>
          StringUtil.isEither(x, "owner_and_lender", "lender_policy_only")
        }
        {...conditionalProps}
      >
        <Conditional
          upon="IsMortgagePresent"
          when={(x) => StringUtil.isYes(x)}
          {...conditionalProps}
        >
          <Question qId="AreMortgagesRemaining" {...questionParams}>
            <Conditional
              upon="AreMortgagesRemaining"
              when={(x) => StringUtil.isYes(x)}
              {...conditionalProps}
            >
              <RemainingMortgagesSection
                {...props}
                itemsList={remainingMortgages}
                propertyId={item.Id}
              />
            </Conditional>
          </Question>
        </Conditional>
      </Conditional>

      <Conditional
        upon="TransactionType"
        when={(x) => StringUtil.isEqual(x, TransactionTypeEnum.MortgageOnly)}
        {...conditionalProps}
      >
        <Conditional
          upon="HasMortgageClosingPriorityOfLessThan1"
          when={(x) => x == true}
          {...conditionalProps}
        >
          <Question qId="AreMortgagesRemaining" {...questionParams}>
            <Conditional
              upon="AreMortgagesRemaining"
              when={(x) => StringUtil.isYes(x)}
              {...conditionalProps}
            >
              <RemainingMortgagesSection
                {...props}
                itemsList={remainingMortgages}
                propertyId={item.Id}
              />
            </Conditional>
          </Question>
        </Conditional>
      </Conditional>
    </Fragment>
  );
}

function TitleSurvey(props) {
  const {
    item,
    questionParams,
    questionary,
    collectionName,
    conditionalProps,
  } = props;

  questionParams.indexer = QuestionaryHelper.createIndexer(
    collectionName,
    item
  );

  const selectOrderChanges = useMemo(createOrderChangesSelector, []);

  const propertyType = useSelector((x) =>
    selectOrderChanges(x, `${questionParams.indexer}TransactionType`)
  );
  const transactionType = useSelector((x) =>
    selectOrderChanges(x, `${questionParams.indexer}PropertyType`)
  );

  if (!propertyType || !transactionType) return null;

  return (
    <Fragment>
      <SectionHeader
        section="titleSurvey"
        title="Title/Survey"
        questionary={questionary}
        subSection
      />
      <RemainingMortgages {...props} />
      <Question
        qId="AdverseMattersFromTitleSearch"
        {...questionParams}
        optional
      />
      <Question qId="SurveyDone" {...questionParams} />
      <Conditional
        upon="SurveyDone"
        when={(x) => StringUtil.isEqual(x, "yes_w_discrepancies")}
        {...conditionalProps}
      >
        <Question
          qId="SurveyDiscrepancies"
          {...questionParams}
          style={{ border: "1px" }}
        />
      </Conditional>
      <Question qId="ExecAgainstRegisteredOwners" {...questionParams} />

      <Conditional
        upon="TransactionType"
        when={(x) =>
          StringUtil.isEither(
            x,
            TransactionTypeEnum.ExistingOwner,
            TransactionTypeEnum.New,
            TransactionTypeEnum.Resale
          )
        }
        {...conditionalProps}
      >
        <Question qId="AdjacentToWater" {...questionParams} />
      </Conditional>

      <Conditional
        upon="TransactionType"
        when={(x) => StringUtil.isEqual(x, TransactionTypeEnum.MortgageOnly)}
        {...conditionalProps}
      >
        <Conditional
          upon="AddExistingOwnerPolicy"
          when={(x) => StringUtil.isYes(x)}
          {...conditionalProps}
        >
          <Question qId="AdjacentToWater" {...questionParams} />
        </Conditional>
      </Conditional>

      <Question qId="UtToDischargeAllMortgages" {...questionParams} />
    </Fragment>
  );
}

export default TitleSurvey;
