import { createAction } from '@reduxjs/toolkit'

export const reset = createAction('project/reset');
export const set_new_project = createAction('project/set_new_project');
export const setProjectInput = createAction('project/setProjectInput');

export const clearValidationError = createAction('project/clearValidationError');
export const setValidationResult = createAction('project/setValidationResult');
export const setError = createAction('project/setError');
export const clearError = createAction('project/clearError');
export const setBusy = createAction('project/setBusy');

export const save = createAction('project/save');
export const addProperty = createAction('project/addProperty');
export const removeProperty = createAction('project/removeProperty');
export const addException = createAction('project/addException');
export const removeException = createAction('project/removeException');
export const addExceptionRange = createAction('project/addExceptionRange');
export const removeExceptionRange = createAction('project/removeExceptionRange');