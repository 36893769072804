import { DocumentsApiClient } from 'Policy/DocumentsApiClient';
import BrowserHelper from 'Util/BrowserHelper';
import Logger from 'Util/Logger';
import * as PolicyActions from './PolicyActions';

const logger = new Logger("DocumentThunks");

export function generatePolicyDocument(orderSecureId, dealNumber, policyNumber, documentType) {
    return async function (dispatch, getState) {
        const currState = getState();

        dispatch({ type: PolicyActions.generatingPDF.type, payload: { orderSecureId, dealNumber, policyNumber, documentType } });

        const resp = await DocumentsApiClient.generatePolicyDocument(orderSecureId, dealNumber, policyNumber, documentType);

        dispatch({ type: PolicyActions.generatingPDF.type, payload: {} }); // not generating ...

        if (resp.hasError) {
            dispatch({ type: PolicyActions.setActionMessage.type, payload: { type: 'error', message: resp.errorMessage } });

            return;
        }

        const respData = resp.data;

        const file = new Blob([respData], { type: 'application/pdf' });

        if(BrowserHelper.isIE11()) {
            // this will auto-download the file. Only thing we can do on IE 11...
            
            window.navigator.msSaveOrOpenBlob(file, `${policyNumber}.pdf`);

            return;
        }

        const dataUrl = window.URL.createObjectURL(file); // WE MUST DISPOSE IT WHEN WE CLOSE THE DOC to release memory
        
        dispatch({ type: PolicyActions.viewGeneratedPDF.type, payload: { orderSecureId, dealNumber, policyNumber, documentType, dataUrl, label: "Policy PDF" } });
    }
}

export function generateInvoice(orderSecureId, dealNumber) {
    return async function (dispatch, getState) {
        const currState = getState();

        dispatch({ type: PolicyActions.generatingPDF.type, payload: { orderSecureId, dealNumber } });

        const resp = await DocumentsApiClient.generateInvoice(orderSecureId);

        dispatch({ type: PolicyActions.generatingPDF.type, payload: {} }); // not generating ...

        if (resp.hasError) {
            dispatch({ type: PolicyActions.setActionMessage.type, payload: { type: 'error', message: resp.errorMessage } });

            return;
        }

        const respData = resp.data;

        const file = new Blob([respData], { type: 'application/pdf' });

        if(BrowserHelper.isIE11()) {
            // this will auto-download the file. Only thing we can do on IE 11...
            
            window.navigator.msSaveOrOpenBlob(file, `${dealNumber}.pdf`);

            return;
        }

        const dataUrl = window.URL.createObjectURL(file); // WE MUST DISPOSE IT WHEN WE CLOSE THE DOC to release memory

        dispatch({ type: PolicyActions.viewGeneratedPDF.type, payload: { orderSecureId, dealNumber, dataUrl, label: "Invoice" } });
    }
}

export function viewNewHomeProgramList() {
    return async function (dispatch, getState) {
        const dataUrl = `https://titleplus.ca/wp-content/uploads/2022/11/New-Home-Program-List-of-Current-Projects-Nov-2-2022.pdf`;

        dispatch({ type: PolicyActions.viewGeneratedPDF.type, payload: { dataUrl, label: "TitlePLUS New Home Program List" } });
    }
}
