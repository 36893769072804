import StringUtil from "./StringUtil";
import UriUtil from "./UriUtil";

class TppHelper {
    static isTPOrigin() {
        const ancestorOrigins = document.location.ancestorOrigins;
        let origin = '';

        if (ancestorOrigins == null) //Possibly IE 11
        {
            try {
                origin = window.parent.location.origin;
            }
            catch (ex) {
                console.debug('origin exception thrown');
                return false;
            }
        }
        else
            origin = ancestorOrigins[0];

        if (StringUtil.isNullOrEmpty(origin))
            return true;

        const domain = UriUtil.getDomain(origin);

        return StringUtil.containsAny(domain, 'localhost:3000', 'mytitleplus.ca');
    }
}

export default TppHelper;
