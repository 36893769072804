import React, { useEffect, useState, useMemo } from 'react';
import { Button, CircularProgress, TextField, Select, MenuItem, Typography, Chip, Tooltip } from '@mui/material';
import { alpha } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { grey, deepPurple, brown } from '@mui/material/colors';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import useDebounce from 'Util/UseDebounce';
import { DateFormatUtil } from 'Util/DateFormatUtil';
import './Note.css';
import moment from 'moment';
import AddAlertIcon from '@mui/icons-material/AddAlert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { StringUtil, UserHelper } from 'Util/Helpers';
import { useSelector } from 'react-redux';
import * as UserPermissions from 'Model/UserPermissions';
import * as UserActions from 'User/State/UserActions';
import * as UserRoles from 'Model/UserRoles';

const useStyles = makeStyles((theme) => ({
    centerLoaderExpanded: {
        position: 'absolute',
        top: '40%',
        left: '50%'
    },
    loaderBackdrop: {
        position: 'absolute',
        width: '100%',
        background: alpha(grey[50], 0.5),
        zIndex: 100,
        top: 0,
        left: 0,
        height: '100%'
    },
    multilineInput: {
        minHeight: 200,
        color: "black"
    },
    outlinedChip: {
        color: "green",
        borderColor: "green",
        opacity: "0.5",
        '&:hover, &:focus': {
            backgroundColor: 'green',
            opacity: "1.0"
        },
    },
    resolvedChip: {
        color: "white",
        background: "green",
        borderColor: "green",
        '&:hover, &:focus': {
            backgroundColor: 'green',
            opacity: "0.5"
        },
        visibility: "visible"
    },
    datePicker: {
        fontWeight: 600,
        fontSize: "0.75rem",
        color: "grey",
        marginTop: 4
    },
    title: {
        width: "100%",
        fontWeight: 700,
        color: "blue",
        display: "flex",
        alignItems: "center"
    },
    tooltip: {
        whiteSpace: "pre-line"
    },
    iconSmall: {
        fontSize: "1.00rem",
    },
    visibilityTextbox: {
        fontWeight: 600,
        fontSize: "0.75rem",
        color: "grey"
    },
    footerSplitterLeft: {
        width: "25%"
    },
    footerSplitterRight: {
        width: "75%",
        justifyContent: "flex-end"
    },
    externalNote: {
        background: brown[50],
        '&:after': {
            borderBottom: `solid 8px ${brown[100]}`,
            borderLeft: `solid 8px ${brown[100]}`,
            borderRight: "solid 8px #fafafa",
            borderTop: "solid 8px #fafafa",
        }
    }
}))

function Note(props) {

    const {
        onDeleteAsync,
        onSaveAsync,
        onCloseNote,
    } = props;

    const classes = useStyles();

    const [note, setNote] = useState(props.note);
    const [changeCount, setChangeCount] = useState(0);
    const [saving, setSaving] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const debouncedSaving = useDebounce(changeCount, 1000);
    const exceptions = ["CreatedById", "CreatedByName", "CreatedOn", "SecureId", "Visibility", "FollowupDate"];

    //#region  Hooks

    const selectUserInfo = useMemo(
        UserActions.createUserInfoSelector,
        []
    );
    
    const userInfo =  useSelector(x => selectUserInfo(x));

    const canDeleteNote = UserHelper.hasPermission(userInfo, UserPermissions.delete_note);
    const canChangeNote = UserHelper.hasPermission(userInfo, UserPermissions.change_note);

    useEffect(() => {
        if (debouncedSaving) {
            setSaving(true);
            onSaveAsync(note).then(resp => {
                setSaving(false);
            });
        }
    }, [debouncedSaving]);

    //#endregion Hooks

    const isExternal = StringUtil.isEqual(note.Visibility, "External");

    //#region Functions

    function updateChangeCount() {     
        setChangeCount(currentVal => currentVal + 1);
    }

    async function handleDelete(event) {
        if(!canDeleteNote) {
            return;
        }
        
        setDeleting(true);

        onDeleteAsync(note.SecureId).then(resp => {
            setDeleting(false);
        });
    }

    function setPropertyVal(fieldName, val) {
        if(!canChangeNote) {
            return;
        }
        
        setNote(currentItem => ({ ...currentItem, [fieldName]: val }));
        
        updateChangeCount();
    }

    function isItemResolved() {
        if (note.Resolved)
            return note.Resolved;

        return false;
    }

    function saveFollowupDate(val) {
        if (moment(val).isValid()) {
            setPropertyVal("FollowupDate", val);
        }
    }

    function retriveValue(fieldName, defaultVal) {
        if (note[fieldName])
            return note[fieldName];

        return defaultVal ? defaultVal : "";
    }

    //#endregion Functions

    //#region Input Props
    const textFieldStylingProps = {
        classes: {
            inputMultiline: classes.multilineInput,
        },
        disableUnderline: true
    };

    const datePickerStylingProps = {
        classes: {
            input: classes.datePicker
        },
        disableUnderline: true
    }

    //#endregion Input Props

    //#region Html

    const dateTime = DateFormatUtil.relativeToNowCal(note.UpdatedOn ? note.UpdatedOn : note.CreatedOn);

    const creatorTooltip = <div className={classes.tooltip}>{
        `Created by: ${note.CreatedByName}
    ${!StringUtil.isNullOrEmpty(note.UpdatedByName) ? `Last updated by: ${note.UpdatedByName}` : ``
        }`}</div>;

    const dateTimeTooltip = <div className={classes.tooltip}> {
        `Created: ${DateFormatUtil.relativeToNowCal(note.CreatedOn)}
    ${!StringUtil.isNullOrEmpty(note.UpdatedOn) ? `Last updated: ${DateFormatUtil.relativeToNowCal(note.UpdatedOn)}` : ``
        }`}</ div>;

    const header = <div className="headerWrapper"
        style={{
            marginTop: "-10px"
        }}
    >
        <Tooltip title={creatorTooltip}>
            <span>{StringUtil.isNullOrEmpty(note.UpdatedByName) ? note.CreatedByName : note.UpdatedByName}&nbsp;</span>
        </Tooltip>
        <Button
            onClick={(event) => onCloseNote(note)}
            disableElevation
        >Close</Button>
    </div>;

    const body = <React.Fragment key={note.Tag}>
        <div className="hoverTarget">
            <div className="watermarkContainer">
                <Typography className={classes.title}>
                    {note.Title}
                    &nbsp;&nbsp;
                    <div className="hoverContent">
                        <Chip
                            label="Resolved"
                            onClick={(event) => setPropertyVal("Resolved", !isItemResolved())}
                            icon={<DoneIcon />}
                            size="small"
                            color="primary"
                            variant={isItemResolved() ? "default" : "outlined"}
                            className={isItemResolved() ? classes.resolvedChip : classes.outlinedChip} />
                    </div>
                </Typography>
                <TextField
                    multiline
                    fullWidth={true}
                    InputProps={textFieldStylingProps}
                    value={note.Value}
                    name={note.Tag}
                    onChange={(event) => setPropertyVal("Value", event.target.value)} />
            </div>
        </div>
    </React.Fragment >;

    const footer = <div className="footerWrapper">
        <div className={classes.footerSplitterLeft}>
            <Tooltip title={dateTimeTooltip}>
                <span>{saving ? "Saving..." : dateTime}</span>
            </Tooltip>
        </div>
        <div className={`${classes.footerSplitterRight} flexWrapper`}>
        {
            (userInfo && StringUtil.isEqual(userInfo.Role, UserRoles.UnderwritingManager)) &&
                <div className="flexWrapper">
                    <Tooltip title="Visibility">
                        <VisibilityIcon size="small" className={classes.iconSmall} />
                    </Tooltip>
                    &nbsp;
                    <Select
                        labelId="label"
                        className={classes.visibilityTextbox}
                        disableUnderline
                        value={retriveValue("Visibility", "Internal")}
                        onChange={event => setPropertyVal("Visibility", event.target.value)}>
                        <MenuItem value="Internal">Internal</MenuItem>
                        <MenuItem value="External">External</MenuItem>
                    </Select>
                </div>
            }
                &nbsp;&nbsp;&nbsp;
                <div className="footerWrapper">
                <Tooltip title="Follow up date">
                    <AddAlertIcon size="small" className={classes.iconSmall} />
                </Tooltip>
                &nbsp;
                <TextField
                    size="small"
                    type="date"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={datePickerStylingProps}
                    value={retriveValue("FollowupDate")}
                    onChange={event => saveFollowupDate(event.target.value)}
                />
            </div>
            {
                canDeleteNote &&
                <Tooltip title="Delete">
                    <DeleteIcon color="action" onClick={handleDelete} />
                </Tooltip>
            }
        </div>
    </div>;

    const deleteLoader = deleting &&
        <div className={classes.loaderBackdrop}>
            <CircularProgress size={35} className={classes.centerLoaderExpanded} />
        </div>;

    //#endregion Html

    return (<div className={`sticky ${isExternal ? classes.externalNote : ""}`}>
        <div className="container">
            {header}
            {body}
            {footer}
            {deleteLoader}
        </div>
    </div>
    );
}

export default Note;
