import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Table,
    TableBody,
    TableRow,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';
import MuiTableCell from "@mui/material/TableCell";
import SyntheticEvent from 'Components/SyntheticEvent';
import { TextFieldDebounced } from 'Components/withOnChangeDebounced';
import React, { useState } from 'react';
import { StringUtil } from 'Util/Helpers';

const useStyles = makeStyles(theme => ({
    error: {
        color: theme.palette.error.main
    }
}));

const TableCell = withStyles({
    root: {
        borderBottom: "none",
        padding: 0
    }
})(MuiTableCell);

function AdverseMattersFromTitleSearch(props) {
    const classes = useStyles();
    const { name, defaultValue, onChange, opts, error, helperText, readOnly } = props;

    if (StringUtil.isNullOrEmpty(opts))
        opts = [];

    let defaultItems = [];

    if (defaultValue.length > 0) {
        defaultItems = defaultValue;
    }

    const [items, setItems] = useState(defaultItems);

    function isChecked(option) {
        const result = items.some(x => StringUtil.isEqual(x.Issue, option));

        return result;
    }

    function getInstrumentNumber(option) {
        const result = items.find(x => StringUtil.isEqual(x.Issue, option));

        return result.InstrumentNumber;
    }

    const handleCheckBoxInputChange = option => event => {
        if (readOnly)
            return;

         

        const checked = event.target.checked;
        let changed = false;
        const selectedItems = [...items];

        if (!checked) {
            const idx = items.map(x => x.Issue).indexOf(option);

            if (idx !== -1) {
                selectedItems.splice(idx, 1);

                changed = true;
            }
        }
        else {
            const item = {
                Issue: option
            };
            selectedItems.push(item);

            changed = true;
        }

        if (changed) {
            setItems(selectedItems); // this will re-render the entire control ...
            const ev = new SyntheticEvent(name, selectedItems);
            onChange(ev);
        }
    }

    const handleTextFieldInputChange = option => event => {
        if (readOnly)
            return;

         

        const value = event.target.value;

        let changed = false;

        const selectedItems = [...items];

        const item = items.find(x => StringUtil.isEqual(x.Issue, option));

        if (!item)
            return;

        if (!StringUtil.isEqual(item.InstrumentNumber, value))
            changed = true;

        if (changed) {
            const idx = items.map(x => x.Issue).indexOf(option);

            if (idx !== -1)
                selectedItems.splice(idx, 1);

            const item = {
                Issue: option,
                InstrumentNumber: value
            };

            selectedItems.push(item);

            setItems(selectedItems);

            const ev = new SyntheticEvent(name, selectedItems);

            onChange(ev);
        }
    }

    return (
        <FormControl error={error} component="fieldset" aria-label={name} name={name}>
            <Table>
                <TableBody>
                    {opts.map((opt) =>
                        <TableRow key={opt.Id}>
                            <TableCell>
                                <FormControlLabel
                                    value={opt.Id}
                                    control={<Checkbox color="primary"
                                        checked={isChecked(opt.Id)}
                                        onChange={handleCheckBoxInputChange(opt.Id)}
                                    />}
                                    label={opt.Label}
                                    labelPlacement="end"
                                />
                            </TableCell>
                            <TableCell>
                                {isChecked(opt.Id) &&
                                    <TextFieldDebounced
                                        defaultValue={getInstrumentNumber(opt.Id)}
                                        onChange={handleTextFieldInputChange(opt.Id)}
                                        InputProps={{ readOnly: readOnly }}
                                        error={error}
                                    />}
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            {!StringUtil.isNullOrEmpty(helperText) &&
                <FormHelperText style={{ marginBottom: '0.8rem' }}>{helperText}</FormHelperText>
            }
        </FormControl>
    );
}

export default AdverseMattersFromTitleSearch;