import React, { useMemo } from 'react';
import { Question } from 'Components/Questionary';
import { QuestionaryHelper } from 'Util/QuestionaryHelper';
import { Typography, Box, IconButton, Tooltip, FormGroup, FormControl } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { grey } from '@mui/material/colors';

const useStyles = makeStyles((theme) => ({
    header: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        backgroundColor: grey[200]
    },
    orBreak: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    nameSection: {
        marginTop: theme.spacing(1)
    }
}));

function inputValueSelector() {
    return createSelector(
        state => state,
        (_, fieldName) => fieldName,
        (x, fieldName) => x[fieldName] // case sensitive! ...
    );
}

function Purchaser(props) {
    const { item, questionParams, collectionName } = props;

    const classes = useStyles();

    questionParams.indexer = QuestionaryHelper.createIndexer(collectionName, item);

    const selectInputVal = useMemo(
        inputValueSelector,
        []
    );

    const disableCorporationName = useSelector(x => selectInputVal(x.order.orderChanges, `${questionParams.indexer}${'ShouldDisableCorporationName'}`));
    const disableIndividualName = useSelector(x => selectInputVal(x.order.orderChanges, `${questionParams.indexer}${'ShouldDisableIndividualName'}`));

    const groupName = "PurchaserName";

    return <Box m={2}>
        <FormControl name={groupName} area-label={groupName} className={classes.nameSection}>
            <FormGroup row>
                <Question qId={`${groupName}First`} {...questionParams} short inline disabled={disableIndividualName} txtWidth={30} />
                <Question qId={`${groupName}Middle`} {...questionParams} short inline optional disabled={disableIndividualName} txtWidth={30} />
                <Question qId={`${groupName}Last`} {...questionParams} short inline disabled={disableIndividualName} txtWidth={30} />
            </FormGroup>
        </FormControl>
        <div className={classes.orBreak}>
            <Typography>--- OR ---</Typography>
        </div>
        <div style={{ width: '104ch' }}>
            <Question qId={`${groupName}Corporation`} {...questionParams} optional disabled={disableCorporationName} txtWidth={60} />
        </div>
    </Box>;
}

function PurchaserInfo(props) {
    const { item, idx, canAdd, add, remove } = props;

    const classes = useStyles();

    const isFirst = idx === 0;

    return (
        <React.Fragment>
            <Box p={1} display="flex" className={classes.header}>
                <Box flexGrow={1}>
                    <Typography>Purchaser {idx + 1}</Typography>
                </Box>
                <Box mr={1}>
                    <Tooltip title="Add Purchaser">
                        <span>
                            <IconButton
                                aria-label="add"
                                size="small"
                                disabled={!canAdd}
                                onClick={add}
                            >
                                <AddIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                </Box>
                <Box>
                    <Tooltip title="Remove">
                        <span>
                            <IconButton
                                aria-label="remove"
                                size="small"
                                disabled={isFirst}
                                onClick={() => { remove(item) }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                </Box>
            </Box>
            <Purchaser {...props} />
        </React.Fragment>
    );
}

export default PurchaserInfo;