import { Box, TableCell, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Question } from 'Components/Questionary';
import React, { Fragment, useMemo } from "react";
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { QuestionaryHelper } from 'Util/QuestionaryHelper';
import StringUtil from 'Util/StringUtil';

const useStyles = makeStyles((theme) => ({
    suffix: {
        textTransform: "capitalize",
        marginLeft: theme.spacing(0.5)
    }
}));


function createOrderSelector() {
    return createSelector(
        state => state,
        x => x
    );
}

function MortgagePriorityPrefix(props) {
    const { idx, item, collectionName, questionParams } = props;
    questionParams.indexer = QuestionaryHelper.createIndexer(collectionName, item);

    const select = useMemo(
        createOrderSelector,
        []
    );

    const mortgageInfo = useSelector(x => select(x.order.orderVolatileData.Mortgages[idx]));

    return mortgageInfo ? <Fragment>{StringUtil.stringifyNumber(StringUtil.toDecimal(mortgageInfo.PriorityUponClosing))} Mortgage:</Fragment> : null
}

function MortgageInfo(props) {
    const classes = useStyles();
    const { idx, item, collectionName, questionParams } = props;

    if (!item)
        return null;

    questionParams.indexer = QuestionaryHelper.createIndexer(collectionName, item);

    return <Fragment>
        <TableCell>
            <Typography>
                Lender Policy {item.PolicyNumber}
            </Typography>
        </TableCell>
        <TableCell>
            <Typography className={classes.suffix}>
                {/* {`${StringUtil.stringifyNumber(StringUtil.toDecimal(priorityOfClosing))} Mortgage:`} */}
                <MortgagePriorityPrefix {...props} />
            </Typography>
            <Question qId="MortgageLenderName" {...questionParams} displayOnly hideLabel />
        </TableCell>
        <TableCell>
            <Box style={{ width:90, textAlign:'right' }}>
                <Question qId="MortgagePrincipalAmount" {...questionParams} displayOnly hideLabel />
            </Box>
        </TableCell>
    </Fragment>;
}

export default MortgageInfo;
