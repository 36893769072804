import { createAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import StringUtil from 'Util/StringUtil';

export const set_private_lenders_list = createAction('admin/private_lenders_list');
export const set_private_lenders_list_loading = createAction('admin/private_lenders_list_loading');
export const set_private_lenders_list_loading_error = createAction('admin/private_lenders_list_loading_error');
export const set_private_lenders_list_total_records = createAction('admin/private_lenders_list_total_records');
export const set_private_lenders_list_total_pages = createAction('admin/private_lenders_list_total_pages');
export const set_private_lender = createAction('admin/set_private_lender');
export const create_private_lender = createAction('admin/create_private_lender');
export const update_private_lender = createAction('admin/update_private_lender');
export const delete_private_lender = createAction('admin/delete_private_lender');
export const action_private_lender_busy = createAction('admin/action_private_lender');
export const set_private_lender_loading_error = createAction('admin/set_private_lender_loading_error');
export const set_private_lender_handle_change = createAction('admin/set_private_lender_handle_change');

export function createPrivateLendersListSelector() {
    return createSelector(
        state => state.privateLenders.private_lenders_list,
        x => x
    );
}

export function createPrivateLendersListLoadingSelector() {
    return createSelector(
        state => state.privateLenders.private_lenders_list_loading,
        x => x
    );
}

export function createPrivateLendersListLoadingErrorSelector() {
    return createSelector(
        state => state.privateLenders.private_lenders_list_loading_error,
        x => x
    );
}

export function createPrivateLendersListTotalRecordsSelector() {
    return createSelector(
        state => state.privateLenders.private_lenders_list_total_records,
        x => x
    );
}

export function createPrivateLendersListTotalPagesSelector() {
    return createSelector(
        state => state.privateLenders.private_lenders_list_total_pages,
        x => x
    );
}

export function createPrivateLenderSelector() {
    return createSelector(
        state => state.privateLenders.private_lender,
        x => x
    );
}

export function createPrivateLenderBusySelector() {
    return createSelector(
        state => state.privateLenders.private_lender_busy,
        x => x
    );
}

export function createPrivateLenderErrorSelector() {
    return createSelector(
        state => state.privateLenders.private_lender_loading_error,
        x => x
    );
}

export function updatePrivateLenderSelector() {
    return createSelector(
        state => state.privateLenders.privateLender,
        x => x
    );
}

export function deletePrivateLenderSelector() {
    return createSelector(
        state => state.privateLenders.privateLender,
        x => x
    );
}

export function changePrivateLenderInputSelector() {
    return createSelector(
        state => state.privateLenders.privateLender,
        x => x
    );
}
