import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { StringUtil } from 'Util/Helpers';
import { Info as InfoIcon } from '@mui/icons-material';

const useStyles = makeStyles(theme => ({
  errorMsg: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'pre-wrap'
  },
  icon: {
    marginRight: theme.spacing(0.5)
  },
  warning: {
    backgroundColor: theme.palette.warning.main
  },
  success: {
    backgroundColor: theme.palette.success.main
  },
  info: {
    backgroundColor: theme.palette.info.main
  }
}));

function ErrorSnackbar(props) {
  const classes = useStyles();

  const { message, variant, centered, errorCode, ...other } = props;
  let centeredStyle = {};

  if (centered) {
    centeredStyle = {
      display: "flex",
      justifyContent: "center"
    }
  }

  if (StringUtil.isNullOrEmpty(message))
    return null;

  if (StringUtil.isEqual(variant, 'success')) {
    return (
      <Box className={classes.success} color="error.contrastText" p={1} borderRadius="3px" {...other} {...centeredStyle}>
        <span className={classes.errorMsg}><CheckCircleIcon className={classes.icon} />{message}</span>
      </Box>
    );
  }

  if (StringUtil.isEqual(variant, 'info')) {
    return (
      <Box className={classes.info} color="error.contrastText" p={1} borderRadius="3px" {...other} {...centeredStyle}>
        <span className={classes.errorMsg}><InfoIcon className={classes.icon} />{message}</span>
      </Box>
    );
  }

  if (StringUtil.isEqual(variant, 'warning')) {
    return (
      <Box className={classes.warning} color="error.contrastText" p={1} borderRadius="3px" {...other} {...centeredStyle}>
        <span className={classes.errorMsg}><ErrorIcon className={classes.icon} />{message}</span>
      </Box>
    );
  }

  return (
    <Box bgcolor="error.main" color="error.contrastText" p={1} borderRadius="3px" {...other} {...centeredStyle}>
      <span className={classes.errorMsg}><ErrorIcon className={classes.icon} />{message}{!StringUtil.isNullOrEmpty(errorCode) && ` [code ${errorCode}]`}</span>
    </Box>
  );
}

ErrorSnackbar.propTypes = {
  message: PropTypes.string.isRequired,
  errorCode: PropTypes.string,
  variant: PropTypes.oneOf(['', 'error', 'warning', 'success', 'info']).isRequired,
};

export default ErrorSnackbar;