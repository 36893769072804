import React, { useState } from 'react';
import { FormGroup, FormControl, FormControlLabel, Checkbox, FormHelperText } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { StringUtil } from 'Util/Helpers';
import SyntheticEvent from './SyntheticEvent';

const useStyles = makeStyles(theme => ({
  error: {
    color: theme.palette.error.main
  }
}));

function CheckBoxOptionGroup(props) {
  const { name, onChange, defaultValue, readOnly, disabled, error, helperText, column } = props;  
  const row = column != true ? true : false;

  const classes = useStyles();

  let opts = props.opts;

  if (StringUtil.isNullOrEmpty(opts))
    opts = [];

  let selectedDefault = null;

  if (!StringUtil.isNullOrEmpty(defaultValue)) {
    selectedDefault = defaultValue;
  }

  const [selected, setSelected] = useState(selectedDefault);

  function isChecked(option) {
    const result = StringUtil.isEqual(selected, option);

    return result;
  }

  const handleInputChange = option => event => {
     

    if(readOnly || disabled) {
      return;
    }

    const checked = event.target.checked;

    let selectedNew = option;

    if (!checked)
      selectedNew = null;

    setSelected(selectedNew); // this will re-render the entire control ...

    const selectedOption = selectedNew;

    const ev = new SyntheticEvent(name, selectedOption);

    onChange(ev);
  }

  const classN = error ? classes.error : null;
  
  // this is an unctonrolled component...
  return (
    <FormControl error={error} component="fieldset" aria-label={name} name={name} className={classN}>
      <FormGroup row={row}>
        {opts.map((opt) =>
          <FormControlLabel
            key={opt.Id}
            value={opt.Id}
            control={<Checkbox color="primary" className={classN} checked={isChecked(opt.Id)} onChange={handleInputChange(opt.Id)} disabled={disabled} />}
            label={opt.Label}
            labelPlacement="end"
          />
        )}
      </FormGroup>
      {!StringUtil.isNullOrEmpty(helperText) &&
        <FormHelperText style={{ marginBottom: '0.8rem' }}>{helperText}</FormHelperText>
      }
    </FormControl>
  );
}

export default CheckBoxOptionGroup;
