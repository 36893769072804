import { AppSettings } from 'AppSettings';
import * as AppErrors from 'Model/AppErrors';
import { ApiHelper } from 'Util/ApiHelper';
import { StringUtil } from 'Util/Helpers';
import Logger from 'Util/Logger';
import ApiResponse from 'Model/ApiResponse';
import { QuestionaryHelper } from 'Util/QuestionaryHelper';

const urljoin = require('url-join');

class UserApiClient {
    logger;

    constructor() {
        this.logger = new Logger(UserApiClient.name);
    }

    async registerUser(inputs, removedUserAssociations, validateForPayment) {
        const req = {
            RemovedUserAssociations: removedUserAssociations,
            ValidateForPayment: validateForPayment
        };

        for (const fieldName in inputs) {
            const answer = inputs[fieldName];

            if(!QuestionaryHelper.houseNestedAnswer(fieldName, answer, req)) {
                req[fieldName] = answer;
            }
        }
        
        const url = urljoin(AppSettings.urls.policy, '/user/register');

        const authHeaders = await ApiHelper.authHeaders();

        let apiResp;

        try {
            const response = await fetch(url, {
                body: JSON.stringify(req),
                method: "POST",
                headers: {
                    ...authHeaders
                }
            });

            apiResp = ApiHelper.deserializeJsonResponse(response);
        } catch (ex) {
            this.logger.error('error registering user', ex);

            const errMsg = AppErrors.generic;

            apiResp = new ApiResponse(true);
            apiResp.error = errMsg;
        }

        return apiResp;
    }


    async loadUserAssociationHistory(secureId){        
        
        const url = urljoin(AppSettings.urls.policy, '/user/getUserAssociationHistory');
        const req = {
            UserAssociationId: secureId
        };

        const authHeaders = await ApiHelper.authHeaders();
        let apiResp;

        try {
            const response = await fetch(url, {
                body: JSON.stringify(req),
                method: "POST",
                headers: {
                    ...authHeaders,
                    'pragma': 'no-cache',
                    'cache-control': 'no-cache'
                }
            });

            apiResp = ApiHelper.deserializeJsonResponse(response);
        } catch (ex) {
            this.logger.error('error loading association history', ex);

            const errMsg = AppErrors.generic;

            apiResp = new ApiResponse(true);
            apiResp.error = errMsg;
        }

        return apiResp;
    }

    async loadUserAssociations(secureId){        
        const req = {
            EffectiveSecureId: secureId
        };
        
        const url = urljoin(AppSettings.urls.policy, '/admin/loadUserAssociations');

        const authHeaders = await ApiHelper.authHeaders();
        let apiResp;

        try {
            const response = await fetch(url, {
                body: JSON.stringify(req),
                method: "POST",
                headers: {
                    ...authHeaders,
                    'pragma': 'no-cache',
                    'cache-control': 'no-cache'
                }
            });

            apiResp = ApiHelper.deserializeJsonResponse(response);
        } catch (ex) {
            this.logger.error('error loading user association', ex);

            const errMsg = AppErrors.generic;

            apiResp = new ApiResponse(true);
            apiResp.error = errMsg;
        }

        return apiResp;
    }

    async addUserAssociation(effectiveUserId, firstName, lastName, email, associatingUserRole, lsn) {    
        const req = {
            effectiveUserId,
            firstName,
            lastName,
            email,
            associatingUserRole,
            lsn
        };
                
        const url = urljoin(AppSettings.urls.policy, '/user/addUserAssociation');

        const authHeaders = await ApiHelper.authHeaders();

        let apiResp;

        try {
            const response = await fetch(url, {
                body: JSON.stringify(req),
                method: "POST",
                headers: {
                    ...authHeaders,
                    'pragma': 'no-cache',
                    'cache-control': 'no-cache'
                }
            });

            apiResp = ApiHelper.deserializeJsonResponse(response);
        } catch (ex) {
            this.logger.error('error adding user association', ex);

            const errMsg = AppErrors.generic;

            apiResp = new ApiResponse(true);
            apiResp.error = errMsg;
        }

        return apiResp;
    }


    async setUserAssociationStatus(id, status, effectiveUserId) {
        const req = {
            UserAssociationId: id,
            AssociationStatus: status,
            EffectiveUserId: effectiveUserId
        };
        
        const url = urljoin(AppSettings.urls.policy, '/user/setUserAssociationStatus');

        const authHeaders = await ApiHelper.authHeaders();

        let apiResp;

        try {
            const response = await fetch(url, {
                body: JSON.stringify(req),
                method: "POST",
                headers: {
                    ...authHeaders
                }
            });

            apiResp = ApiHelper.deserializeJsonResponse(response);
        } catch (ex) {
            this.logger.error('error setting user association status', ex);

            const errMsg = AppErrors.generic;

            apiResp = new ApiResponse(true);
            apiResp.error = errMsg;
        }

        return apiResp;
    }

    async migrateLawPROUser(oktaUserId) {
        const req = {
            OktaUserId: oktaUserId
        };
        
        const url = urljoin(AppSettings.urls.policy, '/user/migrateLawPROUser');

        let apiResp;

        try {
            const response = await fetch(url, {
                body: JSON.stringify(req),
                method: "POST"
            });

            apiResp = ApiHelper.deserializeJsonResponse(response);
        } catch (ex) {
            this.logger.error('error migrating LawPRO user', ex);

            const errMsg = AppErrors.generic;

            apiResp = new ApiResponse(true);
            apiResp.error = errMsg;
        }

        return apiResp;
        
    }

    async getOmsObjectId(lsn) {
        const req = {
            LSN: lsn
        };
        
        const url = urljoin(AppSettings.urls.policy, '/user/getOmsObjectId');

        let apiResp = null;

        try {

            const authHeaders = await ApiHelper.authHeaders();

            const response = await fetch(url, {
                body: JSON.stringify(req),
                method: "POST",
                headers: {
                    ...authHeaders
                }
            });

            apiResp = ApiHelper.deserializeJsonResponse(response);
        } catch (ex) {
            this.logger.error('Cannot get oms object id for lsn: ' + lsn + " url: " + url, ex);
        }

        return apiResp;
    }
}

const instance = new UserApiClient();

export { instance as UserApiClient }
