import React from 'react';
import SupplementaryDbMatch from '../Policy/SupplementaryDbMatch'

function SupplementaryDbMatchResult(props) {
    return <React.Fragment>
        <br />
        <b>{props.name}</b> <br />

        {props.matches?.map(x => <SupplementaryDbMatch {...x} />)}
    </React.Fragment>
}

export default SupplementaryDbMatchResult;
