import React, { useState, useMemo } from 'react';
import { Button, Slide, TextField, Icon, Tooltip, CircularProgress, Snackbar, InputAdornment, IconButton, MenuItem, Select, FormControl, InputLabel, Collapse, Box } from '@mui/material';
import { alpha } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { IdGenerator, StringUtil, UserHelper } from 'Util/Helpers';
import Note from './Note2.3'
import NotePreview from './NotePreview';
import './Note.css';
import { PolicyApiClient } from 'Policy/PolicyApiClient';
import { grey } from '@mui/material/colors';
import { ValidationHelper } from 'Util/ValidationHelper';
import MuiAlert from '@mui/material/Alert';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useSelector } from 'react-redux';
import * as UserPermissions from 'Model/UserPermissions';
import * as UserActions from 'User/State/UserActions';
import * as UserRoles from 'Model/UserRoles';
const useStyles = makeStyles((theme) => ({
    boxAlign: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
        }
    },
    iconRoot: {
        height: 24,
    },
    imageIcon: {
        height: 20
    },
    loaderBackdrop: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        background: alpha(grey[50], 0.5),
        zIndex: 100,
        top: 0,
        left: 0
    },
    centerLoaderExpanded: {
        position: 'absolute',
        top: '50%',
        left: '50%'
    },
    squareButton: {
        height: '175px',
        width: '175px'
    },
    fullWidth: {
        width: '100%'
    },
    filterContentAlign: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
        }
    }
}));

function NotesFrame(props) {
    const {
        policyId
    } = props;

    const classes = useStyles();

    const [note, setNote] = useState({});
    const [notes, setNotes] = useState(props.notes ? props.notes : []);
    const [newSecureId, setNewSecureId] = useState('');
    const [editMode, setEditMode] = useState(false);
    const [reviewValidationMap, setReviewValidationMap] = useState(props.reviewValidationMap ? props.reviewValidationMap : {});
    const [inProgress, setInProgress] = useState(false);
    const [actionMsg, setActionMsg] = useState('');
    const [actionMsgType, setActionMsgType] = useState('');
    const [searchRequest, setSearchRequest] = useState(defaultSearchRequest());
    const [filter, setFilter] = useState(defaultFilter());
    const [showFilters, setShowFilters] = useState(false);
    const [validationMap, setValidationMap] = useState({});

    const selectUserInfo = useMemo(
        UserActions.createUserInfoSelector,
        []
    );
    
    const userInfo =  useSelector(x => selectUserInfo(x));

    const canChangeNote = UserHelper.hasPermission(userInfo, UserPermissions.change_note);

    function defaultSearchRequest() {
        return {
            PolicyId: props.policyId,
            SearchText: ''
        };
    }

    function defaultFilter() {
        return {
            From: '',
            To: '',
            Visibility: 'All'
        };
    }

    //#region Search Functions

    function search(request) {

        let req = { ...request };

        if (StringUtil.isEqual(req.Visibility, 'All'))
            req.Visibility = '';

        setInProgress(true);

        PolicyApiClient.searchNotes(req).then(result => {
            setInProgress(false);
            setValidationMap({});
            //handle validation and errors
            if (result.hasError) {
                setActionMsgType('error');
                setActionMsg(result.errorMessage);
                return;
            }

            if (result.data.HasValidationErrors) {
                setActionMsgType('error');
                setActionMsg('Search failed');

                const validationMap = ValidationHelper.getValidationMap(result.data);

                setValidationMap(validationMap);

                return;
            }

            const searchedNotes = result.data.Notes;

            setNotes(searchedNotes);
        });
    }

    function isRequired(name) {
        return Boolean(validationMap[name.toLowerCase()]);
    }

    function handleSearch(event) {
        if (StringUtil.isEqual(event.key, 'Enter'))
            search(searchRequest);
    }

    function applyFilters(event) {

        let filteredRequest = {
            ...searchRequest,
            ...filter
        };

        setSearchRequest(filteredRequest);
        search(filteredRequest);
    }

    function clearFilters(event) {

        const unfilteredRequest = {
            ...defaultSearchRequest(),
            SearchText: searchRequest.SearchText
        };

        setSearchRequest(unfilteredRequest);
        setFilter(defaultFilter());
        search(unfilteredRequest);
    }

    function handleSetSearchRequest(event) {
         

        const attribute = event.target.name;

        setSearchRequest(prevState => {
            return {
                ...prevState,
                [attribute]: event.target.value
            };
        });
    }

    function handleSetFilter(event) {
         

        const attribute = event.target.name;

        setFilter(prevState => {
            return {
                ...prevState,
                [attribute]: event.target.value
            };
        });
    }

    //#endregion Search Functions

    //#region  Misc Functions

    function createNoteWithKey(key, title) {

        const id = IdGenerator.NewId();
        const clientTime = new Date();

        const note = {
            SecureId: id,
            CreatedOn: clientTime,
            Tag: key,
            Title: title,
            Value: "",
            Visibility: "Internal",
            Resolved: false
        };

        return note;
    }

    function createDefaultNote() {
        return createNoteWithKey("Miscellaneous", "Miscellaneous");
    }

    function reset() {
        setEditMode(false);
        setNote({});
        setNewSecureId('');
    }

    function closeActionResult(event, reason) {
        if (reason === 'clickaway') {
            return;
        }

        setActionMsg('');
    };

    //#endregion Misc Functions

    //#region Handlers

    function handleAddNote(event) {
        const isAddStickyAlreadyTriggered = newSecureId !== '';

        if (isAddStickyAlreadyTriggered)
            return;

        const note = createDefaultNote();

        setNote(note);
        setNewSecureId(note.SecureId);
        setEditMode(true);
    }

    function handleCloseNote(note) {
        reset();
    }

    function handleNoteClick(secureId) {
        const note = notes.find(item => item.SecureId === secureId);
        setNote(note);
        setEditMode(true);
    }

    async function handleBatchSaveAsync(notes) {

        let savedNotes = null;

        const result = await PolicyApiClient.saveNotes(policyId, notes);

        if (result.hasError) {
            setActionMsgType('error');
            setActionMsg(result.errorMessage);
            return;
        }

        if (result.data.HasValidationErrors) {
            setActionMsgType('error');
            setActionMsg('Auto creation failed');
            return;
        }

        savedNotes = result.data.Notes;

        setNotes(savedNotes);
        setActionMsgType('success');
        setActionMsg('Note(s) saved');

        return savedNotes;
    }

    async function handleSaveAsync(note) {

        let savedNote = null;

        const result = await PolicyApiClient.saveNote(policyId, note);

        if (result.hasError) {
            setActionMsgType('error');
            setActionMsg(result.errorMessage);
            return;
        }

        if (result.data.HasValidationErrors) {
            setActionMsgType('error');
            setActionMsg('Failed to save note');
            return;
        }

        savedNote = result.data.Note;

        setNotes(currentVal => [savedNote, ...currentVal.filter(item => !StringUtil.isEqual(item.SecureId, note.SecureId))]);
        setNewSecureId('');
        setActionMsgType('success');
        setActionMsg('Note saved');

        return savedNote;
    }

    async function handleDeleteAsync(secureId) {
        const note = {
            SecureId: secureId
        };

        //Refrain from deleting as th note was never added
        if (secureId !== newSecureId) {
            const result = await PolicyApiClient.deleteNote(policyId, note);

            if (result.hasError) {
                setActionMsgType('error');
                setActionMsg(result.errorMessage);
                return;
            }

            if (result.data.HasValidationErrors) {
                setActionMsgType('error');
                setActionMsg('Failed to save note');
                return;
            }

            const isDeleted = result.data.Deleted ? true : false;

            if (!isDeleted)
                return;
        }

        const notesCollection = notes.filter(item => item.SecureId !== secureId);

        setActionMsgType('success');
        setActionMsg('Note deleted');

        setNotes(notesCollection);
        reset();
    }

    async function autoCreateImportantNotes() {

        let validationMap = reviewValidationMap;
        setInProgress(true);

        if (Object.keys(validationMap).length === 0) {
            const result = await PolicyApiClient.loadReviewRules(policyId);

            if (result.hasValidationInfo) {
                validationMap = ValidationHelper.getValidationMap(result.data);
                setReviewValidationMap(validationMap);
            }
        }

        if (Object.keys(validationMap).length === 0) {
            setActionMsgType('info');
            setActionMsg('No notes created as no review rules were triggered');
            setInProgress(false);

            return;
        }

        const notes = Object.values(validationMap).map(item => {
            const note = createNoteWithKey(item.FieldName, item.ErrorMessage);
            return note;
        });

        handleBatchSaveAsync(notes).then(resp => {
            setInProgress(false);
        });
    }

    //#endregion Handlers

    const noteParams = {
        onSaveAsync: handleSaveAsync,
        onDeleteAsync: handleDeleteAsync,
        onCloseNote: handleCloseNote,
        onNoteClick: handleNoteClick
    };

    const previewCollection = notes.map(note => {
        return <div key={note.SecureId}>
            <NotePreview
                {...noteParams}
                note={note} />
        </div>;
    });

    const searchTextBoxProps = {
        endAdornment: (
            <InputAdornment>
                <IconButton onClick={event => search(searchRequest)} size="large">
                    <SearchIcon />
                </IconButton>
            </InputAdornment>
        )
    };

    return (
        <div className="stickyFrame">
            <Snackbar
                open={!StringUtil.isNullOrEmpty(actionMsg)}
                autoHideDuration={6000}
                onClose={closeActionResult}>
                <MuiAlert elevation={6} variant="filled"
                    onClose={closeActionResult}
                    severity={actionMsgType}>
                    {actionMsg}
                </MuiAlert>
            </Snackbar>

            {editMode &&
                <Slide in={editMode} direction='right'>
                    <div className={classes.fullWidth}>
                        <Note note={note} {...noteParams} readonly={false} />
                    </div>
                </Slide>
            }
            {!editMode &&
                <Slide in={!editMode} direction='left'>
                    <div>
                        <div className={classes.fullWidth} style={{ display: "inline-flex" }}>
                            <TextField label="Search notes"
                                style={{ width: '80%' }}
                                value={searchRequest.SearchText}
                                InputProps={searchTextBoxProps}
                                name="SearchText"
                                onChange={handleSetSearchRequest}
                                onKeyPress={handleSearch} />
                            <Tooltip title="Filter by date range and visibility">
                                <Button
                                    startIcon={<FilterListIcon />}
                                    onClick={event => { setShowFilters(prevState => !prevState) }}>
                                    Filter
                                </Button>
                            </Tooltip>
                            {canChangeNote &&
                                <Tooltip title="Auto create notes with items to review">
                                    <Button
                                        startIcon={
                                            <Icon className={classes.iconRoot}>
                                                <img className={classes.imageIcon} src="img/tag_to_notes.png" />
                                            </Icon>
                                        }
                                        onClick={event => autoCreateImportantNotes()}>
                                        Auto create
                                    </Button>
                                </Tooltip>
                            }
                        </div>
                        <Collapse in={showFilters}>
                            <Box className={classes.filterContentAlign} style={{ marginTop: 10 }}>
                                <Tooltip title="Start date">
                                    <TextField
                                        size="small"
                                        type="date"
                                        variant="outlined"
                                        label="From"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={filter.From}
                                        name="From"
                                        error={isRequired("From")}
                                        onChange={handleSetFilter} />
                                </Tooltip>
                                <Tooltip title="End date">
                                    <TextField
                                        size="small"
                                        type="date"
                                        variant="outlined"
                                        label="To"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={filter.To}
                                        name="To"
                                        error={isRequired("To")}
                                        onChange={handleSetFilter} />
                                </Tooltip>
                                {
                                    (userInfo && StringUtil.isEqual(userInfo.Role, UserRoles.UnderwritingManager)) &&
                                
                                
                                        <FormControl variant="outlined">
                                            <InputLabel shrink id="Visibility">Visibility</InputLabel>
                                            <Select
                                                //className={classes.visibilityTextbox}

                                                labelId="Visibility"
                                                defaultValue="All"
                                                label="Visibility"
                                                value={filter.Visibility}
                                                name="Visibility"
                                                onChange={handleSetFilter}>
                                                <MenuItem value="All">All</MenuItem>
                                                <MenuItem value="Internal">Internal</MenuItem>
                                                <MenuItem value="External">External</MenuItem>
                                            </Select>
                                        </FormControl>
                                }
                            </Box>
                            <Box className={classes.filterContentAlign}>
                                <Tooltip title="Apply filter(s)">
                                    <Button onClick={applyFilters}>Apply</Button>
                                </Tooltip>
                                <Tooltip title="Remove all filter(s)">
                                    <Button onClick={clearFilters}>Clear</Button>
                                </Tooltip>
                            </Box>
                        </Collapse>
                        <div className={classes.boxAlign} style={{ position: "relative" }}>
                            {canChangeNote &&
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    size="large"
                                    className={classes.squareButton}
                                    startIcon={<NoteAddIcon />}
                                    onClick={handleAddNote}>
                                    Add Note
                                </Button>
                            }
                            {previewCollection}
                            {inProgress &&
                                <div className={classes.loaderBackdrop}>
                                    <CircularProgress size={35} className={classes.centerLoaderExpanded} />
                                </div>
                            }
                        </div>
                    </div>
                </Slide>
            }
        </div>
    );
}

export default NotesFrame;