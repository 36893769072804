import React, { Fragment } from 'react';
import { Question } from 'Components/Questionary';
import { QuestionaryHelper } from 'Util/QuestionaryHelper';
import { Typography, Box, IconButton, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import makeStyles from '@mui/styles/makeStyles';
import { grey } from '@mui/material/colors';

const useStyles = makeStyles((theme) => ({
    header: {
        backgroundColor: grey[200]
    }
}));


function RemainingMortgageInfo(props) {
    const { item, idx, questionParams, canAdd, add, remove, collectionName } = props;

    const classes = useStyles();

    const isFirst = idx === 0;

    questionParams.indexer = QuestionaryHelper.createIndexer(collectionName, item);

    return (
        <Fragment>
            <Box p={1} display="flex" className={classes.header}>
                <Box flexGrow={1}>
                    <Typography>Mortgage {idx + 1}</Typography>
                </Box>
                <Box mr={1}>
                    <Tooltip title="Add Mortgage">
                        <span>
                            <IconButton
                                aria-label="add"
                                size="small"
                                disabled={!canAdd}
                                onClick={add}
                            >
                                <AddIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                </Box>
                <Box>
                    <Tooltip title="Remove">
                        <span>
                            <IconButton
                                aria-label="remove"
                                size="small"
                                disabled={isFirst}
                                onClick={() => { remove(item) }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                </Box>
            </Box>
            <Box p={2} className={classes.content}>
                <Question qId="LenderName" txtWidth={100} {...questionParams} />
                <Question qId="ClosingPriority" {...questionParams} />
                <Question qId="InstrumentNumber" txtWidth={100} {...questionParams} />
                <Question qId="MortgageAmount"  {...questionParams} />
            </Box>
        </Fragment>
    );
}

export default RemainingMortgageInfo;