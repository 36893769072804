import React from 'react';
import MaskedInput from 'react-text-mask';

function PhoneMask(props) {
    const { inputRef, ...other } = props;

    console.log("phhonemask props");
    console.log(inputRef);
    console.log(props);
  
    return (
      <MaskedInput
        {...other}
        ref={ref => {
          /*inputRef(ref ? ref.inputElement : null);*/
        }}
        mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        placeholderChar={'\u2000'}
      />
    );
}

export default PhoneMask;
