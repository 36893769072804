import React from 'react';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import './App.css';
import RouterSetup from './Startup/RouterSetupOkta';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { AppTheme } from "./AppTheme";

function App(children) {
  const theme = AppTheme();
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <LocalizationProvider  dateAdapter={AdapterMoment}>
          <Container maxWidth="false">
            <CssBaseline />
            <RouterSetup />
          </Container>
        </LocalizationProvider  >
      </ThemeProvider>
    </StyledEngineProvider>
  );
}


export default App;
