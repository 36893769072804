import { createAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

export const set_messages_list = createAction('admin/messages_list');
export const set_messages_list_loading = createAction('admin/messages_list_loading');
export const set_messages_list_loading_error = createAction('admin/messages_list_loading_error');
export const set_messages_list_total_records = createAction('admin/messages_list_total_records');
export const set_messages_list_total_pages = createAction('admin/messages_list_total_pages');
export const set_message = createAction('admin/set_message');
export const create_message = createAction('admin/create_message');
export const update_message = createAction('admin/update_message');
export const delete_message = createAction('admin/delete_message');
export const action_message_busy = createAction('admin/action_message');
export const set_message_loading_error = createAction('admin/set_message_loading_error');
export const set_message_handle_change = createAction('admin/set_message_handle_change');
export const set_current_messages = createAction('admin/set_current_messages');

export function createMessagesListSelector() {
    return createSelector(
        state => state.messages.messages_list,
        x => x
    );
}

export function createMessagesListLoadingSelector() {
    return createSelector(
        state => state.messages.messages_list_loading,
        x => x
    );
}

export function createMessagesListLoadingErrorSelector() {
    return createSelector(
        state => state.messages.messages_list_loading_error,
        x => x
    );
}

export function createMessagesListTotalRecordsSelector() {
    return createSelector(
        state => state.messages.messages_list_total_records,
        x => x
    );
}

export function createMessagesListTotalPagesSelector() {
    return createSelector(
        state => state.messages.messages_list_total_pages,
        x => x
    );
}

export function createMessageSelector() {
    return createSelector(
        state => state.messages.message,
        x => x
    );
}

export function createMessageBusySelector() {
    return createSelector(
        state => state.messages.message_busy,
        x => x
    );
}

export function createMessageErrorSelector() {
    return createSelector(
        state => state.messages.message_loading_error,
        x => x
    );
}

export function updateMessageSelector() {
    return createSelector(
        state => state.messages.message,
        x => x
    );
}

export function deleteMessageSelector() {
    return createSelector(
        state => state.messages.message,
        x => x
    );
}

export function changeMessageInputSelector() {
    return createSelector(
        state => state.messages.message,
        x => x
    );
}
