import React from 'react';
import { SectionHeader } from 'Components/Questionary';
import PurchaserInfo from './PurchaserInfo';
import makeStyles from '@mui/styles/makeStyles';
import { SectionHelper } from '../../Util/SectionHelper';
import { useDispatch } from 'react-redux'
import * as PolicyActions from 'Policy/State/PolicyActions';
import { CollectionTypeEnum, TransactionTypeEnum } from 'Model/PolicyOrder';
import { StringUtil } from 'Util/Helpers';
import { Fragment } from 'react';
import Conditional from 'Policy/Conditional';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(3)
    }
}));

function PurchasersSection(props) {
    const classes = useStyles();

    const { questionary, questionParams, itemsList, property, propertyConditionalProps } = props;

    const dispatch = useDispatch();

    const collectionName = CollectionTypeEnum.Purchasers;

    const canAdd = itemsList.length < 6;

    function add() {
        const newItem = SectionHelper.addNewSection(collectionName, questionParams, { PurchaserPropertyId: property.Id });

        dispatch({ type: PolicyActions.add_purchaser.type, payload: newItem });
    }

    function remove(item) {
        SectionHelper.removeSection({ collectionName, item, questionParams });

        dispatch({ type: PolicyActions.remove_purchaser.type, payload: item });
    }

    return (
        <Fragment>
            <Conditional upon="TransactionType" when={(x) => StringUtil.isEqual(x, TransactionTypeEnum.MortgageOnly)} {...propertyConditionalProps}>
                <SectionHeader section="purchasers" title="Borrower" questionary={questionary} subSection />
            </Conditional>
            <Conditional upon="TransactionType" when={(x) => StringUtil.isEqual(x, TransactionTypeEnum.ExistingOwner)} {...propertyConditionalProps}>
                <SectionHeader section="purchasers" title="Registered Owner" questionary={questionary} subSection />
            </Conditional>
            <Conditional upon="TransactionType" when={(x) => !StringUtil.isEither(x, TransactionTypeEnum.MortgageOnly, TransactionTypeEnum.ExistingOwner)} {...propertyConditionalProps}>
                <SectionHeader section="purchasers" title="Purchasers" questionary={questionary} subSection />
            </Conditional>

            <div className={classes.root}>
                {itemsList.map((item, index) => {
                    return (
                        <PurchaserInfo
                            key={item.Id}
                            idx={index}
                            collectionName={collectionName}
                            item={item}
                            canAdd={canAdd}
                            add={add}
                            remove={remove}
                            questionParams={questionParams} />
                    );
                })}
            </div>
        </Fragment>
    );
}

export default PurchasersSection;