import React, { useMemo } from 'react';
import { useTheme } from '@mui/material/styles';
import { Tooltip, IconButton, TableCell, TableRow, Link } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { StringUtil } from 'Util/Helpers';

const cellStyleBase = {
    borderBottom: 'none',
    paddingTop: 0,
    paddingBottom: 0
};

const toMb = (bytes) => {
    if (bytes === 0) { return bytes };

    const mb = bytes / 1000 / 1000;

    return mb.toFixed(2);
};

function UploadedFile(props) {
    const { file, handleView, handleDelete, readOnly } = props;

    const theme = useTheme();

    const hasError = !StringUtil.isNullOrEmpty(file.uploadError);

    const errorStyle = {
        color: theme.palette.error.main
    };

    const cellStyle = useMemo(() => ({
        ...cellStyleBase,
        ...(hasError ? errorStyle : {})
    }), [hasError]);

    let fileLink = file.name;

    if(!StringUtil.isNullOrEmpty(handleView)) {
        fileLink = (<Link component="button" onClick={(e) => handleView(e, file)}>{file.name}</Link>);
    }

    return (
        <TableRow tabIndex={-1} key={file.name}>
            <TableCell style={cellStyle}>&bull;&nbsp;&nbsp;{fileLink}</TableCell>
            <TableCell style={cellStyle}>{toMb(file.size)} MB</TableCell>
            {!StringUtil.isNullOrEmpty(file.uploadError) &&
                <TableCell style={cellStyle}>{file.uploadError}</TableCell>
            }
            {!readOnly &&
                <TableCell style={cellStyle}>
                    <Tooltip title="Remove">
                        <IconButton size="small" area-label="remove" onClick={(e) => handleDelete(e, file)}>
                            <CancelIcon />
                        </IconButton>
                    </Tooltip>
                </TableCell>
            }
        </TableRow>
    );
}

export default UploadedFile;