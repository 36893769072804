import * as AppErrors from '../Model/AppErrors';
import {StringUtil} from '../Util/Helpers';
import Logger from '../Util/Logger';
import ApiResponse from '../Model/ApiResponse';
import {ValidationHelper} from '../Util/ValidationHelper';
import {AuthService} from '../Services/AuthService';

class ApiHelper {
    logger;

    constructor() {
        this.logger = new Logger(ApiHelper.name);
    }

    async authHeaders() {
        let accessToken = await AuthService.getAccessTokenAsync();

        // accessToken = "PUT YER TOKEN HERE"; // IGOR IE 11 FOR HENRY

        const result = {
            "Authorization": "Bearer " + accessToken
        };

        return result;
    }

    async httpRespToErrorApiResp(response) {
        // we did not get a 200 status code
        if (!response.ok) {
            let errMsg = await response.text();

            this.logger.error('error response received', `${response.status} ${response.statusText} ${errMsg}`);

            if (StringUtil.isNullOrEmpty(errMsg)) {
                errMsg = AppErrors.generic;
            }

            const apiResp = new ApiResponse(true);
            apiResp.error = errMsg;

            return apiResp;
        }

        return null;
    }

    async deserializeJsonResponse(response) {
        const errorApiResp = await this.httpRespToErrorApiResp(response);

        if (errorApiResp != null) {
            return errorApiResp;
        }

        const responseObj = await response.json();

        const isError = StringUtil.isEqual(responseObj.Status, "error");

        const apiResp = new ApiResponse(isError);
        apiResp.data = responseObj;

        apiResp.hasValidationResult = ValidationHelper.hasItems(responseObj);

        if (apiResp.hasValidationResult === true) {
            apiResp.hasValidationErrors = ValidationHelper.hasErrors(responseObj);
            apiResp.hasValidationWarnings = ValidationHelper.hasWarnings(responseObj);
            apiResp.hasValidationInfo = ValidationHelper.hasInfo(responseObj);
            apiResp.genericValidationMessage = ValidationHelper.getGenericErrorMessage(responseObj);
        }

        if (isError) {
            apiResp.error = responseObj.StatusDescription;
        }

        return apiResp;
    }

    async _getS3PreSignedUrl(file, url) {
        const authHeaders = await this.authHeaders();

        const getPreSignedUrl = `${url}&filename=${file.name}`;

        const resp = await fetch(getPreSignedUrl, {
            method: 'GET',
            headers: {
                ...authHeaders
            }
        });

        var apiResp = await this.deserializeJsonResponse(resp);
        apiResp.file = file;

        if (!StringUtil.isNullOrEmpty(apiResp.data)) {
            this.logger.info(`got a pre-signed url for file ${file.name}`);
        }

        return apiResp;
    }

    async apiGet(url) {
        try {
            const authHeaders = await this.authHeaders();

            const resp = await fetch(url, {
                method: 'GET',
                headers: {
                    ...authHeaders
                }
            });

            return await this.deserializeJsonResponse(resp);
        } catch (ex) {
            this.logger.error(`GET error ${url}`, ex);

            const errMsg = AppErrors.generic;

            const apiResp = new ApiResponse(true);
            apiResp.error = errMsg;

            return apiResp;
        }
    }

    async uploadFileViaS3PreSigned(file, url) {
        try {
            const preSignedResp = await this._getS3PreSignedUrl(file, url);

            if (preSignedResp.hasError) {
                return preSignedResp;
            }

            const tmpUploadUrl = preSignedResp.data.Url;

            let fileInfoHeaders = {
                "Content-Type": file.type
            }

            if (!StringUtil.isNullOrEmpty(preSignedResp.data.Metadata)) {
                fileInfoHeaders = { ...fileInfoHeaders, ...preSignedResp.data.Metadata };
            }

            const options = {
                method: 'PUT',
                body: file,
                headers: {
                    ...fileInfoHeaders
                }
            };

            const resp = await fetch(tmpUploadUrl, options);

            const errorApiResp = await this.httpRespToErrorApiResp(resp);

            if (errorApiResp != null) {
                errorApiResp.error = AppErrors.generic; // errors from S3 are not user friendly
                errorApiResp.file = file;
                return errorApiResp;
            }

            const apiResp = new ApiResponse();
            apiResp.file = file;

            return apiResp;
        }
        catch (ex) {
            this.logger.error('file upload error', ex);

            const errMsg = AppErrors.generic;

            const apiResp = new ApiResponse(true);
            apiResp.error = errMsg;
            apiResp.file = file;

            return apiResp;
        }
    }
}

const instance = new ApiHelper();

export { instance as ApiHelper }
