import { Button, FormGroup, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Question } from 'Components/Questionary';
import { PropertyTypeEnum } from 'Model/PolicyOrder';
import Conditional from 'NewHomeProjects/Conditional';
import React from 'react';
import { useTranslation } from "react-i18next";
import { QuestionaryHelper } from 'Util/QuestionaryHelper';
import StringUtil from 'Util/StringUtil';

const useStyles = makeStyles((theme) => ({
}));

function RemoveButton(props) {
    const { isFirst, item, remove } = props;

    return (
        <Tooltip title="Remove">
            <span>
                <Button
                    type="button"
                    size="small"
                    startIcon={<DeleteIcon />}
                    disabled={isFirst}
                    onClick={() => { remove(item) }}
                >
                    Remove
        </Button>
            </span>
        </Tooltip>
    );
}

function AddButton(props) {
    const { canAdd, add } = props;

    return (
        <Tooltip title="Add Property">
            <span>
                <Button
                    type="button"
                    size="small"
                    startIcon={<AddIcon />}
                    disabled={!canAdd}
                    onClick={add}>
                    Add
                </Button>
            </span>
        </Tooltip>
    );
}

function ExceptionPropertyInfo(props) {
    const { item, idx, questionParams, canAdd, add, remove, collectionName, totalCount, getInitialAnswer } = props;

    let qParams = { ...questionParams };

    const { t } = useTranslation();

    const classes = useStyles();

    const isFirst = idx === 0;
    const isLast = idx === totalCount - 1;

    qParams.indexer = QuestionaryHelper.createIndexer(collectionName, item);

    const conditionalProps = {
        indexer: questionParams.indexer,
        getInitialAnswer: getInitialAnswer
    };

    const rangeProps = {
        indexer: qParams.indexer,
        getInitialAnswer: getInitialAnswer
    };

    return (
        <React.Fragment>
            <FormGroup row>
                <Conditional upon="PropertyType" when={(x) => StringUtil.isEqual(x, PropertyTypeEnum.Condominium)} {...conditionalProps}>
                    <Question qId="Type" questionTitle="Type of Unit" {...qParams} inline txtWidth={20} hideLabel={!isFirst} />
                    <Question qId="Plan" {...qParams} inline txtWidth={20} hideLabel={!isFirst} />
                    <Question qId="BoundaryFrom" questionTitle="Unit Start" {...qParams} inline txtWidth={20} hideLabel={!isFirst} />
                    <Question qId="BoundaryTo" questionTitle="Unit End" {...qParams} inline txtWidth={20} hideLabel={!isFirst} />
                    <Question qId="SubDivisionFrom" questionTitle="Level Start" {...qParams} inline txtWidth={20} hideLabel={!isFirst} />
                    <Question qId="SubDivisionTo" questionTitle="Level End" {...qParams} inline txtWidth={20} hideLabel={!isFirst} />
                </Conditional>

                <Conditional upon="PropertyType" when={(x) => !StringUtil.isEqual(x, PropertyTypeEnum.Condominium)} {...conditionalProps}>
                    <Question qId="Plan" {...qParams} inline txtWidth={20} hideLabel={!isFirst} />
                    <Question qId="Type" questionTitle="Lot/Block" {...qParams} inline txtWidth={20} hideLabel={!isFirst} />
                    <Question qId="BoundaryFrom" questionTitle="Start" {...qParams} inline txtWidth={20} hideLabel={!isFirst} />
                    <Question qId="BoundaryTo" questionTitle="End" {...qParams} inline txtWidth={20} hideLabel={!isFirst} />

                    <Conditional upon="Type" when={(x) => StringUtil.isEither(x, "Lot", "Block")} {...rangeProps}>
                        <Question qId="SubDivisionFrom" questionTitle="Part Start" {...qParams} inline txtWidth={20} hideLabel={!isFirst} disabled />
                        <Question qId="SubDivisionTo" questionTitle="End" {...qParams} inline txtWidth={20} hideLabel={!isFirst} disabled />
                        <Question qId="ReferencePlan" questionTitle="Ref Plan" {...qParams} inline txtWidth={20} hideLabel={!isFirst} disabled />
                    </Conditional>

                    <Conditional upon="Type" when={(x) => !StringUtil.isEither(x, "Lot", "Block")} {...rangeProps}>
                        <Question qId="SubDivisionFrom" questionTitle="Part Start" {...qParams} inline txtWidth={20} hideLabel={!isFirst} />
                        <Question qId="SubDivisionTo" questionTitle="End" {...qParams} inline txtWidth={20} hideLabel={!isFirst} />
                        <Question qId="ReferencePlan" questionTitle="Ref Plan" {...qParams} inline txtWidth={20} hideLabel={!isFirst} />
                    </Conditional>
                </Conditional>

                {!isFirst && <RemoveButton isFirst={isFirst} item={item} remove={remove} getInitialAnswer={getInitialAnswer} />}
                {isFirst && <AddButton canAdd={canAdd} add={add} getInitialAnswer={getInitialAnswer} />}
            </FormGroup>
        </React.Fragment>
    );
}

export default ExceptionPropertyInfo;