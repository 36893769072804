import Logger from 'Util/Logger';
import { StringUtil } from "Util/Helpers";
import { AppSettings } from 'AppSettings';
import { OktaAuth } from '@okta/okta-auth-js';
import WebStorageHelper from 'Util/WebStorageHelper';
import { Store } from 'store';
import * as UserThunks from 'User/State/UserThunks';
import BrowserHelper from 'Util/BrowserHelper';

// this was originally non-Okta. This needs clean up.

class AuthService {
    mgr;
    logger;
    config;
    oktaConfig;
    oktaAuth;

    constructor() {
        this.logger = new Logger(AuthService.name);
        this.config = AppSettings.auth_config;
        this._injectUserFromQueryString();

        this.oktaConfig = {
            issuer: this.config.issuer,
            clientId: this.config.client_id,
            redirectUri: window.location.origin + '/implicit/callback',
            tokenManager: {
                expireEarlySeconds: 120
            },
            cookies: { /* only if we fall back to cookie storage, this allows cookie storage over https only */
                SameSite: 'None',
                Secure: true,
                Path: '/',
                Partitioned: true
            }
        };

        if (BrowserHelper.isIE11()) {
            // as per Erik Manor from Okta (support case 01006545)
            this.oktaConfig.storageManager = {
                token: {
                    storageTypes: [
                        'localStorage',
                        'sessionStorage',
                        'cookie'
                    ],
                    useMultipleCookies: true // puts each token in its own cookie
                },
                cache: {
                    storageTypes: [
                        'localStorage',
                        'sessionStorage',
                        'cookie'
                    ]
                },
                transaction: {
                    storageTypes: [
                        'cookie'
                    ]
                }
            };
        }
        this.oktaAuth = new OktaAuth(this.oktaConfig);
    }

    get authClient() {
        return this.oktaAuth;
    }

    onPreLogin() {
        WebStorageHelper.removeItem('login_page', 'user-info');
        WebStorageHelper.removeItem(null, 'okta-pkce-storage');
        WebStorageHelper.removeFromDisk(null, 'okta-pkce-storage');
    }

    injectAccessToken(at) {
        if(StringUtil.isNullOrEmpty(at)) {
            return;
        }
        WebStorageHelper.setItem("auth", "access-token", at);
    }

    removeInjectedAccessTokenAndRedirect(hist) {
        Store.dispatch(UserThunks.loadUserProfile(true, () => {
            hist.replace({
                pathname: window.location.pathname,
                search: this.urlQueryString
            });
        }));
    }

    get injectedAccessToken() {
        return WebStorageHelper.getItem("auth", "access-token");
    }

    get isAccessTokenInjected() {
        return !StringUtil.isNullOrEmpty(this.injectedAccessToken);
    }

    _injectUserFromQueryString() {
        /*
        const searchParams = new URLSearchParams(window.location.search);

        const accessToken = searchParams.get('access_token');

        if (StringUtil.isNullOrEmpty(accessToken)) {
            return;
        }
        
        // remove the access_token from query string...
        const queryStringLessToken = this.urlQueryString;
        window.history.replaceState(null, null, queryStringLessToken);

        this.logger.info("access_token was passed in via url query string");

        const parsed = this._parseJwt(accessToken);

        const userStorageKey = `oidc.user:${this.config.authority}:${this.config.client_id}`;

        const userInfo = new User({
            access_token: accessToken,
            token_type: "Bearer",
            scope: this.config.scope,
            expires_at: parsed.exp
        });

        const expiresIn = userInfo.expires_in;

        // if the passed in token expires in less than 61 seconds, it's no good ...
        if (userInfo.expires_in < 61) {
            this.logger.info("access_token passed via query string has expired.")

            return;
        }

        const userJSON = userInfo.toStorageString();
        window.sessionStorage.setItem(userStorageKey, userJSON);
        */
    }

    _parseJwt(token) {
        const payload = token.split(".")[1];
        const decoded = window.atob(payload);
        const parsed = JSON.parse(decoded);

        return parsed;
    }

    async isSignInRequired() {
        const currPageUrl = window.location.href;
        if (StringUtil.contains(currPageUrl, this.config.redirect_uri)) {
            this.logger.debug('sign in redirect route is being invoked. user loading skipped.');
            return false;
        }

        if (StringUtil.contains(currPageUrl, this.config.silent_redirect_uri)) {
            this.logger.debug('silent renew route is being invoked. user loading skipped.');
            return false;
        }

        const user = await this.mgr.getUser();
        if (StringUtil.isNullOrEmpty(user)) {
            this.logger.info('user is not present');
            return true;
        }

        if (user.expires_in <= 0) {
            this.logger.info("user is present but the session has expired");
            return true;
        }
        return false;
    }

    getAccessToken() {
        // dirty hack
        const iat = this.injectedAccessToken;
        if(!StringUtil.isNullOrEmpty(iat)) {
            return iat;
        }
        
        const at = this.oktaAuth.getAccessToken();
        if (StringUtil.isNullOrEmpty(at)) {
            return null;
        }
        return at;
    }

    // backwards compatibility...
    async getAccessTokenAsync() {
        // dirty hack
        return await this.getAccessToken();
    }

    async isUserSignedIn() {
        const accessToken = await this.getAccessTokenAsync();
        return !StringUtil.isNullOrEmpty(accessToken);
    }

    async redirectToLogin() {
        try {
            const currPageUrl = window.location.href;
            if (!StringUtil.contains(currPageUrl, this.config.redirect_uri)) {
                // careful with IOS and private mode as session storage is not available ....
                window.sessionStorage.setItem("preLoginRoute", window.location.pathname + this.urlQueryString);
            }

            await this.mgr.signinRedirect();
            this.logger.debug("sign in redirect success");
            return true;
        }
        catch (e) {
            this.logger.error("sign in redirect error", e);
        }
        return false;
    }

    get urlQueryString() {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.delete('access_token');
        let searchParamsMod = searchParams.toString();
        if (!StringUtil.isNullOrEmpty(searchParamsMod) && !searchParamsMod.startsWith("?")) {
            searchParamsMod = `?${searchParamsMod}`;
        }
        return searchParamsMod;
    }

    async signOut() {
        WebStorageHelper.removeItem('login_page', 'user-info');
        WebStorageHelper.removeItem(null, 'okta-pkce-storage');
        WebStorageHelper.removeFromDisk(null, 'okta-pkce-storage');

        await this.oktaAuth.signOut();
    }

    async signinRedirectCallback() {
        try {
            await this.mgr.signinRedirectCallback();
            this.logger.debug("sign in callback success");
            this.redirectToPreLoginRoute();
            return true;
        }
        catch (e) {
            this.logger.error("error in sign in callback", e);
        }

        try {
            const isSignedIn = await this.isUserSignedIn();
            if (isSignedIn) {
                this.redirectToPreLoginRoute();
                return true;
            }
        }
        catch (e) {
            this.logger.error("error in sign in callback 2", e);
        }
        return false;
    }

    redirectToPreLoginRoute() {
        let preLoginRoute = window.sessionStorage.getItem("preLoginRoute");
        if (StringUtil.isNullOrEmpty(preLoginRoute)) {
            this.logger.debug("Pre-login route is not stored. Defaulting to root path.");
            preLoginRoute = "/";
        }
        this.logger.debug(`Redirecting to pre-login route ${preLoginRoute}`);
        window.location.replace(preLoginRoute);
    }

    async signinSilentCallback() {
        try {
            await this.mgr.signinSilentCallback();
            this.logger.debug("silent renew callback success");
            return true;
        }
        catch (e) {
            this.logger.error("silent renew callback error", e);
        }
        return false;
    };
}

const instance = new AuthService();

// listen to auth state changes
instance.authClient.authStateManager.subscribe((authState) => {
    if (!authState) {
        return;
    }
    const isAuthenticated = instance.isAccessTokenInjected || authState.isAuthenticated;
    // load user profile, if authenticated
    Store.dispatch(UserThunks.loadUserProfile(isAuthenticated));
});

export { instance as AuthService }
