import React from 'react';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Info as InfoIcon } from '@mui/icons-material';
import { QuestionaryHelper } from 'Util/QuestionaryHelper';
import { StringUtil } from 'Util/Helpers';
import { Box } from '@mui/material';

const useStyles = makeStyles(theme => ({
  info: {
    verticalAlign: "middle",
    marginBottom: 3,
    color: theme.palette.info.main
  },
  attention: {
    verticalAlign: "middle",
    marginBottom: 3,
    color: theme.palette.warning.main
  }
}));

function Stipulation(props) {
  const classes = useStyles();

  const { questionary, stipId } = props;

  const stip = QuestionaryHelper.getStipulation(questionary, stipId);

  if (stip == null) {
    return null;
  }

  let rendered = null;

  if (StringUtil.isEqual(stip.Type, "Info")) {
    rendered = (
      <React.Fragment>
        <InfoIcon className={classes.info} />
            &nbsp;{stip.Text}
      </React.Fragment>
    );
  }
  else if (StringUtil.isEqual(stip.Type, "Attention")) {

    
    rendered = (
      <React.Fragment>
        <InfoIcon className={classes.attention} />    
           
        &nbsp; {stip.Link && stip.Link.length > 0 ? <a target="_blank" href={stip.Link}>{stip.Text}</a> : stip.Text}
        
      </React.Fragment>
    );
  }

  if (rendered == null) {
    return null;
  }

  return (
    <Box style={{ whiteSpace: "pre-wrap" }}>
      <Typography variant="body1">
        {rendered}
      </Typography>
    </Box>
  )
}

export default Stipulation;