import { Box, MenuItem } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import GroupedButton from 'Components/GroupedButton';
import MultiSelectAutoComplete from 'Components/MultiSelectAutoComplete';
import { ApplicabilityTypeEnum, EndorsementTypeEnum, PolicyTypeEnum } from 'Model/PolicyOrder';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { IdGenerator, StringUtil, UserHelper } from 'Util/Helpers';
import { ValidationHelper } from 'Util/ValidationHelper';
import { PolicyApiClient } from '../../PolicyApiClient';

const useStyles = makeStyles(() => ({
    fullWidth: {
        width: "100%"
    }
}));

function SearchEndorsement(props) {
    const classes = useStyles();
    const { items, setItems, canChange, setChangeCount } = props;
    const [openSearchPopup, setOpenSearchPopup] = useState(false);
    const [options, setOptions] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [disableOwnerButtons, setDisableOwnerButtons] = useState(false);
    const [disableLenderButtons, setDisableLenderButtons] = useState(false);
    const [actionMsg, setActionMsg] = useState('');
    const [actionMsgType, setActionMsgType] = useState('');

    const multiSelectAutoCompleteRef = useRef();

    const policyMap = useSelector(x => x.order.orderVolatileData.PolicyMap);

    useEffect(() => {
        async function fetchData() {
            if (canChange) {
                const searchCriteria = '';
                const resp = await PolicyApiClient.searchPolicyEndorsement(searchCriteria);

                if (resp.hasError)
                    return;

                const respData = resp.data;
                const validationMap = ValidationHelper.getValidationMap(respData);
                if (!StringUtil.isNullOrEmpty(validationMap))
                    return;

                const opts = respData.Endorsements.map((option) => {
                    let group = "Lender/Owner Endorsement(s)";

                    if (StringUtil.isEqual(option.PolicyType, PolicyTypeEnum.LenderPolicyOnly))
                        group = "Lender Endorsement(s)";

                    if (StringUtil.isEqual(option.PolicyType, PolicyTypeEnum.OwnerPolicyOnly))
                        group = "Owner Endorsement(s)";

                    return {
                        Group: group,
                        ...option,
                    };
                });

                setOptions(opts);
            }
        }
        fetchData();
    }, []);

    if (!canChange)
        return null;

    //#region Search
    async function handleSearch(searchCriteria) {
        if (StringUtil.isNullEmptyOrWhiteSpace(searchCriteria))
            return;

        const resp = await PolicyApiClient.searchPolicyEndorsement(searchCriteria);

        if (resp.hasError) {
            setActionMsgType('error');
            setActionMsg(resp.errorMessage);

            if (!resp.hasValidationResult) {
                return;
            }
        }

        const respData = resp.data;
        const validationMap = ValidationHelper.getValidationMap(respData);
        if (!StringUtil.isNullOrEmpty(validationMap)) {
            setActionMsgType('error');
            setActionMsg('Search failed');
            return;
        }

        const opts = respData.Endorsements.map((option) => {
            let group = "Lender/Owner Endorsement(s)";

            if (StringUtil.isEqual(option.PolicyType, PolicyTypeEnum.LenderPolicyOnly))
                group = "Lender Endorsement(s)";

            if (StringUtil.isEqual(option.PolicyType, PolicyTypeEnum.OwnerPolicyOnly))
                group = "Owner Endorsement(s)";

            return {
                Group: group,
                ...option,
            };
        });

        setOptions(opts);
        setOpenSearchPopup(true);
    }

    function handleOnAutoCompleteChange(event, value) {
        setDisableLenderButtons(false);
        setDisableOwnerButtons(false);

        if (value) {
            if (value.some(x => StringUtil.isEqual(x.PolicyType, PolicyTypeEnum.OwnerPolicyOnly)))
                setDisableLenderButtons(true);

            if (value.some(x => StringUtil.isEqual(x.PolicyType, PolicyTypeEnum.LenderPolicyOnly)))
                setDisableOwnerButtons(true);
        }

        setSelectedOptions(value);
    }

    function handleApplyBtnClick(policyType, policyNumber) {
        const appliedItems = [...items];
        appliedItems.forEach(item => item.AlreadyApplied = false);
        const policyTypes = StringUtil.isEqual(policyType, PolicyTypeEnum.OwnerAndLenderPolicy) ? [PolicyTypeEnum.LenderPolicyOnly, PolicyTypeEnum.OwnerPolicyOnly] : [policyType];

        selectedOptions.forEach((item) => {
            policyTypes.forEach(policyType => {
                const existingItem = appliedItems.find(o => StringUtil.isEqual(o.Code, item.Code) && StringUtil.isEqual(o.AppliedTo, policyNumber));
                if (existingItem) {
                    const existingItemIndex = appliedItems.indexOf(existingItem);
                    appliedItems[existingItemIndex].AlreadyApplied = true;
                    return;
                }

                const i = { ...item };
                i.SecureId = IdGenerator.NewId();
                i.Type = ApplicabilityTypeEnum.Manual;
                i.AppliedTo = policyNumber;
                i.PolicyType = policyType;
                i.ToSave = true;
                i.IsNew = true;

                appliedItems.push(i);
                setChangeCount(prevState => prevState + 1);
            });
        });
        setItems(appliedItems);
        multiSelectAutoCompleteRef.current.clearSearch();
    }
    //#endregion Search

    //#region Props and HTML
    const shouldDisableApplyButton = items.some(o => o.Saving === true) || selectedOptions.length === 0;

    const hasOwnerPolicies = policyMap.some(p => StringUtil.isEqual(p.Type, PolicyTypeEnum.OwnerPolicyOnly));
    const hasLenderPolicies = policyMap.some(p => StringUtil.isEqual(p.Type, PolicyTypeEnum.LenderPolicyOnly));

    return <React.Fragment>
        {canChange &&
            <Box style={{ display: "inline-flex", marginTop: 10, marginBottom: 10 }} className={classes.fullWidth}>
                <Box style={{ paddingRight: 2 }} className={classes.fullWidth}>
                    <MultiSelectAutoComplete
                        ref={multiSelectAutoCompleteRef}
                        openPopup={openSearchPopup}
                        options={options}
                        onChangeEventHandler={handleOnAutoCompleteChange}
                        //onSearchEventHandler={handleSearch}
                        textFieldLabel="Search endorsements"
                        optionLabelProp="Topic"
                        optionIdProp="Code"
                        groupByProp="Group"
                    />
                </Box>
                {/* Apply button */}
                <GroupedButton disabled={shouldDisableApplyButton} buttonText="Apply">
                    {hasOwnerPolicies && [
                        <MenuItem disabled key="ownerPolicy" value="">
                            <em>Owner Policy(s)</em>
                        </MenuItem>,
                        policyMap.filter(p => StringUtil.isEqual(p.Type, PolicyTypeEnum.OwnerPolicyOnly))
                            .map((p) => {
                                return <MenuItem
                                    key={p.PolicyNumber}
                                    onClick={() => handleApplyBtnClick(PolicyTypeEnum.OwnerPolicyOnly, p.PolicyNumber)}
                                    disabled={disableOwnerButtons}>
                                    {p.PolicyNumber}
                                </MenuItem>;
                            })

                    ]}
                    {hasLenderPolicies && [
                        <MenuItem disabled key="lenderPolicies" value="">
                            <em>Lender Policy(s)</em>
                        </MenuItem>,
                        policyMap.filter(p => StringUtil.isEqual(p.Type, PolicyTypeEnum.LenderPolicyOnly))
                            .map((p) => {
                                return <MenuItem
                                    key={p.PolicyNumber}
                                    onClick={() => handleApplyBtnClick(PolicyTypeEnum.LenderPolicyOnly, p.PolicyNumber)}
                                    disabled={disableLenderButtons}>
                                    {p.PolicyNumber}
                                </MenuItem>;
                            })
                    ]}
                </GroupedButton>
            </Box>
        }
    </React.Fragment>;
    //#endregion Props and HTML 
}

export default SearchEndorsement;