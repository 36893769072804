import * as actions from './MessagesActions';
import { createReducer } from '@reduxjs/toolkit';

const initialState = {
    messages_list: [],
    messages_list_loading: true,
    messages_list_loading_error: null,
    messages_list_total_records: 0,
    messages_list_total_pages: 0,
    message: { },
    message_busy: { },
    message_loading_error: null,
};

const MessagesReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(actions.set_message, (state, action) => {
            state.message = action.payload;
        })
        .addCase(actions.set_messages_list, (state, action) => {
            const withPagination = action.payload;

            const items = withPagination.Items ?? [];
            const totalRecords = withPagination.TotalRecords ?? 0;
            const numPages = withPagination.NumPages ?? 1;

            state.messages_list = items;
            state.messages_list_total_records = totalRecords;
            state.messages_list_total_pages = numPages;
        })
        .addCase(actions.set_current_messages, (state, action) => {
            state.messages_list = action.payload.Items ?? [];
        })
        .addCase(actions.set_messages_list_loading, (state, action) => {
            state.messages_list_loading = action.payload;
        })
        .addCase(actions.set_messages_list_loading_error, (state, action) => {
            state.messages_list_loading_error = action.payload;
        })
        .addCase(actions.action_message_busy, (state, action) => {
            state.message_busy = action.payload;
        })
        .addCase(actions.create_message, (state, action) => {
            const { Id, Name, MessageType, DisplayType, Status, DisplayStart, DisplayEnd, Text, CreatedBy, LastUpdatedBy, IsArchived, SigninPage, LandingPage, ApplicationPage } = action.payload;
            const message = state.message;

            if (!message || message.Id < 1) { return; }

            message.Id = Id;
            message.Name = Name;
            message.MessageType = MessageType;
            message.DisplayType = DisplayType;
            message.Status = Status;
            message.DisplayStart = DisplayStart;
            message.DisplayEnd = DisplayEnd;
            message.Text = Text;
            message.CreatedBy = CreatedBy;
            message.LastUpdatedBy = LastUpdatedBy;
            message.IsArchived = IsArchived;
            message.SigninPage = SigninPage;
            message.LandingPage = LandingPage;
            message.ApplicationPage = ApplicationPage;
        })
        .addCase(actions.update_message, (state, action) => {
            const { Id, Name, Type, Province, Issues, CreatedOn, CreatedBy, LastUpdatedBy } = action.payload;
            const message = state.message;

            if (!message || message.Id < 1) { return; }

            message.Name = Name;
            message.Type = Type;
            message.Province = Province;
            message.Issues = Issues;
            message.CreatedBy = CreatedBy;
            message.CreatedOn = CreatedOn;
            message.LastUpdatedBy = LastUpdatedBy;
        })
        .addCase(actions.set_message_handle_change, (state, action) => {
            const { fieldName, fieldValue } = action.payload;
            const currValue = state.message[fieldName];
            if (currValue === fieldValue) { return; }
            state.message[fieldName] = fieldValue;
        })
        .addCase(actions.set_message_loading_error, (state, action) => {
            state.message_loading_error = action.payload;
        })
        .addDefaultCase((state, action) => {
        })
});

export default MessagesReducer;