import makeStyles from '@mui/styles/makeStyles';
import { SectionHeader } from 'Components/Questionary';
import { isEqual } from 'lodash';
import { PermittedOrderActionsEnum } from 'Model/PolicyOrder';
import * as UserPermissions from 'Model/UserPermissions';
import React, { createRef, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import * as UserActions from 'User/State/UserActions';
import BrowserHelper from 'Util/BrowserHelper';
import { StringUtil, UserHelper } from 'Util/Helpers';
import useDebounce from 'Util/UseDebounce';
import { ValidationHelper } from 'Util/ValidationHelper';
import { PolicyApiClient } from '../../PolicyApiClient';
import InsureOver from './InsureOver';
import SearchInsureOver from './SearchInsureOver';
import { brown } from '@mui/material/colors';

const useStyles = makeStyles(() => ({
    attention: {
        color: brown[400]
    }
}));

function InsureOverView(props) {
    const classes = useStyles();
    const { po, userInfo, permittedActions } = props;

    const [items, setItems] = useState([]);
    const [changeCount, setChangeCount] = useState(0);
    const debouncedSave = useDebounce(changeCount, 4000);

    const [validationsMap, setValidationsMap] = useState();
    const [actionMsg, setActionMsg] = useState('');
    const [actionMsgType, setActionMsgType] = useState('');

    const [refs, setRefs] = useState([]);

    const canChange = UserHelper.hasPermission(userInfo, UserPermissions.change_insure_overs);

    useEffect(() => {
        if (po.InsureOvers) {
            const e = po.InsureOvers.map(item => {
                const _item = {
                    ...item,
                    AlreadyApplied: false
                }
                return _item;
            });
            setItems(e);
        }
    }, [po.InsureOvers]);

    useEffect(() => {
        setRefs(r => (
            items.map((_, i) => r[i] || createRef())
        ));

        items.forEach((o, index) => {
            if (o.AlreadyApplied)
                if (refs[index].current)
                    refs[index].current.focus();
        })
    }, [items]);

    useEffect(() => {
        if (changeCount === 0)
            return;
        save();
    }, [debouncedSave]);

    if (!StringUtil.containsAny(permittedActions, PermittedOrderActionsEnum.ViewInsureOvers, PermittedOrderActionsEnum.ApplyInsureOvers))
        return null;

    //#region Save
    async function save() {
        const optionsToSave = items.filter(o => o.ToSave === true && o.Deleting !== true);

        if (optionsToSave.length === 0)
            return;

        setSaving(true);
        const resp = await PolicyApiClient.saveInsureOvers(po.SecureId, optionsToSave);
        setSaving(false);
        setIsErrorOnSave(false);

        if (resp.hasError) {
            setActionMsgType('error');
            setActionMsg(resp.errorMessage);

            if (!resp.hasValidationResult) {
                return;
            }
        }

        const respData = resp.data;
        const validationMap = ValidationHelper.getValidationMap(respData);
        setValidationsMap(validationMap);
        if (!StringUtil.isNullOrEmpty(validationMap)) {
            setIsErrorOnSave(true);
            return;
        }

        setItems(prevState => prevState.map(item => {
            optionsToSave.forEach(savedItem => {
                if (isEqual(item, savedItem)) {
                    item.ToSave = false;
                    item.IsNew = false;
                }
            });
            return item;
        }));
    }

    function setSaving(val) {
        setItems(prevState => prevState.map(item => {
            if (item.ToSave === true && item.Deleting !== true)
                item.Saving = val;
            return item;
        }));
    }

    function setIsErrorOnSave(val) {
        setItems(prevState => prevState.map(item => {
            if (item.ToSave === true && item.Deleting !== true)
                item.IsError = val;
            return item;
        }));
    }

    function onDescriptionChange(value, secureId) {
        if (!canChange)
            return;

        setItems(prevState => prevState.map(item => {
            const _item = { ...item };
            if (StringUtil.isEqual(secureId, item.SecureId)) {
                _item.Description = value;
                _item.AlreadyApplied = false;
                _item.ToSave = true;
            }
            return _item;
        }));
        setChangeCount(prevState => prevState + 1);
    }
    //#endregion Save

    //#region Delete
    async function onDelete(secureId) {
        const itemToDelete = items.find(o => StringUtil.isEqual(secureId, o.SecureId));

        if (!itemToDelete.IsNew) {
            setDeleting(secureId, true);
            const resp = await PolicyApiClient.deleteInsureOver(po.SecureId, itemToDelete);
            setDeleting(secureId, false);
            setIsErrorOnDelete(secureId, false);

            if (resp.hasError) {
                setActionMsgType('error');
                setActionMsg(resp.errorMessage);
                setIsErrorOnDelete(secureId, true);

                if (!resp.hasValidationResult) {
                    return;
                }
            }

            const respData = resp.data;
            const validationMap = ValidationHelper.getValidationMap(respData);
            setValidationsMap(validationMap);
            if (!StringUtil.isNullOrEmpty(validationMap)) {
                setIsErrorOnDelete(secureId, true);
                return;
            }
        }

        setItems(prevState => prevState.filter(o => !StringUtil.isEqual(secureId, o.SecureId)));
    }

    function setDeleting(secureId, val) {
        setItems(prevState => prevState.map(item => {
            if (StringUtil.isEqual(secureId, item.SecureId))
                item.Deleting = val;

            return item;
        }));
    }

    function setIsErrorOnDelete(secureId, val) {
        setItems(prevState => prevState.map(item => {
            if (StringUtil.isEqual(secureId, item.SecureId))
                item.IsError = val;
            return item;
        }));
    }
    //#endregion Delete

    //#region Error Handling
    const getErrMsg = (index) => {
        if (StringUtil.isNullOrEmpty(validationsMap))
            return null;

        const secureId = items.find((item, i) => i === index).SecureId;

        if (secureId) {
            const itms = items.filter(item => item.IsError === true);
            let e = null;
            itms.forEach((item, index) => {
                if (item.SecureId === secureId)
                    e = validationsMap[`insureovers[${index}].description`];
            });

            if (StringUtil.isNullOrEmpty(e))
                return null;

            if (!StringUtil.isEqual(e.Severity, 'error'))
                return null;

            return e.ErrorMessage;
        }

        return null;
    }

    function isError(index) {
        return items.some((o, i) => o.IsError === true && i === index);
    }
    //#endregion Error Handling

    //#region Props and HTML

    const searchProps = {
        items: items,
        setItems: setItems,
        setChangeCount: setChangeCount,
        canChange: canChange
    };

    const itemProps = {
        isError: isError,
        getErrMsg: getErrMsg,
        onDescriptionChange: onDescriptionChange,
        onDelete: onDelete,
        canChange: canChange
    };

    return <SectionHeader section="insureovers" title="Insure Over" collapsible={!BrowserHelper.isIE11()} iconImgPath={"/img/insurance.svg"}>
        <SearchInsureOver {...searchProps} />
        {items.map((i, index) =>
            <InsureOver
                key={i.SecureId}
                item={i}
                index={index}
                controlRef={refs[index]}
                {...itemProps}
            />)}
    </SectionHeader>;
    //#endregion Props and HTML 
}

export default InsureOverView;