import { AppSettings } from 'AppSettings';
import ApiResponse from 'Model/ApiResponse';
import * as AppErrors from 'Model/AppErrors';
import { ApiHelper } from 'Util/ApiHelper';
import { StringUtil } from 'Util/Helpers';
import Logger from 'Util/Logger';
import { QuestionaryHelper } from 'Util/QuestionaryHelper';

const urljoin = require('url-join');

class ProjectApiClient {
    logger;

    constructor() {
        this.logger = new Logger(ProjectApiClient.name);
    }

    async load(secureId) {
        if (StringUtil.isNullOrEmpty(secureId))
            return;

        const url = urljoin(AppSettings.urls.policy, `/project/load?secureId=${secureId}`);

        const authHeaders = await ApiHelper.authHeaders();

        try {
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    ...authHeaders,
                    'Cache-Control': 'no-cache, no-store'
                }
            });

            return ApiHelper.deserializeJsonResponse(response);
        }
        catch (ex) {
            this.logger.error(`error loading policy ${secureId}`, ex);

            const errMsg = AppErrors.generic;

            const apiResp = new ApiResponse(true);
            apiResp.error = errMsg;

            return apiResp;
        }
    }

    async save(inputs) {
        const req = {};

        for (const fieldName in inputs) {
            const answer = inputs[fieldName];

            if (!QuestionaryHelper.houseNestedAnswer(fieldName, answer, req)) {
                req[fieldName] = answer;
            }
        }

        if (req.Exceptions && req.Exceptions.length > 0)
            if (req.ExceptionRange && req.ExceptionRange.length > 0)
                for (const ex of req.Exceptions) {
                    const range = req.ExceptionRange.filter(x => StringUtil.isEqual(x.ExceptionId, ex.Id));

                    if (range.length > 0 && StringUtil.isEqual(ex.ExceptionAppliesTo, "RangeOfProperties"))
                        ex["Properties"] = range;
                    else
                        ex["Properties"] = null;
                }

        const request = {
            NewHomeProject: req
        }

        const url = urljoin(AppSettings.urls.policy, '/project/save');

        const authHeaders = await ApiHelper.authHeaders();

        let apiResp;

        try {
            const response = await fetch(url, {
                body: JSON.stringify(request),
                method: "POST",
                headers: {
                    ...authHeaders
                }
            });

            apiResp = ApiHelper.deserializeJsonResponse(response);
        } catch (ex) {
            this.logger.error('error registering user', ex);

            const errMsg = AppErrors.generic;

            apiResp = new ApiResponse(true);
            apiResp.error = errMsg;
        }

        return apiResp;
    }
}

const instance = new ProjectApiClient();

export { instance as ProjectApiClient };

