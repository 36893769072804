import { AppSettings } from 'AppSettings';
import * as AppErrors from 'Model/AppErrors';
import { ApiHelper } from 'Util/ApiHelper';
import { StringUtil } from 'Util/Helpers';
import Logger from 'Util/Logger';
import ApiResponse from 'Model/ApiResponse';
import urlJoin from 'url-join';

function sleep(milliseconds)
{
    return new Promise(resolve => setTimeout(resolve, milliseconds));
}

class AuthApiClient {
    logger;

    constructor() {
        this.logger = new Logger(AuthApiClient.name);
    }

    async createUser(inputs) {
        const req = {};
        const userInfo = {};

        for (const fieldName in inputs) {
            const answer = inputs[fieldName];

            if(StringUtil.isEither(fieldName, "Password", "SecurityQuestionText", "SecurityQuestionAnswer")) {
                req[fieldName] = answer;
            }
            else
            {
                userInfo[fieldName] = answer;
            }
        }

        req.UserInfo = userInfo;

        const url = urlJoin(AppSettings.urls.policy, '/user/add');

        let apiResp;

        try {
            const response = await fetch(url, {
                body: JSON.stringify(req),
                method: "POST"
            });

            apiResp = ApiHelper.deserializeJsonResponse(response);
        } catch (ex) {
            this.logger.error('error creating new user', ex);

            const errMsg = AppErrors.generic;

            apiResp = new ApiResponse(true);
            apiResp.error = errMsg;
        }

        return apiResp;
    }

    async resetSecurityQuestion(inputs) {
        const req = {};
        for (const fieldName in inputs) {
            const answer = inputs[fieldName];
            if (StringUtil.isEither(fieldName, "Password", "UserName", "SecurityQuestionText", "SecurityQuestionAnswer")) {
                req[fieldName] = answer;
            }
        }
        const url = urlJoin(AppSettings.urls.policy, '/user/resetSecurityQuestion');
        let apiResp;
        try {
            const response = await fetch(url, {
                body: JSON.stringify(req),
                method: "POST"
            });
            apiResp = ApiHelper.deserializeJsonResponse(response);
        } catch (ex) {
            this.logger.error('error resetting a security question', ex);

            const errMsg = AppErrors.generic;

            apiResp = new ApiResponse(true);
            apiResp.error = errMsg;
        }

        return apiResp;
    }

    async changeSecurityQuestion(inputs) {
        const req = {};
        for (const fieldName in inputs) {
            const answer = inputs[fieldName];
            if (StringUtil.isEither(fieldName, "Password", "SecurityQuestionText", "SecurityQuestionAnswer")) {
                req[fieldName] = answer;
            }
        }
        const url = urlJoin(AppSettings.urls.policy, '/user/changeSecurityQuestion');

        const authHeaders = await ApiHelper.authHeaders();

        let apiResp;
        try {
            const response = await fetch(url, {
                body: JSON.stringify(req),
                method: "POST",
                headers: {
                    ...authHeaders
                }
            });
            apiResp = ApiHelper.deserializeJsonResponse(response);
        } catch (ex) {
            this.logger.error('error changing a security question', ex);

            const errMsg = AppErrors.generic;

            apiResp = new ApiResponse(true);
            apiResp.error = errMsg;
        }

        return apiResp;
    }

    async resetPassword(inputs) {
        const req = {};
        for (const fieldName in inputs) {
            const answer = inputs[fieldName];
            if (StringUtil.isEither(fieldName,
              "OldPassword", "NewPassword", "NewPasswordConfirm", "SecurityQuestionText",
              "SecurityQuestionAnswer", "UserName", "IsShadow")) {
                req[fieldName] = answer;
            }
        }
        const url = urlJoin(AppSettings.urls.policy, '/user/resetPassword');
        let apiResp;
        try {
            const response = await fetch(url, {
                body: JSON.stringify(req),
                method: "POST"
            });
            apiResp = ApiHelper.deserializeJsonResponse(response);
        } catch (ex) {
            this.logger.error('error resetting user password and secret', ex);
            const errMsg = AppErrors.generic;
            apiResp = new ApiResponse(true);
            apiResp.error = errMsg;
        }
        return apiResp;
    }

    async changePassword(inputs) {
        const req = {};
        for (const fieldName in inputs) {
            const answer = inputs[fieldName];
            if (StringUtil.isEither(fieldName,
              "CurrentPassword", "NewPassword", "NewPasswordConfirm")) {
                req[fieldName] = answer;
            }
        }
        const url = urlJoin(AppSettings.urls.policy, '/user/changePassword');

        const authHeaders = await ApiHelper.authHeaders();

        let apiResp;
        try {
            const response = await fetch(url, {
                body: JSON.stringify(req),
                method: "POST",
                headers: {
                    ...authHeaders
                }
            });
            apiResp = ApiHelper.deserializeJsonResponse(response);
        } catch (ex) {
            this.logger.error('error changing user password', ex);
            const errMsg = AppErrors.generic;
            apiResp = new ApiResponse(true);
            apiResp.error = errMsg;
        }
        return apiResp;
    }

    async forgotPasswordEmailValidation(inputs) {
        const req = {};
        for (const fieldName in inputs) {
            const answer = inputs[fieldName];
            if (StringUtil.isEither(fieldName, "Email", "Login")) {
                req[fieldName] = answer;
            }
        }
        const url = urlJoin(AppSettings.urls.policy, '/user/forgotPasswordEmailValidation');
        let apiResp;
        try {
            const response = await fetch(url, {
                body: JSON.stringify(req),
                method: "POST"
            });
            apiResp = ApiHelper.deserializeJsonResponse(response);
        } catch (ex) {
            this.logger.error('error validating email', ex);

            const errMsg = AppErrors.generic;

            apiResp = new ApiResponse(true);
            apiResp.error = errMsg;
        }

        return apiResp;
    }

    async forgotUsernameEmailValidation(inputs) {
        const req = {};
        for (const fieldName in inputs) {
            const answer = inputs[fieldName];
            if (StringUtil.isEqual(fieldName, "Email")) {
                req[fieldName] = answer;
            }
        }
        const url = urlJoin(AppSettings.urls.policy, '/user/forgotUsernamedEmailValidation');
        let apiResp;
        try {
            const response = await fetch(url, {
                body: JSON.stringify(req),
                method: "POST"
            });
            apiResp = ApiHelper.deserializeJsonResponse(response);
        } catch (ex) {
            this.logger.error('error validating email', ex);
            const errMsg = AppErrors.generic;
            apiResp = new ApiResponse(true);
            apiResp.error = errMsg;
        }

        return apiResp;
    }

    async forgotPasswordGetSecurityQuestion(inputs) {
        const req = {};
        for (const fieldName in inputs) {
            const answer = inputs[fieldName];
            if (StringUtil.isEither(fieldName, "Email")) {
                req[fieldName] = answer;
            }
        }
        const url = urlJoin(AppSettings.urls.policy, '/user/forgotPasswordGetSecurityQuestion');
        let apiResp;
        try {
            const response = await fetch(url, {
                body: JSON.stringify(req),
                method: "POST"
            });
            apiResp = ApiHelper.deserializeJsonResponse(response);
        } catch (ex) {
            this.logger.error('error validating email', ex);

            const errMsg = AppErrors.generic;

            apiResp = new ApiResponse(true);
            apiResp.error = errMsg;
        }

        return apiResp;
    }

    async getUserEmailWithToken(token) {
        const req = {};
        req["Token"] = token;
        const url = urlJoin(AppSettings.urls.policy, '/user/getUserEmailWithToken');
        let apiResp;
        try {
            const response = await fetch(url, {
                body: JSON.stringify(req),
                method: "POST"
            });
            apiResp = ApiHelper.deserializeJsonResponse(response);
        } catch (ex) {
            this.logger.error(`error fetching email with token ${req["Token"]}`, ex);

            const errMsg = AppErrors.generic;

            apiResp = new ApiResponse(true);
            apiResp.error = errMsg;
        }

        return apiResp;
    }

    async getForgottenUsernameWithToken(token) {
        const req = {};
        req["Token"] = token;
        const url = urlJoin(AppSettings.urls.policy, '/user/getForgottenUsernameWithToken');
        let apiResp;
        try {
            const response = await fetch(url, {
                body: JSON.stringify(req),
                method: "POST"
            });
            apiResp = ApiHelper.deserializeJsonResponse(response);
        } catch (ex) {
            this.logger.error(`error fetching username with token ${req["Token"]}`, ex);
            const errMsg = AppErrors.generic;
            apiResp = new ApiResponse(true);
            apiResp.error = errMsg;
        }

        return apiResp;
    }

    async resetForgottenPassword(inputs) {
        const req = {};
        for (const fieldName in inputs) {
            const answer = inputs[fieldName];
            if (StringUtil.isEither(fieldName, "Answer", "NewPassword", "NewPasswordConfirm", "UserId")) {
                req[fieldName] = answer;
            }
        }
        const url = urlJoin(AppSettings.urls.policy, '/user/ForgotPasswordChangeWithSecurityQuestion');
        let apiResp;
        try {
            const response = await fetch(url, {
                body: JSON.stringify(req),
                method: "POST"
            });
            apiResp = ApiHelper.deserializeJsonResponse(response);
        } catch (ex) {
            this.logger.error('error resetting a password', ex);

            const errMsg = AppErrors.generic;

            apiResp = new ApiResponse(true);
            apiResp.error = errMsg;
        }

        return apiResp;
    }

    async loadUserProfile() {
        const url = urlJoin(AppSettings.urls.policy, '/user/getProfile');

        let apiResp;

        const authHeaders = await ApiHelper.authHeaders();

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    ...authHeaders
                }
            });
            
            const errorApiResp = await ApiHelper.httpRespToErrorApiResp(response);

            if (errorApiResp != null) {
                return errorApiResp;
            }

            apiResp = new ApiResponse(false);
            apiResp.data = await response.json();
            
        } catch (ex) {
            this.logger.error('error getting user profile', ex);

            const errMsg = AppErrors.generic;

            apiResp = new ApiResponse(true);
            apiResp.error = errMsg;
        }

        return apiResp;
    }

    async activateUser(userId) {        
        const req = {
            UserId: userId
        };

        const url = urlJoin(AppSettings.urls.policy, '/user/confirmEmail');

        let apiResp;

        try {
            const response = await fetch(url, {
                body: JSON.stringify(req),
                method: "POST"
            });

            apiResp = ApiHelper.deserializeJsonResponse(response);
        } catch (ex) {
            this.logger.error('error activating user', ex);

            const errMsg = AppErrors.generic;

            apiResp = new ApiResponse(true);
            apiResp.error = errMsg;
        }

        return apiResp;
    }

    async getCurrentMessages(listReq) {
        const url = urlJoin(AppSettings.urls.policy, '/admin/getcurrentmessages');
        try {
            const response = await fetch(url, {
                body: JSON.stringify(listReq),
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            console.log(response);

            return ApiHelper.deserializeJsonResponse(response);
        }
        catch (ex) {
            this.logger.error('error getting messages', ex);
            const errMsg = AppErrors.generic;
            const apiResp = new ApiResponse(true);
            apiResp.error = errMsg;
            return apiResp;
        }
    }
}

const instance = new AuthApiClient();

export { instance as AuthApiClient } 
